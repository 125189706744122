export const UNICON_COLORS = [
  ["#F50DB4", "#FC74FE"],
  ["#FFBF17", "#FFF612"],
  ["#FF8934", "#FF4D00"],
  ["#85754A", "#996F01"],
  ["#0C8911", "#21C95E"],
  ["#78E744", "#B1F13C"],
  ["#00C3A0", "#5CFE9D"],
  ["#23A3FF", "#3ADCFF"],
  ["#4981FF", "#0047FF"],
  ["#4300B0", "#9E62FF"]
];
