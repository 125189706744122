"use strict";
import {
  defaultOptions
} from "components/Charts/StackedLineChart/stacked-area-series/options";
import { StackedAreaSeriesRenderer } from "components/Charts/StackedLineChart/stacked-area-series/renderer";
export class StackedAreaSeries {
  _renderer;
  constructor() {
    this._renderer = new StackedAreaSeriesRenderer();
  }
  priceValueBuilder(plotRow) {
    return [0, plotRow.values.reduce((previousValue, currentValue) => previousValue + currentValue, 0)];
  }
  isWhitespace(data) {
    return !data.values?.length;
  }
  renderer() {
    return this._renderer;
  }
  update(data, options) {
    this._renderer.update(data, options);
  }
  defaultOptions() {
    return defaultOptions;
  }
}
