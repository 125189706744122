"use strict";
import { createReducer } from "@reduxjs/toolkit";
import { Field, resetMintState, typeInput } from "state/mint/actions";
export const initialState = {
  independentField: Field.CURRENCY_A,
  typedValue: "",
  otherTypedValue: "",
  startPriceTypedValue: "",
  leftRangeTypedValue: "",
  rightRangeTypedValue: ""
};
export default createReducer(
  initialState,
  (builder) => builder.addCase(resetMintState, () => initialState).addCase(typeInput, (state, { payload: { field, typedValue, noLiquidity } }) => {
    if (noLiquidity) {
      if (field === state.independentField) {
        return {
          ...state,
          independentField: field,
          typedValue
        };
      } else {
        return {
          ...state,
          independentField: field,
          typedValue,
          otherTypedValue: state.typedValue
        };
      }
    } else {
      return {
        ...state,
        independentField: field,
        typedValue,
        otherTypedValue: ""
      };
    }
  })
);
