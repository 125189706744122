"use strict";
import { Web3Provider } from "@ethersproject/providers";
import { useMemo } from "react";
import { useConnectorClient } from "wagmi";
function clientToSigner(client) {
  if (!client || !client.chain) {
    return void 0;
  }
  const { chain, transport, account } = client;
  const ensAddress = chain.contracts?.ensRegistry?.address;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress
  };
  const provider = new Web3Provider(transport, network);
  return provider.getSigner(account.address);
}
export function useEthersSigner() {
  const { data: client } = useConnectorClient();
  return useMemo(() => clientToSigner(client), [client]);
}
