"use strict";
import { MULTICALL_ADDRESSES, NONFUNGIBLE_POSITION_MANAGER_ADDRESSES as V3NFT_ADDRESSES } from "@uniswap/sdk-core";
import NFTPositionManagerJSON from "@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json";
import MulticallJSON from "@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json";
import { useWeb3React } from "@web3-react/core";
import { RPC_PROVIDERS } from "constants/providers";
import { toContractInput } from "graphql/data/util";
import { useAccount } from "hooks/useAccount";
import { useMemo } from "react";
import {
  useUniswapPricesQuery
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { useEnabledChains, useIsSupportedChainIdCallback } from "uniswap/src/features/chains/hooks";
import { getContract } from "utilities/src/contracts/getContract";
import { currencyKey, currencyKeyFromGraphQL } from "utils/currencyKey";
export function useContractMultichain(addressMap, ABI, chainIds) {
  const account = useAccount();
  const { provider: walletProvider } = useWeb3React();
  const isSupportedChain = useIsSupportedChainIdCallback();
  return useMemo(() => {
    const relevantChains = chainIds ?? Object.keys(addressMap).map((chainId) => parseInt(chainId)).filter((chainId) => isSupportedChain(chainId));
    return relevantChains.reduce((acc, chainId) => {
      const provider = walletProvider && account.chainId === chainId ? walletProvider : isSupportedChain(chainId) ? RPC_PROVIDERS[chainId] : void 0;
      if (provider) {
        acc[chainId] = getContract(addressMap[chainId] ?? "", ABI, provider);
      }
      return acc;
    }, {});
  }, [ABI, addressMap, chainIds, isSupportedChain, account.chainId, walletProvider]);
}
export function useV3ManagerContracts(chainIds) {
  return useContractMultichain(V3NFT_ADDRESSES, NFTPositionManagerJSON.abi, chainIds);
}
export function useInterfaceMulticallContracts(chainIds) {
  return useContractMultichain(MULTICALL_ADDRESSES, MulticallJSON.abi, chainIds);
}
export function usePoolPriceMap(positions) {
  const { defaultChainId } = useEnabledChains();
  const contracts = useMemo(() => {
    if (!positions || !positions.length) {
      return [];
    }
    const contractMap = positions.reduce((acc, { pool: { token0, token1 } }) => {
      acc[currencyKey(token0)] = toContractInput(token0, defaultChainId);
      acc[currencyKey(token1)] = toContractInput(token1, defaultChainId);
      return acc;
    }, {});
    return Object.values(contractMap);
  }, [defaultChainId, positions]);
  const { data, loading } = useUniswapPricesQuery({ variables: { contracts }, skip: !contracts.length });
  const priceMap = useMemo(
    () => data?.tokens?.reduce((acc, current) => {
      if (current) {
        acc[currencyKeyFromGraphQL(current)] = current.project?.markets?.[0]?.price?.value;
      }
      return acc;
    }, {}) ?? {},
    [data?.tokens]
  );
  return { priceMap, pricesLoading: loading && !data };
}
