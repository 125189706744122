import { useState } from "react";
export function usePostTextElementPositionProps() {
  const [postTextElementPositionProps, setPostTextElementPositionProps] = useState(
    void 0
  );
  const onTextLayout = (event) => {
    const { lines } = event.nativeEvent;
    const lastLine = lines[lines.length - 1];
    if (!lastLine) {
      return;
    }
    const { width, x, y } = lastLine;
    setPostTextElementPositionProps({ position: "absolute", left: x + width, top: y });
  };
  return { postTextElementPositionProps, onTextLayout };
}
