"use strict";
import { AllowanceTransfer, MaxAllowanceTransferAmount, permit2Address } from "@uniswap/permit2-sdk";
import { CurrencyAmount } from "@uniswap/sdk-core";
import { useAccount } from "hooks/useAccount";
import { useContract } from "hooks/useContract";
import { useEthersSigner } from "hooks/useEthersSigner";
import { useSingleCallResult } from "lib/hooks/multicall";
import ms from "ms";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { trace } from "tracing/trace";
import PERMIT2_ABI from "uniswap/src/abis/permit2.json";
import { UserRejectedRequestError, toReadableError } from "utils/errors";
import { signTypedData } from "utils/signing";
import { didUserReject } from "utils/swapErrorToUserReadableMessage";
const PERMIT_EXPIRATION = ms(`30d`);
const PERMIT_SIG_EXPIRATION = ms(`30m`);
function toDeadline(expiration) {
  return Math.floor((Date.now() + expiration) / 1e3);
}
export function usePermitAllowance(token, owner, spender) {
  const contract = useContract(permit2Address(token?.chainId), PERMIT2_ABI, void 0, token?.chainId);
  const inputs = useMemo(() => [owner, token?.address, spender], [owner, spender, token?.address]);
  const [blocksPerFetch, setBlocksPerFetch] = useState();
  const result = useSingleCallResult(contract, "allowance", inputs, {
    blocksPerFetch
  }).result;
  const rawAmount = result?.amount.toString();
  const allowance = useMemo(
    () => token && rawAmount ? CurrencyAmount.fromRawAmount(token, rawAmount) : void 0,
    [token, rawAmount]
  );
  useEffect(() => setBlocksPerFetch(allowance?.equalTo(0) ? 1 : void 0), [allowance]);
  return useMemo(
    () => ({ permitAllowance: allowance, expiration: result?.expiration, nonce: result?.nonce }),
    [allowance, result?.expiration, result?.nonce]
  );
}
export function useUpdatePermitAllowance(token, spender, nonce, onPermitSignature) {
  const account = useAccount();
  const signer = useEthersSigner();
  const accountRef = useRef(account);
  accountRef.current = account;
  const signerRef = useRef(signer);
  signerRef.current = signer;
  return useCallback(
    () => trace({ name: "Permit2", op: "permit.permit2.signature" }, async (trace2) => {
      try {
        const account2 = accountRef.current;
        if (account2.status !== "connected") {
          throw new Error("wallet not connected");
        }
        if (!account2.chainId) {
          throw new Error("connected to an unsupported network");
        }
        if (!token) {
          throw new Error("missing token");
        }
        if (!spender) {
          throw new Error("missing spender");
        }
        if (nonce === void 0) {
          throw new Error("missing nonce");
        }
        const permit = {
          details: {
            token: token.address,
            amount: MaxAllowanceTransferAmount,
            expiration: toDeadline(PERMIT_EXPIRATION),
            nonce
          },
          spender,
          sigDeadline: toDeadline(PERMIT_SIG_EXPIRATION)
        };
        const { domain, types, values } = AllowanceTransfer.getPermitData(
          permit,
          permit2Address(token?.chainId),
          account2.chainId
        );
        const signature = await trace2.child({ name: "Sign", op: "wallet.sign" }, async (walletTrace) => {
          try {
            const signer2 = signerRef.current;
            if (!signer2) {
              throw new Error("missing signer");
            }
            return await signTypedData(signer2, domain, types, values);
          } catch (error) {
            if (didUserReject(error)) {
              walletTrace.setStatus("cancelled");
              const symbol = token?.symbol ?? "Token";
              throw new UserRejectedRequestError(`${symbol} permit allowance failed: User rejected signature`);
            } else {
              throw error;
            }
          }
        });
        onPermitSignature?.({ ...permit, signature });
        return;
      } catch (error) {
        if (error instanceof UserRejectedRequestError) {
          trace2.setStatus("cancelled");
          throw error;
        } else {
          const symbol = token?.symbol ?? "Token";
          throw toReadableError(`${symbol} permit allowance failed:`, error);
        }
      }
    }),
    [nonce, onPermitSignature, spender, token]
  );
}
