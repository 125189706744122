import { G, Path, Svg } from "react-native-svg";
import { createIcon } from "../factories/createIcon";
export const [PhoneDownload, AnimatedPhoneDownload] = createIcon({
  name: "PhoneDownload",
  getIcon: (props) => <Svg viewBox="0 0 40 41" fill="none" {...props}><G id="Icon container"><Path
    id="Subtract"
    d="M12 29.0714C12 31.3571 13.1429 32.5 15.4286 32.5H24.5714C26.8571 32.5 28 31.3571 28 29.0714V11.9286C28 9.64286 26.8571 8.5 24.5714 8.5H15.4286C13.1429 8.5 12 9.64286 12 11.9286V29.0714ZM18.8571 11.9286C18.8571 11.2977 19.3691 10.7857 20 10.7857C20.6309 10.7857 21.1429 11.2977 21.1429 11.9286C21.1429 12.5594 20.6309 13.0714 20 13.0714C19.3691 13.0714 18.8571 12.5594 18.8571 11.9286ZM15.1429 29.0714C15.1429 28.5983 15.5269 28.2143 16 28.2143H24C24.4731 28.2143 24.8571 28.5983 24.8571 29.0714C24.8571 29.5446 24.4731 29.9286 24 29.9286H16C15.5269 29.9286 15.1429 29.5446 15.1429 29.0714ZM19.1429 23.9107V19.728V17.3504C19.1429 16.8773 19.5269 16.4933 20 16.4933C20.4732 16.4933 20.8572 16.8773 20.8572 17.3504V19.728V23.9107L22.8229 21.945C23.1577 21.6101 23.7006 21.6101 24.0355 21.945C24.3703 22.2798 24.3703 22.8227 24.0355 23.1576L20.6069 26.5862C20.528 26.665 20.4333 26.7279 20.3282 26.7713C20.223 26.8147 20.112 26.8375 20 26.8375C19.888 26.8375 19.777 26.8147 19.6719 26.7713C19.5668 26.7279 19.472 26.665 19.3932 26.5862L15.9646 23.1576C15.6297 22.8227 15.6297 22.2798 15.9646 21.945C16.2994 21.6101 16.8423 21.6101 17.1772 21.945L19.1429 23.9107Z"
    fill="currentColor"
    fillRule="evenodd"
    clipRule="evenodd"
  /></G></Svg>
});
