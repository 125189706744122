"use strict";
import { WRAPPED_NATIVE_CURRENCY, nativeOnChain } from "uniswap/src/constants/tokens";
export function unwrappedToken(currency) {
  if (currency.isNative) {
    return currency;
  }
  if (WRAPPED_NATIVE_CURRENCY[currency.chainId]?.equals(currency)) {
    return nativeOnChain(currency.chainId);
  }
  return currency;
}
