"use strict";
import { PROTOCOL_LEGEND_ELEMENT_ID } from "components/Charts/types";
import { formatTickMarks } from "components/Charts/utils";
import { MissingDataBars } from "components/Table/icons";
import { useScreenSize } from "hooks/screenSize/useScreenSize";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { atom } from "jotai";
import { useUpdateAtom } from "jotai/utils";
import { useTheme } from "lib/styled-components";
import {
  CrosshairMode,
  LineStyle,
  createChart
} from "lightweight-charts";
import { useEffect, useMemo, useRef, useState } from "react";
import { ThemedText } from "theme/components";
import { Flex, assertWebElement, styled } from "ui/src";
import { useCurrentLocale } from "uniswap/src/features/language/hooks";
import { Trans } from "uniswap/src/i18n";
import { useFormatter } from "utils/formatNumbers";
import { v4 as uuidv4 } from "uuid";
export const refitChartContentAtom = atom(void 0);
export class ChartModel {
  api;
  data;
  chartDiv;
  onCrosshairMove;
  _hoverData;
  _lastTooltipWidth = null;
  tooltipId = `chart-tooltip-${uuidv4()}`;
  constructor(chartDiv, params) {
    this.chartDiv = chartDiv;
    this.onCrosshairMove = params.onCrosshairMove;
    this.data = params.data;
    this.api = createChart(chartDiv);
    this.api.subscribeCrosshairMove((param) => {
      let newHoverData = void 0;
      const logical = param.logical;
      const x = param.point?.x;
      const y = param.point?.y;
      if (x !== void 0 && isBetween(x, 0, this.chartDiv.clientWidth) && y !== void 0 && isBetween(y, 0, this.chartDiv.clientHeight) && logical !== void 0) {
        const item = param.seriesData.get(this.series);
        if (item) {
          newHoverData = { item, x, y, logicalIndex: logical };
        }
      }
      const prevHoverData = this._hoverData;
      if (newHoverData?.item.time !== prevHoverData?.item.time || newHoverData?.logicalIndex !== prevHoverData?.logicalIndex || newHoverData?.x !== prevHoverData?.x || newHoverData?.y !== prevHoverData?.y) {
        this._hoverData = newHoverData;
        this.onSeriesHover?.(newHoverData);
      }
    });
  }
  /**
   * Updates React state with the current crosshair data.
   * This method should be overridden in subclasses to provide specific hover functionality.
   * When overriding, call `super.onSeriesHover(data)` to maintain base functionality.
   */
  onSeriesHover(hoverData) {
    this.onCrosshairMove?.(hoverData?.item, hoverData?.logicalIndex);
    if (!hoverData) {
      return;
    }
    const x = hoverData.x + this.api.priceScale("left").width() + 10;
    const deadzoneWidth = this._lastTooltipWidth ? Math.ceil(this._lastTooltipWidth) : 45;
    const xAdjusted = Math.min(x, this.api.paneSize().width - deadzoneWidth);
    const transformX = `calc(${xAdjusted}px)`;
    const y = hoverData.y;
    const flip = y <= 20 + 100;
    const yPx = y + (flip ? 1 : -1) * 20;
    const yPct = flip ? "" : " - 100%";
    const transformY = `calc(${yPx}px${yPct})`;
    const tooltip = document.getElementById(this.tooltipId);
    const legend = document.getElementById(PROTOCOL_LEGEND_ELEMENT_ID);
    if (tooltip) {
      tooltip.style.transform = `translate(${transformX}, ${transformY})`;
      const tooltipMeasurement = tooltip.getBoundingClientRect();
      this._lastTooltipWidth = tooltipMeasurement?.width || null;
    }
    if (legend) {
      legend.style.left = `${x}px`;
      const heroWidth = 230;
      if (x < heroWidth) {
        legend.style.top = "80px";
      } else {
        legend.style.top = "unset";
      }
      const transformOffset = 60;
      const maxXOffset = this.api.paneSize().width - 40;
      if (x < transformOffset) {
        legend.style.transform = `translateX(-${x - 4}%)`;
      } else if (x > maxXOffset) {
        legend.style.transform = `translateX(-${transformOffset + (x - maxXOffset)}%)`;
      } else {
        legend.style.transform = `translateX(-${transformOffset}%)`;
      }
    }
  }
  /** Updates the chart without re-creating it or resetting pan/zoom. */
  updateOptions({ locale, theme, format, isLargeScreen, onCrosshairMove }, nonDefaultChartOptions) {
    this.onCrosshairMove = onCrosshairMove;
    const defaultOptions = {
      localization: {
        locale,
        priceFormatter: (price) => format.formatFiatPrice({ price })
      },
      autoSize: true,
      layout: { textColor: theme.neutral2, background: { color: "transparent" } },
      timeScale: {
        tickMarkFormatter: formatTickMarks,
        borderVisible: false,
        ticksVisible: false,
        timeVisible: true,
        fixLeftEdge: true,
        fixRightEdge: true
      },
      rightPriceScale: {
        visible: isLargeScreen,
        borderVisible: false,
        scaleMargins: {
          top: 0.32,
          bottom: 0.15
        },
        autoScale: true
      },
      grid: {
        vertLines: {
          visible: false
        },
        horzLines: {
          visible: false
        }
      },
      crosshair: {
        horzLine: {
          visible: true,
          style: LineStyle.Solid,
          width: 1,
          color: theme.surface3,
          labelVisible: false
        },
        mode: CrosshairMode.Magnet,
        vertLine: {
          visible: true,
          style: LineStyle.Solid,
          width: 1,
          color: theme.surface3,
          labelVisible: false
        }
      }
    };
    this.api.applyOptions({ ...defaultOptions, ...nonDefaultChartOptions });
  }
  /** Updates visible range to fit all data from all series. */
  fitContent() {
    this.api.timeScale().fitContent();
  }
  /** Removes the injected canvas from the chartDiv. */
  remove() {
    this.api.remove();
  }
}
const isBetween = (num, lower, upper) => num > lower && num < upper;
export function Chart({
  Model,
  TooltipBody,
  params,
  height,
  children,
  className
}) {
  const setRefitChartContent = useUpdateAtom(refitChartContentAtom);
  const [chartDivElement, setChartDivElement] = useState(null);
  const [crosshairData, setCrosshairData] = useState(void 0);
  const format = useFormatter();
  const theme = useTheme();
  const locale = useCurrentLocale();
  const { md: isLargeScreen } = useScreenSize();
  const modelParams = useMemo(
    () => ({ ...params, format, theme, locale, isLargeScreen, onCrosshairMove: setCrosshairData }),
    [format, isLargeScreen, locale, params, theme]
  );
  const chartModelRef = useRef();
  useEffect(() => {
    if (chartDivElement && chartModelRef.current === void 0) {
      assertWebElement(chartDivElement);
      chartModelRef.current = new Model(chartDivElement, modelParams);
      setRefitChartContent(() => () => chartModelRef.current?.fitContent());
    }
  }, [Model, chartDivElement, modelParams, setRefitChartContent]);
  useEffect(() => {
    chartModelRef.current?.updateOptions(modelParams);
  }, [modelParams]);
  useEffect(() => {
    return () => {
      chartModelRef.current?.remove();
      chartModelRef.current = void 0;
      setRefitChartContent(void 0);
    };
  }, [setRefitChartContent]);
  useOnClickOutside({ current: chartDivElement }, () => setCrosshairData(void 0));
  return <Flex
    width="100%"
    position="relative"
    animation="fast"
    ref={setChartDivElement}
    height={height}
    className={className}
    onTouchMove={(e) => e.stopPropagation()}
  >
    {children && children(crosshairData)}
    {TooltipBody && crosshairData && <ChartTooltip id={chartModelRef.current?.tooltipId}><TooltipBody data={crosshairData} /></ChartTooltip>}
    {params.stale && <StaleBanner />}
  </Flex>;
}
const ChartTooltip = styled(Flex, {
  alignItems: "center",
  position: "absolute",
  left: 0,
  top: 0,
  zIndex: "$tooltip",
  backgroundColor: "$surface5",
  backdropFilter: "blur(8px)",
  borderRadius: "$rounded8",
  borderColor: "$surface3",
  borderStyle: "solid",
  borderWidth: 1,
  p: "$spacing8"
});
const StaleBannerWrapper = styled(ChartTooltip, {
  borderRadius: "$rounded16",
  left: "unset",
  top: "unset",
  right: "$spacing12",
  bottom: "$spacing40",
  p: "$spacing12",
  backgroundColor: "$surface4"
});
function StaleBanner() {
  const theme = useTheme();
  return <StaleBannerWrapper data-testid="chart-stale-banner"><Flex row gap="$gap8">
    <MissingDataBars color={theme.neutral1} />
    <ThemedText.BodySmall><Trans i18nKey="common.dataOutdated" /></ThemedText.BodySmall>
  </Flex></StaleBannerWrapper>;
}
