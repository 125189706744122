"use strict";
import { stringify } from "qs";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useUrlContext } from "uniswap/src/contexts/UrlContext";
import { useAppFiatCurrency } from "uniswap/src/features/fiatCurrency/hooks";
import { setCurrentFiatCurrency } from "uniswap/src/features/settings/slice";
import { InterfaceEventNameLocal } from "uniswap/src/features/telemetry/constants";
import { sendAnalyticsEvent } from "uniswap/src/features/telemetry/send";
export function useLocalCurrencyLinkProps(localCurrency) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { useParsedQueryString } = useUrlContext();
  const qs = useParsedQueryString();
  const activeLocalCurrency = useAppFiatCurrency();
  return useMemo(
    () => !localCurrency ? {} : {
      to: {
        ...location,
        search: stringify({ ...qs, cur: localCurrency })
      },
      onClick: () => {
        dispatch(setCurrentFiatCurrency(localCurrency));
        sendAnalyticsEvent(InterfaceEventNameLocal.LocalCurrencySelected, {
          previous_local_currency: activeLocalCurrency,
          new_local_currency: localCurrency
        });
      }
    },
    [localCurrency, location, qs, dispatch, activeLocalCurrency]
  );
}
