"use strict";
import { RouterPreference } from "state/routing/types";
export const migration5 = (state) => {
  if (!state) {
    return state;
  }
  if (state?.user && "disabledUniswapX" in state.user) {
    delete state.user["disabledUniswapX"];
  }
  const userOptedOutOfUniswapX = state?.user?.optedOutOfUniswapX;
  if (state?.user && "optedOutOfUniswapX" in state.user) {
    delete state.user["optedOutOfUniswapX"];
  }
  if (state?.user && !userOptedOutOfUniswapX) {
    return {
      ...state,
      user: {
        ...state.user,
        userRouterPreference: RouterPreference.X
      },
      _persist: {
        ...state._persist,
        version: 5
      }
    };
  }
  return {
    ...state,
    _persist: {
      ...state._persist,
      version: 5
    }
  };
};
