import { createContext, useContext } from "react";
const defaultState = {
  useIsPartOfNavigationTree: () => false,
  shouldLogScreen: (_direct, _screen) => true
};
const context = createContext(defaultState);
export const useAnalyticsNavigationContext = () => useContext(context);
export function AnalyticsNavigationContextProvider({
  useIsPartOfNavigationTree,
  shouldLogScreen,
  children
}) {
  return <context.Provider value={{ useIsPartOfNavigationTree, shouldLogScreen }}>{children}</context.Provider>;
}
