"use strict";
import { CurrencyAmount, Fraction } from "@uniswap/sdk-core";
import { parseUnits } from "ethers/lib/utils";
import { useStablecoinAmountFromFiatValue } from "hooks/useStablecoinPrice";
import { useUSDPrice } from "hooks/useUSDPrice";
import JSBI from "jsbi";
const WARNING_THRESHOLD = new Fraction(5, 100);
const DECIMAL_SCALAR = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18));
function useMarketPrice(baseCurrency, quoteCurrency) {
  const baseCurrencyUSDPrice = useUSDPrice(
    baseCurrency ? CurrencyAmount.fromRawAmount(baseCurrency, JSBI.BigInt(parseUnits("1", baseCurrency?.decimals))) : void 0,
    baseCurrency
  );
  const baseCurrencyStableCoinAmount = useStablecoinAmountFromFiatValue(baseCurrencyUSDPrice.data);
  const quoteCurrencyUSDPrice = useUSDPrice(
    quoteCurrency ? CurrencyAmount.fromRawAmount(quoteCurrency, JSBI.BigInt(parseUnits("1", quoteCurrency?.decimals))) : void 0,
    quoteCurrency
  );
  const quoteCurrencyStableCoinAmount = useStablecoinAmountFromFiatValue(quoteCurrencyUSDPrice.data);
  if (!baseCurrencyStableCoinAmount || !quoteCurrencyStableCoinAmount) {
    return void 0;
  }
  const marketPrice = new Fraction(
    baseCurrencyStableCoinAmount.multiply(DECIMAL_SCALAR).toFixed(0),
    quoteCurrencyStableCoinAmount.multiply(DECIMAL_SCALAR).toFixed(0)
  );
  return marketPrice;
}
export function useIsPoolOutOfSync(poolPrice) {
  const marketPrice = useMarketPrice(poolPrice?.baseCurrency, poolPrice?.quoteCurrency);
  if (!poolPrice || !marketPrice) {
    return false;
  }
  const scaledMarketPrice = JSBI.BigInt(marketPrice.multiply(DECIMAL_SCALAR).toFixed(0));
  const scaledPoolPrice = JSBI.BigInt(
    poolPrice.quote(
      CurrencyAmount.fromRawAmount(
        poolPrice.baseCurrency?.wrapped,
        JSBI.BigInt(parseUnits("1", poolPrice.baseCurrency?.decimals))
      )
    ).multiply(DECIMAL_SCALAR).toFixed(0)
  );
  const difference = JSBI.lessThan(scaledMarketPrice, scaledPoolPrice) ? JSBI.subtract(scaledPoolPrice, scaledMarketPrice) : JSBI.subtract(scaledMarketPrice, scaledPoolPrice);
  const divergence = new Fraction(difference, scaledMarketPrice);
  return divergence.greaterThan(WARNING_THRESHOLD);
}
