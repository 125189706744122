"use strict";
import { InterfaceSectionName, NavBarSearchTypes } from "@uniswap/analytics-events";
import Badge from "components/Badge/Badge";
import { ChainLogo } from "components/Logo/ChainLogo";
import {
  useRecentlySearchedAssets
} from "components/NavBar/SearchBar/RecentlySearchedAssets";
import { SkeletonRow, SuggestionRow } from "components/NavBar/SearchBar/SuggestionRow";
import QuestionHelper from "components/QuestionHelper";
import { SuspendConditionally } from "components/Suspense/SuspendConditionally";
import { SuspenseWithPreviousRenderAsFallback } from "components/Suspense/SuspenseWithPreviousRenderAsFallback";
import useTrendingTokens from "graphql/data/TrendingTokens";
import { useAccount } from "hooks/useAccount";
import deprecatedStyled from "lib/styled-components";
import { useEffect, useMemo, useState } from "react";
import { Clock, TrendingUp } from "react-feather";
import { ThemedText } from "theme/components";
import { Flex, Text, useScrollbarStyles } from "ui/src";
import { getChainInfo } from "uniswap/src/features/chains/chainInfo";
import { isBackendSupportedChainId } from "uniswap/src/features/chains/utils";
import { Trans } from "uniswap/src/i18n";
import { useTrace } from "utilities/src/telemetry/trace/TraceContext";
function SearchBarDropdownSection({
  toggleOpen,
  suggestions,
  header,
  headerIcon = void 0,
  headerInfoText,
  hoveredIndex,
  startingIndex,
  setHoveredIndex,
  isLoading,
  eventProperties
}) {
  return <Flex gap="$spacing4" data-testid="searchbar-dropdown">
    <Flex row alignItems="center" py="$spacing4" px="$spacing16" gap="8px">
      {headerIcon ? headerIcon : null}
      <Text variant="body3">{header}</Text>
      {headerInfoText ? <QuestionHelper text={headerInfoText} /> : null}
    </Flex>
    <Flex gap="$spacing4">{suggestions.map(
      (suggestion, index) => isLoading || !suggestion ? <SkeletonRow key={index} /> : <SuggestionRow
        key={suggestion.address}
        suggestion={suggestion}
        isHovered={hoveredIndex === index + startingIndex}
        setHoveredIndex={setHoveredIndex}
        toggleOpen={toggleOpen}
        index={index + startingIndex}
        eventProperties={{
          ...eventProperties,
          position: index + startingIndex,
          selected_search_result_name: suggestion.name,
          selected_search_result_address: suggestion.address
        }}
      />
    )}</Flex>
  </Flex>;
}
const ChainComingSoonBadge = deprecatedStyled(Badge)`
  align-items: center;
  background-color: ${({ theme }) => theme.surface2};
  color: ${({ theme }) => theme.neutral2};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  opacity: 1;
  padding: 8px;
  margin: 16px 16px 4px;
  width: calc(100% - 32px);
  gap: 8px;
`;
export function SearchBarDropdown(props) {
  const { isLoading } = props;
  const account = useAccount();
  const showChainComingSoonBadge = account.chainId && !isBackendSupportedChainId(account.chainId) && !isLoading;
  const scrollBarStyles = useScrollbarStyles();
  return <Flex
    width="100%"
    backdropFilter="blur(60px)"
    animation="fast"
    opacity={isLoading ? 0.3 : 1}
    style={scrollBarStyles}
    $platform-web={{
      overflowY: "auto"
    }}
  >
    <SuspenseWithPreviousRenderAsFallback><SuspendConditionally if={isLoading}><SearchBarDropdownContents {...props} /></SuspendConditionally></SuspenseWithPreviousRenderAsFallback>
    {showChainComingSoonBadge && account.chainId && <ChainComingSoonBadge>
      <ChainLogo chainId={account.chainId} size={20} />
      <ThemedText.BodySmall color="neutral2" fontSize="14px" fontWeight="400" lineHeight="20px"><ComingSoonText chainId={account.chainId} /></ThemedText.BodySmall>
    </ChainComingSoonBadge>}
  </Flex>;
}
function SearchBarDropdownContents({ toggleOpen, tokens, queryText, hasInput }) {
  const [hoveredIndex, setHoveredIndex] = useState(0);
  const { data: searchHistory } = useRecentlySearchedAssets();
  const shortenedHistory = useMemo(
    () => searchHistory?.filter((item) => item.chain) ?? [...Array(2)],
    [searchHistory]
  );
  const account = useAccount();
  const { data: trendingTokenData } = useTrendingTokens(account.chainId);
  const trendingTokens = useMemo(
    () => trendingTokenData?.slice(0, 3) ?? [...Array(3)],
    [trendingTokenData]
  );
  const totalSuggestions = hasInput ? tokens.length : Math.min(shortenedHistory.length, 2) + trendingTokens?.length;
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "ArrowUp") {
        event.preventDefault();
        if (!hoveredIndex) {
          setHoveredIndex(trendingTokens.length - 1);
        } else {
          setHoveredIndex(hoveredIndex - 1);
        }
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        if (hoveredIndex && hoveredIndex === totalSuggestions - 1) {
          setHoveredIndex(0);
        } else {
          setHoveredIndex((hoveredIndex ?? -1) + 1);
        }
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [toggleOpen, hoveredIndex, totalSuggestions, trendingTokens.length]);
  const trace = useTrace({ section: InterfaceSectionName.NAVBAR_SEARCH });
  const eventProperties = {
    total_suggestions: totalSuggestions,
    query_text: queryText,
    ...trace
  };
  return hasInput ? <Flex gap="$spacing20">{tokens.length > 0 ? <SearchBarDropdownSection
    hoveredIndex={hoveredIndex}
    startingIndex={0}
    setHoveredIndex={setHoveredIndex}
    toggleOpen={toggleOpen}
    suggestions={tokens}
    eventProperties={{
      suggestion_type: NavBarSearchTypes.TOKEN_SUGGESTION,
      ...eventProperties
    }}
    header={<Trans i18nKey="common.tokens" />}
  /> : <Flex py="$spacing4" px="$spacing16"><Text variant="body3"><Trans i18nKey="tokens.noneFound" /></Text></Flex>}</Flex> : (
    // Recent Searches, Trending Tokens
    <Flex gap="$spacing20">
      {shortenedHistory.length > 0 && <SearchBarDropdownSection
        hoveredIndex={hoveredIndex}
        startingIndex={0}
        setHoveredIndex={setHoveredIndex}
        toggleOpen={toggleOpen}
        suggestions={shortenedHistory}
        eventProperties={{
          suggestion_type: NavBarSearchTypes.RECENT_SEARCH,
          ...eventProperties
        }}
        header={<Trans i18nKey="tokens.selector.section.recent" />}
        headerIcon={<Clock width={20} height={20} />}
        isLoading={!searchHistory}
      />}
      <SearchBarDropdownSection
        hoveredIndex={hoveredIndex}
        startingIndex={shortenedHistory.length}
        setHoveredIndex={setHoveredIndex}
        toggleOpen={toggleOpen}
        suggestions={trendingTokens}
        eventProperties={{
          suggestion_type: NavBarSearchTypes.TOKEN_TRENDING,
          ...eventProperties
        }}
        header={<Trans i18nKey="explore.search.section.popularTokens" />}
        headerIcon={<TrendingUp width={20} height={20} />}
        headerInfoText={<Trans i18nKey="explore.search.section.popularTokenInfo" />}
        isLoading={!trendingTokenData}
      />
    </Flex>
  );
}
function ComingSoonText({ chainId }) {
  const chainName = getChainInfo(chainId).name;
  return !isBackendSupportedChainId(chainId) ? <Trans i18nKey="search.chainComing" values={{ chainName }} /> : null;
}
