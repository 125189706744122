import { forwardRef, useCallback, useMemo, useRef } from "react";
import { YStack } from "tamagui";
import { withAnimated } from "ui/src/components/factories/animated";
import { defaultHitslopInset } from "ui/src/theme";
import { useHapticFeedback } from "ui/src/utils/haptics/useHapticFeedback";
import { isTestEnv } from "utilities/src/environment/env";
export const TouchableArea = forwardRef(function TouchableArea2({
  hapticFeedback: triggerHapticFeedback = false,
  ignoreDragEvents = false,
  hapticStyle,
  scaleTo,
  onPress,
  children,
  hoverable,
  activeOpacity = 0.75,
  ...restProps
}, ref) {
  const touchActivationPositionRef = useRef(null);
  const { hapticFeedback } = useHapticFeedback();
  const onPressHandler = useCallback(
    async (event) => {
      if (!onPress) {
        return;
      }
      if (!ignoreDragEvents) {
        const { pageX, pageY } = event.nativeEvent;
        const isDragEvent = touchActivationPositionRef.current && isDrag(touchActivationPositionRef.current.pageX, touchActivationPositionRef.current.pageY, pageX, pageY);
        if (isDragEvent) {
          return;
        }
      }
      onPress(event);
      if (triggerHapticFeedback) {
        await hapticFeedback.impact(hapticStyle);
      }
    },
    [onPress, ignoreDragEvents, triggerHapticFeedback, hapticFeedback, hapticStyle]
  );
  const onPressInHandler = useMemo(() => {
    return ({ nativeEvent: { pageX, pageY } }) => {
      touchActivationPositionRef.current = { pageX, pageY };
    };
  }, []);
  return <YStack
    ref={ref}
    {...!isTestEnv() && {
      animation: "simple",
      // TODO(MOB-3059): fixes crash caused by animating shadowOffset, should be fixed in tamagui
      animateOnly: ["transform", "opacity"]
    }}
    cursor="pointer"
    hitSlop={defaultHitslopInset}
    {...restProps}
    pressStyle={{
      opacity: activeOpacity,
      scale: scaleTo ?? 1,
      ...restProps.pressStyle
    }}
    {...hoverable && {
      hoverStyle: {
        backgroundColor: "$backgroundHover",
        ...restProps.hoverStyle
      }
    }}
    onPress={onPressHandler}
    onPressIn={onPressInHandler}
  >{children}</YStack>;
});
export const AnimatedTouchableArea = withAnimated(TouchableArea);
function isDrag(activationX, activationY, releaseX, releaseY, threshold = 2) {
  const absX = Math.abs(activationX - releaseX);
  const absY = Math.abs(activationY - releaseY);
  const dragged = absX > threshold || absY > threshold;
  return dragged;
}
