"use strict";
import { OffchainOrderType } from "state/routing/types";
export var SignatureType = /* @__PURE__ */ ((SignatureType2) => {
  SignatureType2["SIGN_UNISWAPX_ORDER"] = "signUniswapXOrder";
  SignatureType2["SIGN_UNISWAPX_V2_ORDER"] = "signUniswapXV2Order";
  SignatureType2["SIGN_LIMIT"] = "signLimit";
  SignatureType2["SIGN_PRIORITY_ORDER"] = "signPriorityOrder";
  return SignatureType2;
})(SignatureType || {});
export const OFFCHAIN_ORDER_TYPE_TO_SIGNATURE_TYPE = {
  [OffchainOrderType.DUTCH_AUCTION]: "signUniswapXOrder" /* SIGN_UNISWAPX_ORDER */,
  [OffchainOrderType.DUTCH_V2_AUCTION]: "signUniswapXV2Order" /* SIGN_UNISWAPX_V2_ORDER */,
  [OffchainOrderType.LIMIT_ORDER]: "signLimit" /* SIGN_LIMIT */,
  [OffchainOrderType.PRIORITY_ORDER]: "signPriorityOrder" /* SIGN_PRIORITY_ORDER */
};
