"use strict";
import { FiatCurrency } from "uniswap/src/features/fiatCurrency/constants";
export const activeLocalCurrencyAtomName = "activeLocalCurrency";
export const migration20 = (state) => {
  if (!state) {
    return void 0;
  }
  const atomLocalCurrencyAtomValue = localStorage.getItem(activeLocalCurrencyAtomName);
  const migratedAtomCurrency = Object.values(FiatCurrency).includes(atomLocalCurrencyAtomValue) ? atomLocalCurrencyAtomValue : "USD";
  const newState = {
    ...state,
    userSettings: {
      ...state.userSettings,
      currentCurrency: migratedAtomCurrency
    }
  };
  localStorage.removeItem(activeLocalCurrencyAtomName);
  return { ...newState, _persist: { ...state._persist, version: 20 } };
};
