"use strict";
import { Locale, mapLocaleToLanguage } from "uniswap/src/features/language/constants";
export const migration19 = (state) => {
  if (!state) {
    return void 0;
  }
  const newState = { ...state };
  const oldToNew = {
    "zh-CN": Locale.ChineseSimplified,
    "zh-TW": Locale.ChineseTraditional
  };
  const oldLocale = state.user?.userLocale;
  if (oldLocale) {
    const oldLocaleTranslated = Object.keys(oldToNew).includes(oldLocale) ? oldToNew[oldLocale] : oldLocale;
    if (Object.values(Locale).includes(oldLocaleTranslated)) {
      newState.userSettings.currentLanguage = mapLocaleToLanguage[oldLocaleTranslated];
    }
  }
  delete newState.user.userLocale;
  return { ...newState, _persist: { ...state._persist, version: 19 } };
};
