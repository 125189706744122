"use strict";
import { Z_INDEX } from "theme/zIndex";
import { isWebAndroid, isWebIOS } from "utilities/src/platform";
import { createConnector } from "wagmi";
import { walletConnect } from "wagmi/connectors";
if (process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID === void 0) {
  throw new Error("REACT_APP_WALLET_CONNECT_PROJECT_ID must be a defined environment variable");
}
const WALLET_CONNECT_PROJECT_ID = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;
export const walletTypeToAmplitudeWalletType = (connectionType) => {
  switch (connectionType) {
    case "injected": {
      return "Browser Extension";
    }
    case "walletConnect": {
      return "Wallet Connect";
    }
    case "coinbaseWallet": {
      return "Coinbase Wallet";
    }
    case "uniswapWalletConnect": {
      return "Wallet Connect";
    }
    default: {
      return connectionType ?? "Network";
    }
  }
};
export const WC_PARAMS = {
  projectId: WALLET_CONNECT_PROJECT_ID,
  metadata: {
    name: "Uniswap",
    description: "Uniswap Interface",
    url: "https://app.uniswap.org",
    icons: ["https://app.uniswap.org/favicon.png"]
  },
  qrModalOptions: {
    themeVariables: {
      "--wcm-font-family": '"Inter custom", sans-serif',
      "--wcm-z-index": Z_INDEX.modal.toString()
    }
  }
};
export function uniswapWalletConnect() {
  return createConnector((config) => {
    const wc = walletConnect({
      ...WC_PARAMS,
      showQrModal: false
    })(config);
    config.emitter.on("message", ({ type, data }) => {
      if (type === "display_uri") {
        const uniswapWalletUri = `https://uniswap.org/app/wc?uri=${data}`;
        config.emitter.emit("message", { type: "display_uniswap_uri", data: uniswapWalletUri });
        if (isWebIOS || isWebAndroid) {
          window.location.href = `uniswap://wc?uri=${encodeURIComponent(data)}`;
        }
      }
    });
    return {
      ...wc,
      id: "uniswapWalletConnect",
      type: "uniswapWalletConnect",
      name: "Uniswap Wallet",
      icon: "https://app.uniswap.org/favicon.png"
    };
  });
}
