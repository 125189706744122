"use strict";
import { TransactionStatus } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
export const migration12 = (state) => {
  if (!state?.transactions) {
    return state;
  }
  for (const chainId in state.transactions) {
    const transactionsForChain = state.transactions[chainId];
    for (const txHash in transactionsForChain) {
      const { receipt, ...tx } = transactionsForChain[txHash];
      const status = receipt ? receipt.status === 1 ? TransactionStatus.Confirmed : TransactionStatus.Failed : TransactionStatus.Pending;
      tx.status = status;
      transactionsForChain[txHash] = tx;
    }
  }
  return { ...state, _persist: { ...state._persist, version: 12 } };
};
