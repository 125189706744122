"use strict";
import { ArrowChangeDown } from "components/Icons/ArrowChangeDown";
import { ArrowChangeUp } from "components/Icons/ArrowChangeUp";
import styled from "lib/styled-components";
const StyledUpArrow = styled(ArrowChangeUp)`
  color: ${({ theme, $noColor }) => $noColor ? theme.neutral2 : theme.success};
`;
const StyledDownArrow = styled(ArrowChangeDown)`
  color: ${({ theme, $noColor }) => $noColor ? theme.neutral2 : theme.critical};
`;
export function calculateDelta(start, current) {
  return (current / start - 1) * 100;
}
function isValidDelta(delta) {
  return delta !== null && delta !== void 0 && delta !== Infinity && !isNaN(delta);
}
export function DeltaArrow({ delta, noColor = false, size = 16 }) {
  if (!isValidDelta(delta)) {
    return null;
  }
  return Math.sign(delta) < 0 ? <StyledDownArrow width={size} height={size} key="arrow-down" aria-label="down" $noColor={noColor} /> : <StyledUpArrow width={size} height={size} key="arrow-up" aria-label="up" $noColor={noColor} />;
}
export const DeltaText = styled.span`
  color: ${({ theme, delta }) => delta !== void 0 ? Math.sign(delta) < 0 ? theme.critical : theme.success : theme.neutral1};
`;
