"use strict";
import { BigNumber } from "@ethersproject/bignumber";
import { useInterfaceMulticall } from "hooks/useContract";
import { useSingleCallResult } from "lib/hooks/multicall";
import { useMemo } from "react";
export default function useCurrentBlockTimestamp(options) {
  const multicall = useInterfaceMulticall();
  const resultStr = useSingleCallResult(
    multicall,
    "getCurrentBlockTimestamp",
    void 0,
    options
  )?.result?.[0]?.toString();
  return useMemo(() => typeof resultStr === "string" ? BigNumber.from(resultStr) : void 0, [resultStr]);
}
