"use strict";
import { DEFAULT_DEADLINE_FROM_NOW } from "constants/misc";
import { persistor } from "state";
import { initialState as initialListsState } from "state/lists/reducer";
import { RouterPreference } from "state/routing/types";
import { initialState as initialTransactionsState } from "state/transactions/reducer";
import { initialState as initialUserState } from "state/user/reducer";
import { SlippageTolerance } from "state/user/types";
const currentTimestamp = () => (/* @__PURE__ */ new Date()).getTime();
function tryParseOldState(value, fallback) {
  try {
    return value ? JSON.parse(value) : fallback;
  } catch (e) {
    return fallback;
  }
}
export const legacyLocalStorageMigration = async () => {
  const oldTransactions = localStorage.getItem("redux_localstorage_simple_transactions");
  const oldUser = localStorage.getItem("redux_localstorage_simple_user");
  const oldLists = localStorage.getItem("redux_localstorage_simple_lists");
  const oldSignatures = localStorage.getItem("redux_localstorage_simple_signatures");
  const newTransactions = tryParseOldState(oldTransactions, initialTransactionsState);
  const newUser = tryParseOldState(oldUser, initialUserState);
  const newLists = tryParseOldState(oldLists, initialListsState);
  const newSignatures = tryParseOldState(oldSignatures, {});
  const result = {
    user: legacyUserMigrations(newUser),
    transactions: legacyTransactionMigrations(newTransactions),
    lists: newLists,
    signatures: newSignatures,
    _persist: { version: 0, rehydrated: true }
  };
  await persistor.flush();
  localStorage.removeItem("redux_localstorage_simple_transactions");
  localStorage.removeItem("redux_localstorage_simple_user");
  localStorage.removeItem("redux_localstorage_simple_lists");
  localStorage.removeItem("redux_localstorage_simple_signatures");
  return result;
};
function legacyTransactionMigrations(state) {
  const result = JSON.parse(JSON.stringify(state));
  Object.keys(result).forEach((chainId) => {
    const chainTransactions = result[chainId];
    Object.keys(chainTransactions).forEach((hash) => {
      if (!("info" in chainTransactions[hash])) {
        delete chainTransactions[hash];
      }
    });
  });
  return result;
}
function legacyUserMigrations(state) {
  const result = JSON.parse(JSON.stringify(state));
  if (result.selectedWallet) {
    const selectedWallet = result.selectedWallet;
    if (selectedWallet === "UNIWALLET" || selectedWallet === "UNISWAP_WALLET" || selectedWallet === "WALLET_CONNECT") {
      delete result.selectedWallet;
    }
  }
  if (typeof result.userSlippageTolerance !== "number" || !Number.isInteger(result.userSlippageTolerance) || result.userSlippageTolerance < 0 || result.userSlippageTolerance > 5e3) {
    result.userSlippageTolerance = SlippageTolerance.Auto;
  } else {
    if (!result.userSlippageToleranceHasBeenMigratedToAuto && [10, 50, 100].indexOf(result.userSlippageTolerance) !== -1) {
      result.userSlippageTolerance = SlippageTolerance.Auto;
      result.userSlippageToleranceHasBeenMigratedToAuto = true;
    }
  }
  if (typeof result.userDeadline !== "number" || !Number.isInteger(result.userDeadline) || result.userDeadline < 60 || result.userDeadline > 180 * 60) {
    result.userDeadline = DEFAULT_DEADLINE_FROM_NOW;
  }
  if (typeof result.userRouterPreference !== "string") {
    result.userRouterPreference = RouterPreference.X;
  }
  if (result.userRouterPreference === "auto") {
    result.userRouterPreference = RouterPreference.X;
  }
  if ("buyFiatFlowCompleted" in result) {
    delete result.buyFiatFlowCompleted;
  }
  result.lastUpdateVersionTimestamp = currentTimestamp();
  return result;
}
