"use strict";
import { useOpenLimitOrders } from "components/AccountDrawer/MiniPortfolio/Activity/hooks";
import { TabButton } from "components/AccountDrawer/MiniPortfolio/shared";
import { useTheme } from "lib/styled-components";
import { Clock } from "react-feather";
import { Trans, useTranslation } from "uniswap/src/i18n";
function getExtraWarning(openLimitOrders) {
  if (openLimitOrders.length >= 100) {
    return <Trans i18nKey="common.limits.cancelProceed" />;
  }
  if (openLimitOrders.length >= 90) {
    return <Trans i18nKey="common.limits.approachMax" />;
  }
  return void 0;
}
export function OpenLimitOrdersButton({
  openLimitsMenu,
  account,
  disabled,
  className
}) {
  const { t } = useTranslation();
  const { openLimitOrders } = useOpenLimitOrders(account);
  const theme = useTheme();
  const extraWarning = getExtraWarning(openLimitOrders);
  if (!openLimitOrders || openLimitOrders.length < 1) {
    return null;
  }
  return <TabButton
    text={t("limit.open.count", { count: openLimitOrders.length })}
    icon={<Clock fill={theme.neutral2} color={theme.surface2} size="20px" />}
    extraWarning={extraWarning}
    onClick={openLimitsMenu}
    disabled={disabled}
    className={className}
  />;
}
