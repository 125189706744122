"use strict";
import { TransactionStatus } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
export var FiatOnRampTransactionStatus = /* @__PURE__ */ ((FiatOnRampTransactionStatus2) => {
  FiatOnRampTransactionStatus2["INITIATED"] = "INITIATED";
  FiatOnRampTransactionStatus2["PENDING"] = "PENDING";
  FiatOnRampTransactionStatus2["COMPLETE"] = "COMPLETE";
  FiatOnRampTransactionStatus2["FAILED"] = "FAILED";
  return FiatOnRampTransactionStatus2;
})(FiatOnRampTransactionStatus || {});
export function backendStatusToFiatOnRampStatus(status) {
  switch (status) {
    case TransactionStatus.Confirmed:
      return "COMPLETE" /* COMPLETE */;
    case TransactionStatus.Pending:
      return "PENDING" /* PENDING */;
    case TransactionStatus.Failed:
      return "FAILED" /* FAILED */;
  }
}
export var FiatOnRampTransactionType = /* @__PURE__ */ ((FiatOnRampTransactionType2) => {
  FiatOnRampTransactionType2["BUY"] = "BUY";
  FiatOnRampTransactionType2["TRANSFER"] = "TRANSFER";
  return FiatOnRampTransactionType2;
})(FiatOnRampTransactionType || {});
