"use strict";
import MerkleDistributorJSON from "@uniswap/merkle-distributor/build/MerkleDistributor.json";
import { CurrencyAmount, MERKLE_DISTRIBUTOR_ADDRESS } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { useAccount } from "hooks/useAccount";
import { useContract } from "hooks/useContract";
import JSBI from "jsbi";
import { useSingleCallResult } from "lib/hooks/multicall";
import { useEffect, useState } from "react";
import { useTransactionAdder } from "state/transactions/hooks";
import { TransactionType } from "state/transactions/types";
import { UNI } from "uniswap/src/constants/tokens";
import { isAddress } from "utilities/src/addresses";
import { logger } from "utilities/src/logger/logger";
import { calculateGasMargin } from "utils/calculateGasMargin";
function useMerkleDistributorContract() {
  const account = useAccount();
  return useContract(
    account.chainId ? MERKLE_DISTRIBUTOR_ADDRESS[account.chainId] : void 0,
    MerkleDistributorJSON.abi,
    true
  );
}
let FETCH_CLAIM_MAPPING_PROMISE = null;
function fetchClaimMapping() {
  return FETCH_CLAIM_MAPPING_PROMISE ?? (FETCH_CLAIM_MAPPING_PROMISE = fetch(
    `https://raw.githubusercontent.com/Uniswap/mrkl-drop-data-chunks/final/chunks/mapping.json`
  ).then((res) => res.json()).catch((error) => {
    logger.warn("claim/hooks", "fetchClaimMapping", "Claim mapping fetch failed", error);
    FETCH_CLAIM_MAPPING_PROMISE = null;
  }));
}
const FETCH_CLAIM_FILE_PROMISES = {};
function fetchClaimFile(key) {
  return FETCH_CLAIM_FILE_PROMISES[key] ?? (FETCH_CLAIM_FILE_PROMISES[key] = fetch(
    `https://raw.githubusercontent.com/Uniswap/mrkl-drop-data-chunks/final/chunks/${key}.json`
  ).then((res) => res.json()).catch((error) => {
    logger.warn("claim/hooks", "fetchClaimFile", "Claim file fetch failed", error);
    delete FETCH_CLAIM_FILE_PROMISES[key];
  }));
}
const FETCH_CLAIM_PROMISES = {};
function fetchClaim(account) {
  const formatted = isAddress(account);
  if (!formatted) {
    return Promise.reject(new Error("Invalid address"));
  }
  return FETCH_CLAIM_PROMISES[account] ?? (FETCH_CLAIM_PROMISES[account] = fetchClaimMapping().then((mapping) => {
    const sorted = Object.keys(mapping).sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1);
    for (const startingAddress of sorted) {
      const lastAddress = mapping[startingAddress];
      if (startingAddress.toLowerCase() <= formatted.toLowerCase()) {
        if (formatted.toLowerCase() <= lastAddress.toLowerCase()) {
          return startingAddress;
        }
      } else {
        throw new Error(`Claim for ${formatted} was not found in partial search`);
      }
    }
    throw new Error(`Claim for ${formatted} was not found after searching all mappings`);
  }).then(fetchClaimFile).then((result) => {
    if (result[formatted]) {
      return result[formatted];
    }
    throw new Error(`Claim for ${formatted} was not found in claim file!`);
  }).catch((error) => {
    logger.debug("claim/hooks", "fetchClaim", "Claim fetch failed", error);
    throw error;
  }));
}
function useUserClaimData(account) {
  const { chainId } = useAccount();
  const [claimInfo, setClaimInfo] = useState({});
  useEffect(() => {
    if (!account || chainId !== 1) {
      return;
    }
    fetchClaim(account).then(
      (accountClaimInfo) => setClaimInfo((claimInfo2) => {
        return {
          ...claimInfo2,
          [account]: accountClaimInfo
        };
      })
    ).catch(() => {
      setClaimInfo((claimInfo2) => {
        return {
          ...claimInfo2,
          [account]: null
        };
      });
    });
  }, [account, chainId]);
  return account && chainId === 1 ? claimInfo[account] : null;
}
export function useUserHasAvailableClaim(account) {
  const userClaimData = useUserClaimData(account);
  const distributorContract = useMerkleDistributorContract();
  const isClaimedResult = useSingleCallResult(distributorContract, "isClaimed", [userClaimData?.index]);
  return Boolean(userClaimData && !isClaimedResult.loading && isClaimedResult.result?.[0] === false);
}
export function useUserUnclaimedAmount(account) {
  const { chainId } = useAccount();
  const userClaimData = useUserClaimData(account);
  const canClaim = useUserHasAvailableClaim(account);
  const uni = chainId ? UNI[chainId] : void 0;
  if (!uni) {
    return void 0;
  }
  if (!canClaim || !userClaimData) {
    return CurrencyAmount.fromRawAmount(uni, JSBI.BigInt(0));
  }
  return CurrencyAmount.fromRawAmount(uni, JSBI.BigInt(userClaimData.amount));
}
export function useClaimCallback(address) {
  const account = useAccount();
  const { provider } = useWeb3React();
  const claimData = useUserClaimData(address);
  const unclaimedAmount = useUserUnclaimedAmount(address);
  const addTransaction = useTransactionAdder();
  const distributorContract = useMerkleDistributorContract();
  const claimCallback = async function() {
    if (!claimData || !address || !provider || !account.chainId || !distributorContract) {
      return void 0;
    }
    const args = [claimData.index, address, claimData.amount, claimData.proof];
    return distributorContract.estimateGas["claim"](...args, {}).then((estimatedGasLimit) => {
      return distributorContract.claim(...args, { value: null, gasLimit: calculateGasMargin(estimatedGasLimit) }).then((response) => {
        addTransaction(response, {
          type: TransactionType.CLAIM,
          recipient: address,
          uniAmountRaw: unclaimedAmount?.quotient.toString()
        });
        return response.hash;
      });
    });
  };
  return { claimCallback };
}
