"use strict";
import { isBetaEnv, isProdEnv } from "utilities/src/environment/env";
export function getEnvName() {
  if (isBetaEnv()) {
    return "staging";
  }
  if (isProdEnv()) {
    return "production";
  }
  return "development";
}
