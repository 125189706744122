"use strict";
import { ChartType } from "components/Charts/utils";
import { checkDataQuality } from "components/Tokens/TokenDetails/ChartSection/util";
import { useContext, useMemo } from "react";
import { ExploreContext } from "state/explore";
import { HistoryDuration } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { FeatureFlags } from "uniswap/src/features/gating/flags";
import { useFeatureFlagWithLoading } from "uniswap/src/features/gating/hooks";
function mapDataByTimestamp(v2Data, v3Data, v4Data) {
  const dataByTime = {};
  v2Data?.forEach((v2Point) => {
    const timestamp = Number(v2Point.timestamp);
    dataByTime[timestamp] = { ["v2"]: Number(v2Point.value), ["v3"]: 0, ["v4"]: 0 };
  });
  v3Data?.forEach((v3Point) => {
    const timestamp = Number(v3Point.timestamp);
    if (!dataByTime[timestamp]) {
      dataByTime[timestamp] = { ["v2"]: 0, ["v3"]: Number(v3Point.value), ["v4"]: 0 };
    } else {
      dataByTime[timestamp]["v3"] = Number(v3Point.value);
    }
  });
  v4Data?.forEach((v4Point) => {
    const timestamp = Number(v4Point.timestamp);
    if (!dataByTime[timestamp]) {
      dataByTime[timestamp] = { ["v2"]: 0, ["v3"]: 0, ["v4"]: Number(v4Point.value) };
    } else {
      dataByTime[timestamp]["v4"] = Number(v4Point.value);
    }
  });
  return dataByTime;
}
export function useHistoricalProtocolVolume(duration) {
  const {
    protocolStats: { data, isLoading }
  } = useContext(ExploreContext);
  const { value: isV4EverywhereEnabledLoaded, isLoading: isV4EverywhereLoading } = useFeatureFlagWithLoading(
    FeatureFlags.V4Everywhere
  );
  const isV4EverywhereEnabled = isV4EverywhereEnabledLoaded || isV4EverywhereLoading;
  let v4Data;
  let v3Data;
  let v2Data;
  switch (duration) {
    case HistoryDuration.Max:
      v2Data = data?.historicalProtocolVolume?.Max?.v2;
      v3Data = data?.historicalProtocolVolume?.Max?.v3;
      v4Data = data?.historicalProtocolVolume?.Max?.v4;
      break;
    case HistoryDuration.Year:
      v2Data = data?.historicalProtocolVolume?.Year?.v2;
      v3Data = data?.historicalProtocolVolume?.Year?.v3;
      v4Data = data?.historicalProtocolVolume?.Year?.v4;
      break;
    default:
      v2Data = data?.historicalProtocolVolume?.Month?.v2;
      v3Data = data?.historicalProtocolVolume?.Month?.v3;
      v4Data = data?.historicalProtocolVolume?.Month?.v4;
      break;
  }
  return useMemo(() => {
    const dataByTime = mapDataByTimestamp(v2Data, v3Data, isV4EverywhereEnabled ? v4Data : void 0);
    const entries = Object.entries(dataByTime).reduce((acc, [timestamp, values]) => {
      acc.push({
        time: Number(timestamp),
        values: {
          ["SUBGRAPH_V2"]: values["v2"],
          ["SUBGRAPH_V3"]: values["v3"],
          ["SUBGRAPH_V4"]: isV4EverywhereEnabled ? values["v4"] : void 0
        }
      });
      return acc;
    }, []);
    const dataQuality = checkDataQuality(entries, ChartType.VOLUME, duration);
    return { chartType: ChartType.VOLUME, entries, loading: isLoading, dataQuality };
  }, [duration, isLoading, isV4EverywhereEnabled, v2Data, v3Data, v4Data]);
}
export function useDailyProtocolTVL() {
  const {
    protocolStats: { data, isLoading }
  } = useContext(ExploreContext);
  const { value: isV4EverywhereEnabledLoaded, isLoading: isV4EverywhereLoading } = useFeatureFlagWithLoading(
    FeatureFlags.V4Everywhere
  );
  const isV4EverywhereEnabled = isV4EverywhereEnabledLoaded || isV4EverywhereLoading;
  const v4Data = data?.dailyProtocolTvl?.v4;
  const v3Data = data?.dailyProtocolTvl?.v3;
  const v2Data = data?.dailyProtocolTvl?.v2;
  return useMemo(() => {
    const dataByTime = mapDataByTimestamp(v2Data, v3Data, isV4EverywhereEnabled ? v4Data : void 0);
    const entries = Object.entries(dataByTime).map(([timestamp, values]) => ({
      time: Number(timestamp),
      values: isV4EverywhereEnabled ? [values["v2"], values["v3"], values["v4"]] : [values["v2"], values["v3"]]
    }));
    const dataQuality = checkDataQuality(entries, ChartType.TVL, HistoryDuration.Year);
    return { chartType: ChartType.TVL, entries, loading: isLoading, dataQuality };
  }, [isLoading, isV4EverywhereEnabled, v2Data, v3Data, v4Data]);
}
