import { useEffect, useState } from "react";
import { Image } from "tamagui";
import { FastImageWrapper } from "ui/src/components/UniversalImage/internal/FastImageWrapper";
import { PlainImage } from "ui/src/components/UniversalImage/internal/PlainImage";
import { SvgImage } from "ui/src/components/UniversalImage/internal/SvgImage";
import { Flex } from "ui/src/components/layout/Flex";
import { Loader } from "ui/src/loading/Loader";
import { isSVGUri, uriToHttpUrls } from "utilities/src/format/urls";
import { logger } from "utilities/src/logger/logger";
const LOADING_FALLBACK = <Loader.Image />;
export function UniversalImage({
  uri,
  size,
  style,
  fallback,
  fastImage = false,
  testID,
  onLoad,
  allowLocalUri = false
}) {
  const [width, setWidth] = useState(size.width);
  const [height, setHeight] = useState(size.height);
  const computedSize = { width, height, aspectRatio: size.aspectRatio };
  const [errored, setErrored] = useState(false);
  const hasWidthAndHeight = computedSize.width !== void 0 && computedSize.height !== void 0;
  const hasHeightAndRatio = computedSize.height !== void 0 && computedSize.aspectRatio !== void 0;
  const sizeKnown = hasWidthAndHeight || hasHeightAndRatio;
  const isRequireSource = typeof uri === "number";
  useEffect(() => {
    setWidth(size.width);
    setHeight(size.height);
  }, [size.height, size.width]);
  useEffect(() => {
    if (!uri || sizeKnown || !fastImage || isRequireSource) {
      return;
    }
    Image.getSize(
      uri,
      (calculatedWidth, calculatedHeight) => {
        setWidth(calculatedWidth);
        setHeight(calculatedHeight);
      },
      () => setErrored(true)
    );
  }, [width, height, sizeKnown, uri, fastImage, isRequireSource]);
  if (isRequireSource) {
    return <Image height={size.height} source={uri} width={size.width} />;
  }
  if (!uri && fallback) {
    return fallback;
  }
  if (!uri || !sizeKnown && !errored) {
    if (style?.loadingContainer) {
      return <Flex style={style?.loadingContainer}>{LOADING_FALLBACK}</Flex>;
    }
    return LOADING_FALLBACK;
  }
  const imageHttpUrl = uriToHttpUrls(uri, { allowLocalUri })[0];
  if (!imageHttpUrl || errored) {
    const errMsg = errored ? "could not compute sizing information for uri" : "Could not retrieve and format remote image for uri";
    logger.warn("UniversalImage", "UniversalImage", errMsg, { data: uri });
    return fallback ?? null;
  }
  if (fastImage && sizeKnown) {
    return <FastImageWrapper
      setError={() => setErrored(true)}
      size={computedSize}
      testID={testID ? `svg-${testID}` : void 0}
      uri={uri}
    />;
  }
  if (isSVGUri(imageHttpUrl)) {
    return <Flex
      alignItems="center"
      backgroundColor={style?.image?.backgroundColor}
      borderRadius={style?.image?.borderRadius}
      verticalAlign={style?.image?.verticalAlign}
      height={size.height}
      overflow="hidden"
      testID={testID ? `svg-${testID}` : void 0}
      width={size.width}
    ><SvgImage autoplay={true} fallback={fallback} size={size} uri={imageHttpUrl} /></Flex>;
  }
  return <PlainImage
    fallback={fallback}
    resizeMode={size.resizeMode}
    size={computedSize}
    style={style?.image}
    testID={testID ? `img-${testID}` : void 0}
    uri={imageHttpUrl}
    onLoad={onLoad}
  />;
}
