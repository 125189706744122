"use strict";
export const AllNetworksIcon = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M7.0835 3.33337H4.5835C3.75016 3.33337 3.3335 3.75004 3.3335 4.58337V7.08337C3.3335 7.91671 3.75016 8.33337 4.5835 8.33337H7.0835C7.91683 8.33337 8.3335 7.91671 8.3335 7.08337V4.58337C8.3335 3.75004 7.91683 3.33337 7.0835 3.33337Z"
    fill="#FF0420"
  />
  <path
    d="M7.0835 3.33337H4.5835C3.75016 3.33337 3.3335 3.75004 3.3335 4.58337V7.08337C3.3335 7.91671 3.75016 8.33337 4.5835 8.33337H7.0835C7.91683 8.33337 8.3335 7.91671 8.3335 7.08337V4.58337C8.3335 3.75004 7.91683 3.33337 7.0835 3.33337Z"
    fill="#FF0420"
  />
  <path
    d="M7.0835 11.6666H4.5835C3.75016 11.6666 3.3335 12.0833 3.3335 12.9166V15.4166C3.3335 16.25 3.75016 16.6666 4.5835 16.6666H7.0835C7.91683 16.6666 8.3335 16.25 8.3335 15.4166V12.9166C8.3335 12.0833 7.91683 11.6666 7.0835 11.6666Z"
    fill="#135FFD"
  />
  <path
    d="M7.0835 11.6666H4.5835C3.75016 11.6666 3.3335 12.0833 3.3335 12.9166V15.4166C3.3335 16.25 3.75016 16.6666 4.5835 16.6666H7.0835C7.91683 16.6666 8.3335 16.25 8.3335 15.4166V12.9166C8.3335 12.0833 7.91683 11.6666 7.0835 11.6666Z"
    fill="#135FFD"
  />
  <path
    d="M15.4165 3.33337H12.9165C12.0832 3.33337 11.6665 3.75004 11.6665 4.58337V7.08337C11.6665 7.91671 12.0832 8.33337 12.9165 8.33337H15.4165C16.2498 8.33337 16.6665 7.91671 16.6665 7.08337V4.58337C16.6665 3.75004 16.2498 3.33337 15.4165 3.33337Z"
    fill="#8247E5"
  />
  <path
    d="M15.4165 3.33337H12.9165C12.0832 3.33337 11.6665 3.75004 11.6665 4.58337V7.08337C11.6665 7.91671 12.0832 8.33337 12.9165 8.33337H15.4165C16.2498 8.33337 16.6665 7.91671 16.6665 7.08337V4.58337C16.6665 3.75004 16.2498 3.33337 15.4165 3.33337Z"
    fill="#8247E5"
  />
  <path
    d="M15.4165 11.6666H12.9165C12.0832 11.6666 11.6665 12.0833 11.6665 12.9166V15.4166C11.6665 16.25 12.0832 16.6666 12.9165 16.6666H15.4165C16.2498 16.6666 16.6665 16.25 16.6665 15.4166V12.9166C16.6665 12.0833 16.2498 11.6666 15.4165 11.6666Z"
    fill="#F0B90B"
  />
  <path
    d="M15.4165 11.6666H12.9165C12.0832 11.6666 11.6665 12.0833 11.6665 12.9166V15.4166C11.6665 16.25 12.0832 16.6666 12.9165 16.6666H15.4165C16.2498 16.6666 16.6665 16.25 16.6665 15.4166V12.9166C16.6665 12.0833 16.2498 11.6666 15.4165 11.6666Z"
    fill="#F0B90B"
  />
</svg>;
