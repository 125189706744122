import { logger } from "utilities/src/logger/logger";
export function uriToHttpUrls(uri, options) {
  const protocol = uri.split(":")[0]?.toLowerCase();
  switch (protocol) {
    case uri: {
      return options?.allowLocalUri ? [uri] : [];
    }
    case "data":
      return [uri];
    case "https":
      return [uri];
    case "http":
      return ["https" + uri.slice(4), uri];
    case "ipfs": {
      const hash = uri.match(/^ipfs:(\/\/)?(ipfs\/)?(.*)$/i)?.[3];
      return [`https://ipfs.io/ipfs/${hash}/`, `https://hardbin.com/ipfs/${hash}/`];
    }
    case "ipns": {
      const name = uri.match(/^ipns:(\/\/)?(.*)$/i)?.[2];
      return [`https://ipfs.io/ipns/${name}/`, `https://hardbin.com/ipns/${name}/`];
    }
    case "ar": {
      const tx = uri.match(/^ar:(\/\/)?(.*)$/i)?.[2];
      return [`https://arweave.net/${tx}`];
    }
    default:
      return [];
  }
}
export function isSegmentUri(uri, extension) {
  if (typeof uri !== "string" || !uri.trim()) {
    return false;
  }
  try {
    if (!/^https?:\/\/.+/i.test(uri)) {
      return false;
    }
    const url = new URL(uri);
    const pathname = url.pathname;
    return pathname.toLowerCase().endsWith(extension);
  } catch {
    return false;
  }
}
export function isSVGUri(uri) {
  return isSegmentUri(uri, ".svg");
}
export function isGifUri(uri) {
  return isSegmentUri(uri, ".gif");
}
function parseUrl(url) {
  if (!url) {
    return void 0;
  }
  try {
    return new URL(url);
  } catch (error) {
    logger.error(error, {
      tags: { file: "format/urls", function: "parseUrl" },
      extra: { url }
    });
    return void 0;
  }
}
export function formatDappURL(url) {
  return parseUrl(url)?.origin?.replace("https://", "") ?? url?.slice(0, 20);
}
export function extractUrlHost(url) {
  return parseUrl(url)?.host;
}
export function extractBaseUrl(url) {
  return parseUrl(url)?.origin;
}
