"use strict";
export var TokenType = /* @__PURE__ */ ((TokenType2) => {
  TokenType2["ERC20"] = "ERC20";
  TokenType2["ERC721"] = "ERC721";
  TokenType2["ERC1155"] = "ERC1155";
  TokenType2["Dust"] = "Dust";
  TokenType2["Cryptopunk"] = "Cryptopunk";
  return TokenType2;
})(TokenType || {});
export var Markets = /* @__PURE__ */ ((Markets2) => {
  Markets2["LooksRare"] = "looksrare";
  Markets2["X2Y2"] = "x2y2";
  Markets2["NFT20"] = "nft20";
  Markets2["NFTX"] = "nftx";
  Markets2["Opensea"] = "opensea";
  Markets2["Rarible"] = "rarible";
  Markets2["Sudoswap"] = "sudoswap";
  Markets2["Cryptopunks"] = "cryptopunks";
  Markets2["Gem"] = "gem";
  Markets2["Foundation"] = "foundation";
  Markets2["Zora"] = "zora";
  Markets2["Blur"] = "blur";
  Markets2["Ensvision"] = "ensvision";
  return Markets2;
})(Markets || {});
export const isPooledMarket = (market) => {
  if (!market) {
    return false;
  }
  return market === "nftx" /* NFTX */ || market === "nft20" /* NFT20 */ || market === "sudoswap" /* Sudoswap */;
};
export var DetailsOrigin = /* @__PURE__ */ ((DetailsOrigin2) => {
  DetailsOrigin2["COLLECTION"] = "collection";
  DetailsOrigin2["PROFILE"] = "profile";
  DetailsOrigin2["EXPLORE"] = "explore";
  return DetailsOrigin2;
})(DetailsOrigin || {});
