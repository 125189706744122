"use strict";
export const migration7 = (state) => {
  if (!state) {
    return state;
  }
  const userHidAndroidAnnouncementBanner = state?.user?.hideAndroidAnnouncementBanner;
  if (state?.user && "hideAndroidAnnouncementBanner" in state.user) {
    delete state.user["hideAndroidAnnouncementBanner"];
  }
  if (state?.user && userHidAndroidAnnouncementBanner) {
    return {
      ...state,
      user: {
        ...state.user,
        hideAppPromoBanner: userHidAndroidAnnouncementBanner
      },
      _persist: {
        ...state._persist,
        version: 7
      }
    };
  }
  return {
    ...state,
    _persist: {
      ...state._persist,
      version: 7
    }
  };
};
