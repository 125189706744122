"use strict";
const EXPLORER_HOSTNAMES = {
  "bscscan.com": true,
  "etherscan.io": true,
  "sepolia.etherscan.io": true,
  "optimistic.etherscan.io": true,
  "goerli-optimism.etherscan.io": true,
  "arbiscan.io": true,
  "snowtrace.io": true
};
export function anonymizeLink(href) {
  try {
    const url = new URL(href);
    if (EXPLORER_HOSTNAMES[url.hostname]) {
      const pathPieces = url.pathname.split("/");
      const anonymizedPath = pathPieces.map((pc) => /0x[a-fA-F0-9]+/.test(pc) ? "***" : pc).join("/");
      return `${url.protocol}//${url.hostname}${anonymizedPath}`;
    }
    return href;
  } catch (error) {
    return href;
  }
}
