"use strict";
import { axisBottom, select } from "d3";
import styled from "lib/styled-components";
import { useMemo } from "react";
const StyledGroup = styled.g`
  line {
    display: none;
  }

  text {
    color: ${({ theme }) => theme.neutral2};
    transform: translateY(5px);
  }
`;
const Axis = ({ axisGenerator }) => {
  const axisRef = (axis) => {
    axis && select(axis).call(axisGenerator).call((g) => g.select(".domain").remove());
  };
  return <g ref={axisRef} />;
};
export const AxisBottom = ({
  xScale,
  innerHeight,
  offset = 0
}) => useMemo(
  () => <StyledGroup transform={`translate(0, ${innerHeight + offset})`}><Axis axisGenerator={axisBottom(xScale).ticks(6)} /></StyledGroup>,
  [innerHeight, offset, xScale]
);
