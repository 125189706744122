"use strict";
import { PortfolioLogo } from "components/AccountDrawer/MiniPortfolio/PortfolioLogo";
import { NATIVE_CHAIN_ID } from "constants/tokens";
import { gqlToCurrency } from "graphql/data/util";
import useNativeCurrency from "lib/hooks/useNativeCurrency";
import { useMemo } from "react";
import { UniverseChainId } from "uniswap/src/features/chains/types";
import { getChainIdFromChainUrlParam } from "utils/chainParams";
export default function QueryTokenLogo(props) {
  const chainId = getChainIdFromChainUrlParam(props.token?.chain.toLowerCase()) ?? UniverseChainId.Mainnet;
  const isNative = props.token?.address === NATIVE_CHAIN_ID;
  const isTokenStat = !!props.token && "volume" in props.token;
  const nativeCurrency = useNativeCurrency(chainId);
  const currency = isNative ? nativeCurrency : props.token && !isTokenStat ? gqlToCurrency(props.token) : void 0;
  const logoUrl = !!props.token && "id" in props.token ? props.token?.project?.logoUrl : props.token?.logo;
  const currencies = useMemo(
    () => isTokenStat && !isNative ? void 0 : [currency],
    [currency, isNative, isTokenStat]
  );
  return <PortfolioLogo currencies={currencies} chainId={chainId} images={[logoUrl]} {...props} />;
}
