import { ApolloLink, from, gql } from "@apollo/client";
import { AUTH_TYPE } from "aws-appsync-auth-link";
import { AppSyncRealTimeSubscriptionHandshakeLink } from "aws-appsync-subscription-link/lib/realtime-subscription-handshake-link";
import { ONE_MINUTE_MS } from "utilities/src/time/time";
import { Observable } from "zen-observable-ts";
const Heartbeat = gql`
  mutation ($subscriptionId: ID!) {
    heartbeat(subscriptionId: $subscriptionId, type: ASSET_ACTIVITY) {
      success
    }
  }
`;
const Unsubscribe = gql`
  mutation ($subscriptionId: ID!) {
    unsubscribe(subscriptionId: $subscriptionId, type: ASSET_ACTIVITY) {
      success
    }
  }
`;
function createKeepaliveLink(client) {
  return new ApolloLink((operation, forward) => {
    const observable = forward(operation);
    return new Observable((observer) => {
      const subscription = observable.subscribe(observer);
      const interval = setInterval(async () => {
        const {
          data: {
            heartbeat: { success }
          }
        } = await client.mutate({ mutation: Heartbeat, variables: operation.variables });
        if (!success) {
          observer.error("Failed to keepalive subscription");
        }
      }, ONE_MINUTE_MS * 3);
      return () => {
        clearInterval(interval);
        subscription.unsubscribe();
        client.mutate({ mutation: Unsubscribe, variables: operation.variables });
      };
    });
  });
}
function createConnectionHeaderLink() {
  return new ApolloLink((operation, forward) => {
    operation.setContext((context) => ({
      ...context,
      headers: { ...context.headers, upgrade: "websocket" }
    }));
    return forward(operation);
  });
}
export function createSubscriptionLink({
  uri,
  region = "",
  // left blank for a custom domain name (eg realtime.gateway.uniswap.org)
  token
}, client) {
  const auth = { type: AUTH_TYPE.AWS_LAMBDA, token };
  return from([
    createKeepaliveLink(client),
    createConnectionHeaderLink(),
    new AppSyncRealTimeSubscriptionHandshakeLink({ url: uri, region, auth })
  ]);
}
