import { useIsDarkMode } from "ui/src/hooks/useIsDarkMode";
import { colors, opacify } from "ui/src/theme/color";
export function useShadowPropsShort() {
  const isDarkMode = useIsDarkMode();
  return {
    shadowColor: isDarkMode ? "rgba(0,\xA00,\xA00,\xA00.24)" : "rgba(0,\xA00,\xA00,\xA00.02)",
    shadowOffset: { width: 0, height: 1 },
    shadowRadius: 6
  };
}
export function useShadowPropsMedium() {
  const isDarkMode = useIsDarkMode();
  return {
    shadowColor: isDarkMode ? opacify(60, colors.black) : opacify(16, colors.black),
    shadowOffset: { width: 0, height: 6 },
    shadowRadius: 12
  };
}
