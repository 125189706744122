"use strict";
function isWeb3Provider(provider) {
  return "provider" in provider;
}
function isWalletConnectProvider(provider) {
  return provider.isWalletConnect;
}
export var WalletType = /* @__PURE__ */ ((WalletType2) => {
  WalletType2["WALLET_CONNECT"] = "WalletConnect";
  WalletType2["INJECTED"] = "Injected";
  return WalletType2;
})(WalletType || {});
function getWalletConnectMeta(provider) {
  const metadata = provider.session?.peer.metadata;
  return {
    type: "WalletConnect" /* WALLET_CONNECT */,
    agent: metadata ? `${metadata.name} (WalletConnect)` : "(WalletConnect)",
    ...metadata
  };
}
function getInjectedMeta(provider) {
  const properties = Object.getOwnPropertyNames(provider);
  const names = properties.filter((name) => name.match(/^is.*$/) && provider[name] === true).map((name) => name.slice(2)) ?? [];
  names.sort((a, b) => a === "MetaMask" ? 1 : b === "MetaMask" ? -1 : 0);
  if (properties.includes("qrUrl") && provider["qrUrl"]) {
    names.push("qrUrl");
  }
  return {
    type: "Injected" /* INJECTED */,
    agent: [...names, "(Injected)"].join(" "),
    name: names[0]
    // TODO(WEB-2914): Populate description, url, and icons for known wallets.
  };
}
export function getWalletMeta(provider) {
  if (!isWeb3Provider(provider)) {
    return void 0;
  }
  if (isWalletConnectProvider(provider.provider)) {
    return getWalletConnectMeta(provider.provider);
  } else {
    return getInjectedMeta(provider.provider);
  }
}
