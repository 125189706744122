"use strict";
import { parseEther } from "ethers/lib/utils";
import { wrapScientificNotation } from "nft/utils";
import { useCallback, useMemo } from "react";
import {
  NftAssetSortableField,
  useAssetQuery
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
function formatAssetQueryData(queryAsset, totalCount) {
  const asset = queryAsset.node;
  const ethPrice = parseEther(wrapScientificNotation(asset.listings?.edges[0]?.node.price.value ?? 0)).toString();
  return {
    id: asset.id,
    address: asset?.collection?.nftContracts?.[0]?.address ?? "",
    notForSale: asset.listings?.edges?.length === 0,
    collectionName: asset.collection?.name,
    collectionSymbol: asset.collection?.image?.url,
    imageUrl: asset.image?.url,
    animationUrl: asset.animationUrl,
    marketplace: asset.listings?.edges[0]?.node?.marketplace?.toLowerCase(),
    name: asset.name,
    priceInfo: {
      ETHPrice: ethPrice,
      baseAsset: "ETH",
      baseDecimals: "18",
      basePrice: ethPrice
    },
    susFlag: asset.suspiciousFlag,
    sellorders: asset.listings?.edges.map((listingNode) => {
      return {
        ...listingNode.node,
        protocolParameters: listingNode.node?.protocolParameters ? JSON.parse(listingNode.node?.protocolParameters.toString()) : void 0
      };
    }),
    smallImageUrl: asset.smallImage?.url,
    tokenId: asset.tokenId ?? "",
    tokenType: asset.collection?.nftContracts?.[0]?.standard,
    totalCount,
    collectionIsVerified: asset.collection?.isVerified,
    rarity: {
      primaryProvider: "Rarity Sniper",
      // TODO update when backend adds more providers
      providers: asset.rarities?.map((rarity) => {
        return {
          ...rarity,
          provider: "Rarity Sniper"
        };
      })
    },
    ownerAddress: asset.ownerAddress,
    creator: {
      profile_img_url: asset.collection?.creator?.profileImage?.url,
      address: asset.collection?.creator?.address
    },
    metadataUrl: asset.metadataUrl
  };
}
export const ASSET_PAGE_SIZE = 25;
const defaultAssetFetcherParams = {
  orderBy: NftAssetSortableField.Price,
  asc: true,
  // tokenSearchQuery must be specified so that this exactly matches the initial query.
  filter: { listed: false, tokenSearchQuery: "" },
  first: ASSET_PAGE_SIZE
};
export function useNftAssets(params) {
  const variables = useMemo(() => ({ ...defaultAssetFetcherParams, ...params }), [params]);
  const { data, loading, fetchMore } = useAssetQuery({
    variables
  });
  const hasNext = data?.nftAssets?.pageInfo?.hasNextPage;
  const loadMore = useCallback(
    () => fetchMore({
      variables: {
        after: data?.nftAssets?.pageInfo?.endCursor
      }
    }),
    [data, fetchMore]
  );
  const assets = useMemo(
    () => data?.nftAssets?.edges?.map((queryAsset) => {
      return formatAssetQueryData(queryAsset, data.nftAssets?.totalCount);
    }),
    [data?.nftAssets?.edges, data?.nftAssets?.totalCount]
  );
  return useMemo(() => {
    return {
      data: assets,
      hasNext,
      loading,
      loadMore
    };
  }, [assets, hasNext, loadMore, loading]);
}
const DEFAULT_SWEEP_AMOUNT = 50;
function useSweepFetcherVars({ contractAddress, markets, price, traits }) {
  const filter = useMemo(
    () => ({
      listed: true,
      maxPrice: price?.high?.toString(),
      minPrice: price?.low?.toString(),
      traits: traits && traits.length > 0 ? traits?.map((trait) => {
        return { name: trait.trait_type, values: [trait.trait_value] };
      }) : void 0,
      marketplaces: markets && markets.length > 0 ? markets?.map((market) => market.toUpperCase()) : void 0
    }),
    [markets, price?.high, price?.low, traits]
  );
  return useMemo(
    () => ({
      address: contractAddress,
      orderBy: NftAssetSortableField.Price,
      asc: true,
      first: DEFAULT_SWEEP_AMOUNT,
      filter
    }),
    [contractAddress, filter]
  );
}
export function useSweepNftAssets(params) {
  const variables = useSweepFetcherVars(params);
  const { data, loading } = useAssetQuery({
    variables,
    // This prevents overwriting the page's call to assets for cards shown
    fetchPolicy: "no-cache"
  });
  const assets = useMemo(
    () => data?.nftAssets?.edges?.map((queryAsset) => {
      return formatAssetQueryData(queryAsset, data.nftAssets?.totalCount);
    }),
    [data?.nftAssets?.edges, data?.nftAssets?.totalCount]
  );
  return useMemo(() => ({ data: assets, loading }), [assets, loading]);
}
