"use strict";
import { Flex, styled } from "ui/src";
export var PreferencesView = /* @__PURE__ */ ((PreferencesView2) => {
  PreferencesView2["SETTINGS"] = "Settings";
  PreferencesView2["LANGUAGE"] = "Language";
  PreferencesView2["CURRENCY"] = "Currency";
  return PreferencesView2;
})(PreferencesView || {});
export const SettingsColumn = styled(Flex, {
  width: "100%"
});
