"use strict";
import { DEFAULT_DEADLINE_FROM_NOW } from "constants/misc";
export const migration1 = (state) => {
  if (state?.user && state.user?.userDeadline === 1800) {
    return {
      ...state,
      user: {
        ...state.user,
        userDeadline: DEFAULT_DEADLINE_FROM_NOW
      },
      _persist: {
        ...state._persist,
        version: 1
      }
    };
  }
  return state;
};
