import { useFocusEffect } from "@react-navigation/core";
import { BrowserEvent, SharedEventName } from "@uniswap/analytics-events";
import React, { memo, useEffect, useId, useMemo } from "react";
import { isWeb } from "utilities/src/platform";
import { analytics } from "utilities/src/telemetry/analytics/analytics";
import { useAnalyticsNavigationContext } from "utilities/src/telemetry/trace/AnalyticsNavigationContext";
import { TraceContext, useTrace } from "utilities/src/telemetry/trace/TraceContext";
import { getEventHandlers } from "utilities/src/telemetry/trace/utils";
export function getEventsFromProps(logPress, logFocus, logKeyPress) {
  const events = [];
  if (logPress) {
    events.push(isWeb ? "onClick" : "onPress");
  }
  if (logFocus) {
    events.push(BrowserEvent.onFocus);
  }
  if (logKeyPress) {
    events.push(BrowserEvent.onKeyPress);
  }
  return events;
}
const devDoubleLogDisableMap = {};
function _Trace({
  children,
  logImpression,
  eventOnTrigger,
  logPress,
  logFocus,
  logKeyPress,
  directFromPage,
  screen,
  page,
  section,
  element,
  modal,
  properties
}) {
  const id = useId();
  const { useIsPartOfNavigationTree, shouldLogScreen } = useAnalyticsNavigationContext();
  const isPartOfNavigationTree = useIsPartOfNavigationTree();
  const parentTrace = useTrace();
  const events = useMemo(() => {
    return getEventsFromProps(logPress, logFocus, logKeyPress);
  }, [logFocus, logKeyPress, logPress]);
  const combinedProps = useMemo(() => {
    const filteredProps = {
      ...screen ? { screen } : {},
      ...page ? { page } : {},
      ...section ? { section } : {},
      ...modal ? { modal } : {},
      ...element ? { element } : {}
    };
    return {
      ...parentTrace,
      ...filteredProps
    };
  }, [parentTrace, screen, section, modal, element, page]);
  useEffect(() => {
    if (!devDoubleLogDisableMap[id] && logImpression && !isPartOfNavigationTree) {
      if (shouldLogScreen(directFromPage, properties?.screen)) {
        const eventProps = { ...combinedProps, ...properties };
        analytics.sendEvent(eventOnTrigger ?? SharedEventName.PAGE_VIEWED, eventProps);
        if (__DEV__) {
          devDoubleLogDisableMap[id] = true;
          setTimeout(() => {
            devDoubleLogDisableMap[id] = false;
          }, 50);
        }
      }
    }
  }, [logImpression]);
  const modifiedChildren = events.length > 0 ? <TraceContext.Consumer>{(consumedProps) => React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child;
    }
    return React.cloneElement(
      child,
      getEventHandlers(
        child,
        consumedProps,
        events,
        eventOnTrigger ?? SharedEventName.ELEMENT_CLICKED,
        element,
        properties
      )
    );
  })}</TraceContext.Consumer> : children;
  if (!isPartOfNavigationTree) {
    return <TraceContext.Provider value={combinedProps}>{modifiedChildren}</TraceContext.Provider>;
  }
  return <NavAwareTrace
    combinedProps={combinedProps}
    directFromPage={directFromPage}
    logImpression={logImpression}
    properties={properties}
  ><TraceContext.Provider value={combinedProps}>{modifiedChildren}</TraceContext.Provider></NavAwareTrace>;
}
function NavAwareTrace({
  logImpression,
  eventOnTrigger,
  directFromPage,
  combinedProps,
  children,
  properties
}) {
  const { shouldLogScreen } = useAnalyticsNavigationContext();
  useFocusEffect(
    React.useCallback(() => {
      if (logImpression) {
        const eventProps = { ...combinedProps, ...properties };
        if (shouldLogScreen(directFromPage, properties?.screen)) {
          analytics.sendEvent(eventOnTrigger ?? SharedEventName.PAGE_VIEWED, eventProps);
        }
      }
    }, [combinedProps, directFromPage, eventOnTrigger, logImpression, properties, shouldLogScreen])
  );
  return <>{children}</>;
}
export const Trace = memo(_Trace);
