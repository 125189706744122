"use strict";
import { useMemo } from "react";
import {
  useSearchTokensWebQuery
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { isBackendSupportedChain } from "uniswap/src/features/chains/utils";
function isExploreSupportedToken(token) {
  return token !== void 0 && isBackendSupportedChain(token.chain);
}
export function useSearchTokens(searchQuery = "") {
  const { data, loading, error } = useSearchTokensWebQuery({ variables: { searchQuery }, skip: searchQuery === "" });
  return useMemo(() => {
    const sortedTokens = data?.searchTokens?.filter(isExploreSupportedToken) ?? [];
    return { data: sortedTokens, loading, error };
  }, [data?.searchTokens, loading, error]);
}
