import { colorsDark, colorsLight } from "ui/src/theme/color/colors";
import { opacify } from "ui/src/theme/color/utils";
const hoverColor = (color) => opacify(85, color);
const pressedColor = (color) => opacify(65, color);
const { none: darkTransparent, ...tamaguiColorsDark } = colorsDark;
const { none: lightTransparent, ...tamaguiColorsLight } = colorsLight;
const light = {
  // Uniswap Design System
  ...tamaguiColorsLight,
  transparent: lightTransparent,
  // Tamagui Theme
  // Tamagui components expect the following
  background: colorsLight.surface1,
  backgroundHover: colorsLight.surface2,
  backgroundPress: colorsLight.surface2,
  backgroundFocus: colorsLight.surface2,
  borderColor: colorsLight.none,
  borderColorHover: colorsLight.none,
  borderColorFocus: colorsLight.none,
  outlineColor: colorsLight.none,
  color: colorsLight.neutral1,
  colorHover: colorsLight.accent1,
  colorPress: colorsLight.accent1,
  colorFocus: colorsLight.accent1,
  shadowColor: "rgba(0,0,0,0.15)",
  shadowColorHover: "rgba(0,0,0,0.2)"
};
const dark = {
  ...tamaguiColorsDark,
  transparent: darkTransparent,
  background: colorsDark.surface1,
  backgroundHover: colorsDark.surface2,
  backgroundPress: colorsDark.surface2,
  backgroundFocus: colorsDark.surface2,
  borderColor: colorsDark.none,
  borderColorHover: colorsDark.none,
  borderColorFocus: colorsDark.none,
  outlineColor: colorsDark.none,
  color: colorsDark.neutral1,
  colorHover: colorsDark.accent1,
  colorPress: colorsDark.accent1,
  colorFocus: colorsDark.accent1,
  shadowColor: "rgba(0,0,0,0.4)",
  shadowColorHover: "rgba(0,0,0,0.5)"
};
const light_branded = {
  ...light,
  color: colorsLight.accent1
};
const dark_branded = {
  ...dark,
  color: colorsDark.accent1
};
const light_primary = {
  ...light
};
const dark_primary = {
  ...dark
};
const light_secondary = {
  ...light
};
const dark_secondary = {
  ...dark,
  color: colorsDark.neutral2
};
const light_accentSecondary = {
  ...light
};
const dark_accentSecondary = {
  ...dark
};
const light_tertiary = {
  ...light
};
const dark_tertiary = {
  ...dark
};
const light_outline = {
  ...light
};
const dark_outline = {
  ...dark
};
const light_detrimental = {
  ...light
};
const dark_detrimental = {
  ...dark
};
const light_warning = {
  ...light
};
const dark_warning = {
  ...dark
};
const light_primary_Button = {
  ...light,
  background: colorsLight.accent1,
  backgroundHover: hoverColor(colorsLight.accent1),
  backgroundPress: pressedColor(colorsLight.accent1),
  color: colorsLight.white
};
const dark_primary_Button = {
  ...dark,
  background: colorsDark.accent1,
  backgroundHover: hoverColor(colorsDark.accent1),
  backgroundPress: pressedColor(colorsDark.accent1),
  color: colorsDark.white
};
const light_secondary_Button = {
  ...light,
  background: colorsLight.surface3,
  backgroundHover: hoverColor(colorsLight.surface2),
  backgroundPress: pressedColor(colorsLight.surface2),
  color: colorsLight.black
};
const dark_secondary_Button = {
  ...dark,
  background: colorsDark.surface3,
  backgroundHover: hoverColor(colorsDark.surface2),
  backgroundPress: pressedColor(colorsDark.surface2),
  color: colorsDark.white
};
const light_accentSecondary_Button = {
  ...light,
  background: colorsLight.accent2,
  backgroundHover: colorsLight.accent2Hovered,
  backgroundPress: pressedColor(colorsLight.accent2),
  color: colorsLight.accent1
};
const dark_accentSecondary_Button = {
  ...dark,
  background: colorsDark.accent2,
  backgroundHover: colorsDark.accent2Hovered,
  backgroundPress: pressedColor(colorsDark.accent2),
  color: colorsDark.accent1
};
const light_tertiary_Button = {
  ...light,
  background: colorsLight.surface3,
  // TODO(MOB-2206): make hover and press colors different from each other and more consistent with other buttons
  backgroundHover: colorsLight.surface2,
  backgroundPress: colorsLight.surface2,
  borderColor: colorsLight.none,
  color: colorsLight.neutral1
};
const dark_tertiary_Button = {
  ...dark,
  background: colorsDark.surface3,
  // TODO(MOB-2206): make hover and press colors different from each other and more consistent with other buttons
  backgroundHover: colorsDark.surface2,
  backgroundPress: colorsDark.surface2,
  borderColor: colorsDark.none,
  color: colorsDark.neutral1
};
const light_outline_Button = {
  ...light,
  background: colorsLight.none,
  backgroundHover: colorsLight.none,
  backgroundPress: colorsLight.none,
  borderColor: colorsLight.surface3,
  color: colorsLight.neutral1
};
const dark_outline_Button = {
  ...dark,
  background: colorsDark.none,
  backgroundHover: colorsDark.none,
  backgroundPress: colorsDark.none,
  borderColor: colorsDark.surface3,
  color: colorsDark.neutral1
};
const light_detrimental_Button = {
  ...light,
  background: colorsLight.DEP_accentCriticalSoft,
  backgroundHover: colorsLight.DEP_accentCriticalSoft,
  backgroundPress: colorsLight.DEP_accentCriticalSoft,
  color: colorsLight.statusCritical
};
const dark_detrimental_Button = {
  ...dark,
  background: colorsDark.DEP_accentCriticalSoft,
  backgroundHover: colorsLight.DEP_accentCriticalSoft,
  backgroundPress: colorsLight.DEP_accentCriticalSoft,
  color: colorsDark.statusCritical
};
const light_warning_Button = {
  ...light,
  background: colorsLight.DEP_accentWarningSoft,
  backgroundHover: colorsLight.DEP_accentWarningSoft,
  backgroundPress: colorsLight.DEP_accentWarningSoft,
  color: colorsLight.DEP_accentWarning
};
const dark_warning_Button = {
  ...dark,
  background: colorsDark.DEP_accentWarningSoft,
  backgroundHover: colorsDark.DEP_accentWarningSoft,
  backgroundPress: colorsDark.DEP_accentWarningSoft,
  color: colorsDark.DEP_accentWarning
};
const allThemes = {
  dark,
  light,
  light_branded,
  dark_branded,
  light_primary,
  dark_primary,
  light_secondary,
  dark_secondary,
  light_accentSecondary,
  dark_accentSecondary,
  light_tertiary,
  dark_tertiary,
  light_detrimental,
  dark_detrimental,
  light_warning,
  dark_warning,
  light_primary_Button,
  dark_primary_Button,
  light_secondary_Button,
  dark_secondary_Button,
  light_accentSecondary_Button,
  dark_accentSecondary_Button,
  light_tertiary_Button,
  dark_tertiary_Button,
  light_outline,
  dark_outline,
  light_outline_Button,
  dark_outline_Button,
  light_detrimental_Button,
  dark_detrimental_Button,
  light_warning_Button,
  dark_warning_Button
};
export const themes = allThemes;
