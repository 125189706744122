"use strict";
import { createSlice } from "@reduxjs/toolkit";
export const initialState = {};
const signatureSlice = createSlice({
  name: "signatures",
  initialState,
  reducers: {
    addSignature(signatures, { payload }) {
      if (signatures[payload.offerer]?.[payload.id]) {
        return;
      }
      const accountSignatures = signatures[payload.offerer] ?? {};
      accountSignatures[payload.id] = payload;
      signatures[payload.offerer] = accountSignatures;
    },
    updateSignature(signatures, { payload }) {
      if (!signatures[payload.offerer]?.[payload.id]) {
        throw Error("Attempted to update non-existent signature.");
      }
      signatures[payload.offerer][payload.id] = payload;
    },
    removeSignature(signatures, { payload }) {
      if (signatures[payload.offerer][payload.id]) {
        delete signatures[payload.offerer][payload.id];
      }
    }
  }
});
export const { addSignature, updateSignature, removeSignature } = signatureSlice.actions;
export default signatureSlice.reducer;
