"use strict";
export const migration6 = (state) => {
  if (!state) {
    return state;
  }
  if (state?.user && "selectedWallet" in state.user) {
    const connectionType = state.user.selectedWallet;
    if (connectionType !== void 0) {
      state.user.recentConnectionMeta = { type: connectionType };
    }
    delete state.user["selectedWallet"];
  }
  return {
    ...state,
    _persist: {
      ...state._persist,
      version: 6
    }
  };
};
