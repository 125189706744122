"use strict";
import { useEffect, useState } from "react";
import { BREAKPOINTS } from "theme";
const isClient = typeof window !== "undefined";
export const navSearchInputVisibleSize = 1100;
const BREAKPOINTS_ADDITIONAL = {
  ...BREAKPOINTS,
  navSearchInputVisible: navSearchInputVisibleSize,
  navDropdownMobileDrawer: 450
};
function getScreenSize() {
  return Object.keys(BREAKPOINTS_ADDITIONAL).reduce(
    (obj, key) => Object.assign(obj, {
      [key]: isClient ? window.innerWidth > BREAKPOINTS_ADDITIONAL[key] : false
    }),
    {}
  );
}
export function useScreenSize() {
  const [screenSize, setScreenSize] = useState(getScreenSize());
  useEffect(() => {
    function handleResize() {
      setScreenSize(getScreenSize());
    }
    if (isClient) {
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
    return void 0;
  }, []);
  return screenSize;
}
