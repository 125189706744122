"use strict";
import { parseUnits } from "@ethersproject/units";
import { CurrencyAmount } from "@uniswap/sdk-core";
import JSBI from "jsbi";
import { logger } from "utilities/src/logger/logger";
function truncateValue(value, decimals) {
  const parts = value.split(/[.,]/);
  const symbol = value.includes(".") ? "." : ",";
  if (parts.length > 1 && parts[1].length > decimals) {
    return parts[0] + symbol + parts[1].slice(0, decimals);
  }
  return value;
}
export default function tryParseCurrencyAmount(value, currency) {
  if (!value || !currency || isNaN(parseFloat(value))) {
    return void 0;
  }
  try {
    const typedValueParsed = parseUnits(truncateValue(value, currency.decimals), currency.decimals).toString();
    if (typedValueParsed !== "0") {
      return CurrencyAmount.fromRawAmount(currency, JSBI.BigInt(typedValueParsed));
    }
  } catch (error) {
    logger.debug("tryParseCurrencyAmount", "tryParseCurrencyAmount", `Failed to parse input amount: "${value}"`, error);
  }
  return void 0;
}
