"use strict";
import { NATIVE_CHAIN_ID } from "constants/tokens";
import { apolloClient } from "graphql/data/apollo/client";
import { gqlTokenToCurrencyInfo } from "graphql/data/types";
import { COMMON_BASES } from "uniswap/src/constants/routing";
import { nativeOnChain } from "uniswap/src/constants/tokens";
import {
  TokenDocument
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { toGraphQLChain } from "uniswap/src/features/chains/utils";
import { isSameAddress } from "utilities/src/addresses";
export async function getCurrency(currencyId, chainId) {
  const isNative = currencyId === NATIVE_CHAIN_ID || currencyId?.toLowerCase() === "native" || currencyId?.toLowerCase() === "eth";
  if (isNative) {
    return nativeOnChain(chainId);
  }
  const commonBase = chainId ? COMMON_BASES[chainId]?.find((base) => base.currency.isToken && isSameAddress(base.currency.address, currencyId)) : void 0;
  if (commonBase) {
    return commonBase.currency;
  }
  const { data } = await apolloClient.query({
    query: TokenDocument,
    variables: {
      address: currencyId,
      chain: toGraphQLChain(chainId)
    }
  });
  return gqlTokenToCurrencyInfo(data?.token)?.currency;
}
