"use strict";
export const Swap = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" rx="12" fill="#4C82FB" />
  <path
    d="M10.6663 8.33325L13.6663 5.33325M13.6663 5.33325L16.6663 8.33325M13.6663 5.33325V11.9999M13.333 15.6666L10.333 18.6666M10.333 18.6666L7.33301 15.6666M10.333 18.6666L10.333 12.6666"
    stroke="#F5F6FC"
    strokeWidth="1.33333"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>;
