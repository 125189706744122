"use strict";
export var UniswapXOrderStatus = /* @__PURE__ */ ((UniswapXOrderStatus2) => {
  UniswapXOrderStatus2["FILLED"] = "filled";
  UniswapXOrderStatus2["OPEN"] = "open";
  UniswapXOrderStatus2["EXPIRED"] = "expired";
  UniswapXOrderStatus2["ERROR"] = "error";
  UniswapXOrderStatus2["CANCELLED"] = "cancelled";
  UniswapXOrderStatus2["PENDING_CANCELLATION"] = "pending-cancellation";
  UniswapXOrderStatus2["INSUFFICIENT_FUNDS"] = "insufficient-funds";
  return UniswapXOrderStatus2;
})(UniswapXOrderStatus || {});
