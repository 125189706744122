"use strict";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { themeVars } from "nft/css/sprinkles.css";
const resolveTheme = (theme) => typeof theme === "function" ? theme() : theme;
export function cssObjectFromTheme(theme, { extends: baseTheme } = {}) {
  const resolvedThemeVars = {
    ...assignInlineVars(themeVars, resolveTheme(theme))
  };
  if (!baseTheme) {
    return resolvedThemeVars;
  }
  const resolvedBaseThemeVars = assignInlineVars(themeVars, resolveTheme(baseTheme));
  const filteredVars = Object.fromEntries(
    Object.entries(resolvedThemeVars).filter(([varName, value]) => value !== resolvedBaseThemeVars[varName])
  );
  return filteredVars;
}
