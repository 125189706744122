"use strict";
import { usePoolActiveLiquidity } from "hooks/usePoolTickData";
import { useCallback, useMemo } from "react";
export function useDensityChartData({
  currencyA,
  currencyB,
  feeAmount
}) {
  const { isLoading, error, data } = usePoolActiveLiquidity(currencyA, currencyB, feeAmount);
  const formatData = useCallback(() => {
    if (!data?.length) {
      return void 0;
    }
    const newData = [];
    for (let i = 0; i < data.length; i++) {
      const t = data[i];
      const chartEntry = {
        activeLiquidity: parseFloat(t.liquidityActive.toString()),
        price0: parseFloat(t.price0)
      };
      if (chartEntry.activeLiquidity > 0) {
        newData.push(chartEntry);
      }
    }
    return newData;
  }, [data]);
  return useMemo(() => {
    return {
      isLoading,
      error,
      formattedData: !isLoading ? formatData() : void 0
    };
  }, [isLoading, error, formatData]);
}
