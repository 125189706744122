"use strict";
import { tickToPrice } from "@uniswap/v3-sdk";
import { tickToPrice as tickToPriceV4 } from "@uniswap/v4-sdk";
export function getTickToPrice(baseToken, quoteToken, tick) {
  if (!baseToken || !quoteToken || typeof tick !== "number") {
    return void 0;
  }
  return tickToPrice(baseToken, quoteToken, tick);
}
export function getV4TickToPrice(baseCurrency, quoteCurrency, tick) {
  if (!baseCurrency || !quoteCurrency || typeof tick !== "number") {
    return void 0;
  }
  return tickToPriceV4(baseCurrency, quoteCurrency, tick);
}
