"use strict";
export function addMediaQueryListener(mediaQuery, listener) {
  try {
    mediaQuery.addEventListener("change", listener);
  } catch (e) {
    mediaQuery.addListener(listener);
  }
}
export function removeMediaQueryListener(mediaQuery, listener) {
  try {
    mediaQuery.removeEventListener("change", listener);
  } catch (e) {
    mediaQuery.removeListener(listener);
  }
}
