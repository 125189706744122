"use strict";
import { getCurrencySafetyInfo } from "uniswap/src/features/dataApi/utils";
import { SearchResultType } from "uniswap/src/features/search/SearchResult";
import { tokenAddressOrNativeAddress } from "uniswap/src/features/search/utils";
export const searchTokenToTokenSearchResult = (searchToken) => {
  return {
    type: SearchResultType.Token,
    chainId: searchToken.chainId,
    symbol: searchToken.symbol ?? "",
    address: tokenAddressOrNativeAddress(searchToken.address, searchToken.chainId),
    name: searchToken.name ?? null,
    logoUrl: searchToken.project?.logoUrl ?? null,
    safetyLevel: searchToken.project?.safetyLevel ?? null,
    safetyInfo: getCurrencySafetyInfo(searchToken.project?.safetyLevel, searchToken.protectionInfo),
    feeData: searchToken.feeData ?? null
  };
};
