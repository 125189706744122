"use strict";
import { useEffect } from "react";
import { isMobileWeb } from "utilities/src/platform";
export default function useDisableScrolling(disable) {
  useEffect(() => {
    if (!isMobileWeb) {
      return;
    }
    document.body.style.overflow = disable ? "hidden" : "auto";
  }, [disable]);
}
