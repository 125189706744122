"use strict";
import { createSlice } from "@reduxjs/toolkit";
export const initialState = {};
const fiatOnRampTransactionsSlice = createSlice({
  name: "fiatOnRampTransactions",
  initialState,
  reducers: {
    addFiatOnRampTransaction(fiatOnRampTransactions, { payload }) {
      if (fiatOnRampTransactions[payload.account]?.[payload.externalSessionId]) {
        return;
      }
      const accountTransactions = fiatOnRampTransactions[payload.account] ?? {};
      accountTransactions[payload.externalSessionId] = payload;
      fiatOnRampTransactions[payload.account] = accountTransactions;
    },
    updateFiatOnRampTransaction(fiatOnRampTransactions, { payload }) {
      if (!fiatOnRampTransactions[payload.account]?.[payload.externalSessionId]) {
        throw Error("Attempted to update non-existent FOR transaction.");
      }
      fiatOnRampTransactions[payload.account][payload.externalSessionId] = payload;
    },
    removeFiatOnRampTransaction(fiatOnRampTransactions, { payload }) {
      if (fiatOnRampTransactions[payload.account][payload.externalSessionId]) {
        delete fiatOnRampTransactions[payload.account][payload.externalSessionId];
      }
    }
  }
});
export const { addFiatOnRampTransaction, updateFiatOnRampTransaction, removeFiatOnRampTransaction } = fiatOnRampTransactionsSlice.actions;
export default fiatOnRampTransactionsSlice.reducer;
