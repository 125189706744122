"use strict";
import { createAction } from "@reduxjs/toolkit";
export var Field = /* @__PURE__ */ ((Field2) => {
  Field2["CURRENCY_A"] = "CURRENCY_A";
  Field2["CURRENCY_B"] = "CURRENCY_B";
  return Field2;
})(Field || {});
export var Bound = /* @__PURE__ */ ((Bound2) => {
  Bound2["LOWER"] = "LOWER";
  Bound2["UPPER"] = "UPPER";
  return Bound2;
})(Bound || {});
export const typeInput = createAction(
  "mintV3/typeInputMint"
);
export const typeStartPriceInput = createAction("mintV3/typeStartPriceInput");
export const typeLeftRangeInput = createAction("mintV3/typeLeftRangeInput");
export const typeRightRangeInput = createAction("mintV3/typeRightRangeInput");
export const resetMintState = createAction("mintV3/resetMintState");
export const setFullRange = createAction("mintV3/setFullRange");
