"use strict";
import { tickToPrice } from "@uniswap/v3-sdk";
import JSBI from "jsbi";
const PRICE_FIXED_DIGITS = 8;
export default function computeSurroundingTicks(token0, token1, activeTickProcessed, sortedTickData, pivot, ascending) {
  let previousTickProcessed = {
    ...activeTickProcessed
  };
  let processedTicks = [];
  for (let i = pivot + (ascending ? 1 : -1); ascending ? i < sortedTickData.length : i >= 0; ascending ? i++ : i--) {
    const tick = Number(sortedTickData[i]?.tick);
    const sdkPrice = tickToPrice(token0, token1, tick);
    const currentTickProcessed = {
      liquidityActive: previousTickProcessed.liquidityActive,
      tick,
      liquidityNet: JSBI.BigInt(sortedTickData[i]?.liquidityNet ?? ""),
      price0: sdkPrice.toFixed(PRICE_FIXED_DIGITS),
      sdkPrice
    };
    if (ascending) {
      currentTickProcessed.liquidityActive = JSBI.add(
        previousTickProcessed.liquidityActive,
        JSBI.BigInt(sortedTickData[i]?.liquidityNet ?? 0)
      );
    } else if (!ascending && JSBI.notEqual(previousTickProcessed.liquidityNet, JSBI.BigInt(0))) {
      currentTickProcessed.liquidityActive = JSBI.subtract(
        previousTickProcessed.liquidityActive,
        previousTickProcessed.liquidityNet
      );
    }
    processedTicks.push(currentTickProcessed);
    previousTickProcessed = currentTickProcessed;
  }
  if (!ascending) {
    processedTicks = processedTicks.reverse();
  }
  return processedTicks;
}
