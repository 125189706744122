import { isInterface } from "utilities/src/platform";
export function getProcessedEvent({
  eventName,
  eventProperties,
  testnetModeConfig,
  isTestnetMode
}) {
  if (!isTestnetMode) {
    return { eventName, eventProperties };
  }
  if (isInterface) {
    return void 0;
  }
  if (testnetModeConfig?.passthroughAllowlistEvents.includes(eventName)) {
    return { eventName, eventProperties };
  }
  if (testnetModeConfig?.allowlistEvents.includes(eventName)) {
    return {
      eventName: testnetModeConfig.aggregateEventName,
      eventProperties: { ...eventProperties, eventName }
    };
  }
  return void 0;
}
