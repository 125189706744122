"use strict";
import { createSlice } from "@reduxjs/toolkit";
import { filterToKey } from "state/logs/utils";
const slice = createSlice({
  name: "logs",
  initialState: {},
  reducers: {
    addListener(state, { payload: { chainId, filter } }) {
      if (!state[chainId]) {
        state[chainId] = {};
      }
      const key = filterToKey(filter);
      if (!state[chainId][key]) {
        state[chainId][key] = {
          listeners: 1
        };
      } else {
        state[chainId][key].listeners++;
      }
    },
    fetchingLogs(state, {
      payload: { chainId, filters, blockNumber }
    }) {
      if (!state[chainId]) {
        return;
      }
      for (const filter of filters) {
        const key = filterToKey(filter);
        if (!state[chainId][key]) {
          continue;
        }
        state[chainId][key].fetchingBlockNumber = blockNumber;
      }
    },
    fetchedLogs(state, {
      payload: { chainId, filter, results }
    }) {
      if (!state[chainId]) {
        return;
      }
      const key = filterToKey(filter);
      const fetchState = state[chainId][key];
      if (!fetchState || fetchState.results && fetchState.results.blockNumber > results.blockNumber) {
        return;
      }
      fetchState.results = results;
    },
    fetchedLogsError(state, {
      payload: { chainId, filter, blockNumber }
    }) {
      if (!state[chainId]) {
        return;
      }
      const key = filterToKey(filter);
      const fetchState = state[chainId][key];
      if (!fetchState || fetchState.results && fetchState.results.blockNumber > blockNumber) {
        return;
      }
      fetchState.results = {
        blockNumber,
        error: true
      };
    },
    removeListener(state, { payload: { chainId, filter } }) {
      if (!state[chainId]) {
        return;
      }
      const key = filterToKey(filter);
      if (!state[chainId][key]) {
        return;
      }
      state[chainId][key].listeners--;
    }
  }
});
export default slice.reducer;
export const { fetchedLogs, fetchedLogsError, fetchingLogs } = slice.actions;
