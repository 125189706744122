"use strict";
import { useContract } from "hooks/useContract";
import useENSAddress from "hooks/useENSAddress";
import JSBI from "jsbi";
import { useSingleCallResult } from "lib/hooks/multicall";
import { useMemo } from "react";
const CHAIN_DATA_ABI = [
  {
    inputs: [],
    name: "latestAnswer",
    outputs: [{ internalType: "int256", name: "", type: "int256" }],
    stateMutability: "view",
    type: "function"
  }
];
export default function useGasPrice(skip = false) {
  const { address } = useENSAddress("fast-gas-gwei.data.eth");
  const contract = useContract(address ?? void 0, CHAIN_DATA_ABI, false);
  const resultStr = useSingleCallResult(skip ? void 0 : contract, "latestAnswer").result?.[0]?.toString();
  return useMemo(() => typeof resultStr === "string" ? JSBI.BigInt(resultStr) : void 0, [resultStr]);
}
