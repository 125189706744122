"use strict";
import { cssObjectFromTheme } from "nft/css/cssObjectFromTheme";
import { darkTheme } from "nft/themes/darkTheme";
import { lightTheme } from "nft/themes/lightTheme";
function cssStringFromTheme(theme, options = {}) {
  return Object.entries(cssObjectFromTheme(theme, options)).map(([key, value]) => `${key}:${value};`).join("");
}
export function rootCssString(isDarkMode) {
  return isDarkMode ? cssStringFromTheme(darkTheme) : cssStringFromTheme(lightTheme);
}
