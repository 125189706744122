"use strict";
var ValidationSchema = /* @__PURE__ */ ((ValidationSchema2) => {
  ValidationSchema2["LIST"] = "list";
  ValidationSchema2["TOKENS"] = "tokens";
  return ValidationSchema2;
})(ValidationSchema || {});
function getValidationErrors(validate2) {
  return validate2?.errors?.map((error) => [error.instancePath, error.message].filter(Boolean).join(" ")).join("; ") ?? "unknown error";
}
async function validate(schema, data) {
  let validatorImport;
  switch (schema) {
    case "list" /* LIST */:
      validatorImport = await import("utils/__generated__/validateTokenList");
      break;
    case "tokens" /* TOKENS */:
      validatorImport = await import("utils/__generated__/validateTokens");
      break;
    default:
      throw new Error("No validation function specified for token list schema");
  }
  const [, validatorModule] = await Promise.all([import("ajv"), validatorImport]);
  const validator = validatorModule.default;
  if (validator?.(data)) {
    return data;
  }
  throw new Error(getValidationErrors(validator));
}
export async function validateTokens(json) {
  try {
    await validate("tokens" /* TOKENS */, { tokens: json });
    return json;
  } catch (error) {
    throw new Error(`Tokens failed validation: ${error.message}`);
  }
}
export async function validateTokenList(json) {
  try {
    await validate("list" /* LIST */, json);
    return json;
  } catch (error) {
    throw new Error(`Token list failed validation: ${error.message}`);
  }
}
