"use strict";
import { NATIVE_CHAIN_ID } from "constants/tokens";
import { TokenStandard } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { fromGraphQLChain } from "uniswap/src/features/chains/utils";
export function buildCurrencyKey(chainId, address) {
  return `${chainId}-${address.toLowerCase()}`;
}
export function currencyKey(currency) {
  return buildCurrencyKey(currency.chainId, currency.isToken ? currency.address : NATIVE_CHAIN_ID);
}
export function currencyKeyFromGraphQL(contract) {
  const chainId = fromGraphQLChain(contract.chain);
  const address = contract.standard === TokenStandard.Native ? NATIVE_CHAIN_ID : contract.address;
  if (!address) {
    throw new Error("Non-native token missing address");
  }
  if (!chainId) {
    throw new Error("Unsupported chain from pools query");
  }
  return buildCurrencyKey(chainId, address);
}
