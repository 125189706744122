"use strict";
import { ChartHeader } from "components/Charts/ChartHeader";
import { Chart } from "components/Charts/ChartModel";
import {
  CustomVolumeChartModel
} from "components/Charts/VolumeChart/CustomVolumeChartModel";
import { getCumulativeVolume } from "components/Charts/VolumeChart/utils";
import { useHeaderDateFormatter } from "components/Charts/hooks";
import { toHistoryDuration } from "graphql/data/util";
import { useTheme } from "lib/styled-components";
import { useMemo } from "react";
import { ThemedText } from "theme/components";
import { BIPS_BASE } from "uniswap/src/constants/misc";
import { HistoryDuration } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { t } from "uniswap/src/i18n";
import { NumberType, useFormatter } from "utils/formatNumbers";
class VolumeChartModel extends CustomVolumeChartModel {
  constructor(chartDiv, params) {
    super(chartDiv, params);
  }
  updateOptions(params) {
    const volumeChartOptions = {
      autoSize: true,
      rightPriceScale: {
        borderVisible: false,
        scaleMargins: {
          top: 0.3,
          bottom: 0
        }
      },
      handleScale: {
        axisPressedMouseMove: false
      },
      handleScroll: {
        vertTouchDrag: false
      }
    };
    super.updateOptions(params, volumeChartOptions);
  }
}
export function formatHistoryDuration(duration) {
  switch (duration) {
    case HistoryDuration.FiveMinute:
      return t("common.pastFiveMinutes");
    case HistoryDuration.Hour:
      return t("common.pastHour");
    case HistoryDuration.Day:
      return t("common.pastDay");
    case HistoryDuration.Week:
      return t("common.pastWeek");
    case HistoryDuration.Month:
      return t("common.pastMonth");
    case HistoryDuration.Year:
      return t("common.pastYear");
    case HistoryDuration.Max:
      return t("common.allTime");
  }
}
function VolumeChartHeader({
  crosshairData,
  volumes,
  timePeriod
}) {
  const { formatFiatPrice } = useFormatter();
  const headerDateFormatter = useHeaderDateFormatter();
  const display = useMemo(() => {
    const displayValues = {
      volume: "-",
      time: "-"
    };
    const priceFormatter = (price) => formatFiatPrice({
      price,
      type: NumberType.ChartFiatValue
    });
    if (crosshairData === void 0) {
      const cumulativeVolume = getCumulativeVolume(volumes);
      displayValues.volume = priceFormatter(cumulativeVolume);
      displayValues.time = formatHistoryDuration(toHistoryDuration(timePeriod));
    } else {
      displayValues.volume = priceFormatter(crosshairData.value);
      displayValues.time = headerDateFormatter(crosshairData.time);
    }
    return displayValues;
  }, [crosshairData, formatFiatPrice, headerDateFormatter, timePeriod, volumes]);
  return <ChartHeader
    value={<ThemedText.HeadlineLarge color="inherit">{display.volume}</ThemedText.HeadlineLarge>}
    time={crosshairData?.time}
    timePlaceholder={formatHistoryDuration(toHistoryDuration(timePeriod))}
  />;
}
function FeesTooltipDisplay({ data, feeTier }) {
  const { formatFiatPrice } = useFormatter();
  const fees = data.value * ((feeTier ?? 0) / BIPS_BASE / 100);
  return <><ThemedText.BodySmall>{t(`token.chart.tooltip`, {
    amount: formatFiatPrice({
      price: fees,
      type: NumberType.ChartFiatValue
    })
  })}</ThemedText.BodySmall></>;
}
export function VolumeChart({ height, data, feeTier, timePeriod, stale }) {
  const theme = useTheme();
  const params = useMemo(
    () => ({ data, colors: [theme.accent1], headerHeight: 75, stale }),
    [data, stale, theme.accent1]
  );
  return <Chart
    Model={VolumeChartModel}
    params={params}
    height={height}
    TooltipBody={feeTier === void 0 ? void 0 : ({ data: data2 }) => <FeesTooltipDisplay data={data2} feeTier={feeTier} />}
  >{(crosshairData) => <VolumeChartHeader crosshairData={crosshairData} volumes={data} timePeriod={timePeriod} />}</Chart>;
}
