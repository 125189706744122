"use strict";
export function isIFramed(redirect = false) {
  try {
    if (location.ancestorOrigins !== void 0) {
      if (location.ancestorOrigins.length > 0) {
        if (redirect && top) {
          top.location = self.location;
        }
        return true;
      }
    }
    if (self !== top) {
      if (redirect && top) {
        top.location = self.location;
      }
      return true;
    }
  } catch {
    if (redirect) {
      throw new Error("isIFramed: should redirect, but unable to determine if framed");
    }
    return true;
  }
  return false;
}
