"use strict";
import { LoaderButton } from "components/Button/LoaderButton";
import { ButtonError } from "components/Button/buttons";
import { DepositInputForm } from "components/Liquidity/DepositInputForm";
import {
  useCreatePositionContext,
  useCreateTxContext,
  useDepositContext,
  usePriceRangeContext
} from "pages/Pool/Positions/create/CreatePositionContext";
import { CreatePositionModal } from "pages/Pool/Positions/create/CreatePositionModal";
import { Container } from "pages/Pool/Positions/create/shared";
import { useCallback, useState } from "react";
import { Flex, Text } from "ui/src";
import { Trans } from "uniswap/src/i18n";
export const DepositStep = ({ ...rest }) => {
  const {
    derivedPositionInfo: { currencies, isPoolOutOfSync }
  } = useCreatePositionContext();
  const { derivedPriceRangeInfo } = usePriceRangeContext();
  const {
    setDepositState,
    derivedDepositInfo: { formattedAmounts, currencyAmounts, currencyAmountsUSDValue, currencyBalances, error }
  } = useDepositContext();
  const txContext = useCreateTxContext();
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const handleUserInput = (field, newValue) => {
    setDepositState((prev) => ({
      ...prev,
      exactField: field,
      exactAmount: newValue
    }));
  };
  const handleOnSetMax = (field, amount) => {
    setDepositState((prev) => ({
      ...prev,
      exactField: field,
      exactAmount: amount
    }));
  };
  const handleReview = useCallback(() => {
    setIsReviewModalOpen(true);
  }, []);
  const [token0, token1] = currencies;
  if (!token0 || !token1) {
    return null;
  }
  const { deposit0Disabled, deposit1Disabled } = derivedPriceRangeInfo;
  const disabled = !!error || !txContext?.txRequest;
  return <>
    <Container {...rest}>
      <Flex gap={32}><Flex row alignItems="center"><Flex>
        <Text variant="subheading1"><Trans i18nKey="common.depositTokens" /></Text>
        <Text variant="body3" color="$neutral2"><Trans i18nKey="position.deposit.description" /></Text>
      </Flex></Flex></Flex>
      <DepositInputForm
        token0={token0}
        token1={token1}
        formattedAmounts={formattedAmounts}
        currencyAmounts={currencyAmounts}
        currencyAmountsUSDValue={currencyAmountsUSDValue}
        currencyBalances={currencyBalances}
        onUserInput={handleUserInput}
        onSetMax={handleOnSetMax}
        deposit0Disabled={deposit0Disabled}
        deposit1Disabled={deposit1Disabled}
      />
      {!isPoolOutOfSync || disabled ? <LoaderButton
        flex={1}
        py="$spacing16"
        px="$spacing20"
        onPress={handleReview}
        disabled={disabled}
        buttonKey="Position-Create-DepositButton"
        loading={Boolean(!txContext?.txRequest && currencyAmounts?.TOKEN0 && currencyAmounts.TOKEN1)}
      ><Text variant="buttonLabel1" color="$neutralContrast">{error ? error : <Trans i18nKey="swap.button.review" />}</Text></LoaderButton> : <ButtonError error $borderRadius="20px" onClick={handleReview}><Trans i18nKey="swap.button.review" /></ButtonError>}
    </Container>
    <CreatePositionModal isOpen={isReviewModalOpen} onClose={() => setIsReviewModalOpen(false)} />
  </>;
};
