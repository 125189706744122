"use strict";
import { permit2Address } from "@uniswap/permit2-sdk";
import { useAccount } from "hooks/useAccount";
import { usePermitAllowance, useUpdatePermitAllowance } from "hooks/usePermitAllowance";
import { useRevokeTokenAllowance, useTokenAllowance, useUpdateTokenAllowance } from "hooks/useTokenAllowance";
import useInterval from "lib/hooks/useInterval";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TradeFillType } from "state/routing/types";
import { useHasPendingApproval, useHasPendingRevocation, useTransactionAdder } from "state/transactions/hooks";
import { AVERAGE_L1_BLOCK_TIME_MS } from "uniswap/src/features/transactions/swap/hooks/usePollingIntervalByChain";
var ApprovalState = /* @__PURE__ */ ((ApprovalState2) => {
  ApprovalState2[ApprovalState2["PENDING"] = 0] = "PENDING";
  ApprovalState2[ApprovalState2["SYNCING"] = 1] = "SYNCING";
  ApprovalState2[ApprovalState2["SYNCED"] = 2] = "SYNCED";
  return ApprovalState2;
})(ApprovalState || {});
export var AllowanceState = /* @__PURE__ */ ((AllowanceState2) => {
  AllowanceState2[AllowanceState2["LOADING"] = 0] = "LOADING";
  AllowanceState2[AllowanceState2["REQUIRED"] = 1] = "REQUIRED";
  AllowanceState2[AllowanceState2["ALLOWED"] = 2] = "ALLOWED";
  return AllowanceState2;
})(AllowanceState || {});
export default function usePermit2Allowance(amount, spender, tradeFillType) {
  const account = useAccount();
  const token = amount?.currency;
  const permit2AddressForChain = permit2Address(token?.chainId);
  const { tokenAllowance, isSyncing: isApprovalSyncing } = useTokenAllowance(
    token,
    account.address,
    permit2AddressForChain
  );
  const updateTokenAllowance = useUpdateTokenAllowance(amount, permit2AddressForChain);
  const revokeTokenAllowance = useRevokeTokenAllowance(token, permit2AddressForChain);
  const isApproved = useMemo(() => {
    if (!amount || !tokenAllowance) {
      return false;
    }
    return tokenAllowance.greaterThan(amount) || tokenAllowance.equalTo(amount);
  }, [amount, tokenAllowance]);
  const [approvalState, setApprovalState] = useState(2 /* SYNCED */);
  const isApprovalLoading = approvalState !== 2 /* SYNCED */;
  const isApprovalPending = useHasPendingApproval(token, permit2AddressForChain);
  const isRevocationPending = useHasPendingRevocation(token, permit2AddressForChain);
  useEffect(() => {
    if (isApprovalPending) {
      setApprovalState(0 /* PENDING */);
    } else {
      setApprovalState((state) => {
        if (state === 0 /* PENDING */ && isApprovalSyncing) {
          return 1 /* SYNCING */;
        } else if (state === 1 /* SYNCING */ && !isApprovalSyncing) {
          return 2 /* SYNCED */;
        }
        return state;
      });
    }
  }, [isApprovalPending, isApprovalSyncing]);
  const [now, setNow] = useState(Date.now() + AVERAGE_L1_BLOCK_TIME_MS);
  useInterval(
    useCallback(() => setNow((Date.now() + AVERAGE_L1_BLOCK_TIME_MS) / 1e3), []),
    AVERAGE_L1_BLOCK_TIME_MS
  );
  const [signature, setSignature] = useState();
  const isSigned = useMemo(() => {
    if (!amount || !signature) {
      return false;
    }
    return signature.details.token === token?.address && signature.spender === spender && signature.sigDeadline >= now;
  }, [amount, now, signature, spender, token?.address]);
  const { permitAllowance, expiration: permitExpiration, nonce } = usePermitAllowance(token, account.address, spender);
  const updatePermitAllowance = useUpdatePermitAllowance(token, spender, nonce, setSignature);
  const isPermitted = useMemo(() => {
    if (!amount || !permitAllowance || !permitExpiration) {
      return false;
    }
    return (permitAllowance.greaterThan(amount) || permitAllowance.equalTo(amount)) && permitExpiration >= now;
  }, [amount, now, permitAllowance, permitExpiration]);
  const shouldRequestApproval = !(isApproved || isApprovalLoading);
  const shouldRequestSignature = tradeFillType === TradeFillType.Classic && !(isPermitted || isSigned);
  const addTransaction = useTransactionAdder();
  const approveAndPermit = useCallback(async () => {
    if (shouldRequestApproval) {
      const { response, info } = await updateTokenAllowance();
      addTransaction(response, info);
    }
    if (shouldRequestSignature) {
      await updatePermitAllowance();
    }
  }, [addTransaction, shouldRequestApproval, shouldRequestSignature, updatePermitAllowance, updateTokenAllowance]);
  const approve = useCallback(async () => {
    const { response, info } = await updateTokenAllowance();
    addTransaction(response, info);
  }, [addTransaction, updateTokenAllowance]);
  const revoke = useCallback(async () => {
    const { response, info } = await revokeTokenAllowance();
    addTransaction(response, info);
  }, [addTransaction, revokeTokenAllowance]);
  return useMemo(() => {
    if (token) {
      if (!tokenAllowance || !permitAllowance) {
        return { state: 0 /* LOADING */ };
      } else if (shouldRequestSignature) {
        return {
          token,
          state: 1 /* REQUIRED */,
          isApprovalLoading: false,
          isApprovalPending,
          isRevocationPending,
          approveAndPermit,
          approve,
          permit: updatePermitAllowance,
          revoke,
          needsSetupApproval: !isApproved,
          needsPermitSignature: shouldRequestSignature,
          allowedAmount: tokenAllowance
        };
      } else if (!isApproved) {
        return {
          token,
          state: 1 /* REQUIRED */,
          isApprovalLoading,
          isApprovalPending,
          isRevocationPending,
          approveAndPermit,
          approve,
          permit: updatePermitAllowance,
          revoke,
          needsSetupApproval: true,
          needsPermitSignature: shouldRequestSignature,
          allowedAmount: tokenAllowance
        };
      }
    }
    return {
      token,
      state: 2 /* ALLOWED */,
      permitSignature: !isPermitted && isSigned ? signature : void 0,
      needsSetupApproval: false,
      needsPermitSignature: false
    };
  }, [
    approve,
    approveAndPermit,
    isApprovalLoading,
    isApprovalPending,
    isApproved,
    isPermitted,
    isSigned,
    updatePermitAllowance,
    permitAllowance,
    revoke,
    isRevocationPending,
    shouldRequestSignature,
    signature,
    token,
    tokenAllowance
  ]);
}
