"use strict";
export function getPriceBounds(prices) {
  if (!prices.length) {
    return { min: 0, max: 0 };
  }
  let min = prices[0].value;
  let max = prices[0].value;
  for (const pricePoint of prices) {
    if (pricePoint.value < min) {
      min = pricePoint.value;
    }
    if (pricePoint.value > max) {
      max = pricePoint.value;
    }
  }
  return { min, max };
}
export function getCandlestickPriceBounds(data) {
  if (!data.length) {
    return { min: 0, max: 0 };
  }
  let min = data[0].low;
  let max = data[0].high;
  for (const dataPoint of data) {
    if (dataPoint.low < min) {
      min = dataPoint.low;
    }
    if (dataPoint.high > max) {
      max = dataPoint.high;
    }
  }
  return { min, max };
}
