"use strict";
export const migration16 = (state) => {
  if (!state) {
    return void 0;
  }
  const newState = { ...state };
  newState.tokens = {
    dismissedTokenWarnings: state.user?.tokens ?? {}
  };
  delete newState.user.tokens;
  return { ...newState, _persist: { ...state._persist, version: 16 } };
};
