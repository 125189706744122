"use strict";
import { Percent } from "@uniswap/sdk-core";
import { BIPS_BASE } from "uniswap/src/constants/misc";
export function computeFiatValuePriceImpact(fiatValueInput, fiatValueOutput) {
  if (!fiatValueOutput || !fiatValueInput) {
    return void 0;
  }
  if (fiatValueInput === 0) {
    return void 0;
  }
  const ratio = 1 - fiatValueOutput / fiatValueInput;
  const numerator = Math.floor(ratio * BIPS_BASE);
  return new Percent(numerator, BIPS_BASE);
}
