"use strict";
import ms from "ms";
import { useCallback, useEffect, useMemo } from "react";
import { useMultichainTransactions } from "state/transactions/hooks";
import {
  TransactionType
} from "state/transactions/types";
import { isPendingTx } from "state/transactions/utils";
import { fetchSwaps } from "uniswap/src/data/apiClients/tradingApi/TradingApiClient";
import { TransactionStatus } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { SwapStatus } from "uniswap/src/data/tradingApi/__generated__";
import { toTradingApiSupportedChainId } from "uniswap/src/features/transactions/swap/utils/tradingApi";
import { logger } from "utilities/src/logger/logger";
const MIN_BRIDGE_WAIT_TIME = ms("2s");
function isBridgeTransactionDetails(tx) {
  return tx.info.type === TransactionType.BRIDGE;
}
function usePendingDepositedBridgeTransactions() {
  const multichainTransactions = useMultichainTransactions();
  return useMemo(
    () => multichainTransactions.flatMap(([tx, chainId]) => {
      if (isPendingTx(tx) && isBridgeTransactionDetails(tx) && tx.info.depositConfirmed) {
        return { ...tx, chainId };
      } else {
        return [];
      }
    }),
    [multichainTransactions]
  );
}
const SWAP_STATUS_TO_FINALIZED_STATUS = {
  [SwapStatus.SUCCESS]: TransactionStatus.Confirmed,
  [SwapStatus.FAILED]: TransactionStatus.Failed,
  [SwapStatus.EXPIRED]: TransactionStatus.Failed
};
export function usePollPendingBridgeTransactions(onActivityUpdate) {
  const pendingDepositedBridgeTransactions = usePendingDepositedBridgeTransactions();
  const fetchStatuses = useCallback(
    async (txs) => {
      const allTxMap = {};
      const txHashesByChain = txs.reduce((acc, tx) => {
        allTxMap[tx.hash] = tx;
        acc.set(tx.chainId, [...acc.get(tx.chainId) ?? [], tx.hash]);
        return acc;
      }, /* @__PURE__ */ new Map());
      for (const [chainId, txHashes] of txHashesByChain.entries()) {
        try {
          const tradingApiChainId = toTradingApiSupportedChainId(chainId);
          if (!tradingApiChainId) {
            continue;
          }
          const response = await fetchSwaps({ txHashes, chainId: tradingApiChainId });
          for (const swap of response.swaps ?? []) {
            const { txHash, status } = swap;
            const fullTxDetails = allTxMap[txHash ?? ""];
            const updatedStatus = status ? SWAP_STATUS_TO_FINALIZED_STATUS[status] : void 0;
            if (txHash && updatedStatus && fullTxDetails) {
              onActivityUpdate({
                type: "transaction",
                chainId,
                update: { ...fullTxDetails, status: updatedStatus },
                original: fullTxDetails
              });
            }
          }
        } catch {
          logger.debug("bridge", `usePollPendingBridgeTransactions`, "failed to fetch from swapStatus", {
            chainId,
            txHashes
          });
        }
      }
    },
    [onActivityUpdate]
  );
  useEffect(() => {
    let attempts = 0;
    let interval = 500;
    let timeoutId;
    const poll = async () => {
      if (!pendingDepositedBridgeTransactions.length) {
        return;
      }
      if (attempts >= 10) {
        logger.error(new Error("Max attempts reached polling for bridge txs, giving up"), {
          tags: {
            file: "bridge",
            function: "usePollPendingBridgeTransactions"
          }
        });
        return;
      }
      await fetchStatuses(pendingDepositedBridgeTransactions);
      attempts++;
      interval *= 2;
      timeoutId = setTimeout(poll, interval);
    };
    timeoutId = setTimeout(poll, MIN_BRIDGE_WAIT_TIME);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [fetchStatuses, pendingDepositedBridgeTransactions]);
}
