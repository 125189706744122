"use strict";
export const migration13 = (state) => {
  if (!state?.transactions) {
    return state;
  }
  const newState = { ...state };
  newState.localWebTransactions = state.transactions;
  delete newState.transactions;
  return { ...newState, _persist: { ...state._persist, version: 13 } };
};
