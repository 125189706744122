"use strict";
import { supportedChainIdFromGQLChain } from "graphql/data/util";
import { getChainInfo } from "uniswap/src/features/chains/chainInfo";
export function getNativeTokenDBAddress(chain) {
  const pageChainId = supportedChainIdFromGQLChain(chain);
  if (pageChainId === void 0) {
    return void 0;
  }
  return getChainInfo(pageChainId).backendChain.nativeTokenBackendAddress;
}
