"use strict";
import { _TypedDataEncoder } from "@ethersproject/hash";
import { logger } from "utilities/src/logger/logger";
import { WalletType, getWalletMeta } from "utils/walletMeta";
const WC_PEERS_LACKING_V4_SUPPORT = ["SafePal Wallet", "Ledger Wallet Connect"];
function supportsV4(provider) {
  const meta = getWalletMeta(provider);
  if (meta) {
    const { type, name } = meta;
    if (name) {
      if (type === WalletType.WALLET_CONNECT && name && WC_PEERS_LACKING_V4_SUPPORT.includes(name)) {
        return false;
      }
    }
  }
  return true;
}
export async function signTypedData(signer, domain, types, value) {
  const populated = await _TypedDataEncoder.resolveNames(domain, types, value, (name) => {
    return signer.provider.resolveName(name);
  });
  const method = supportsV4(signer.provider) ? "eth_signTypedData_v4" : "eth_signTypedData";
  const address = (await signer.getAddress()).toLowerCase();
  const message = JSON.stringify(_TypedDataEncoder.getPayload(populated.domain, types, populated.value));
  try {
    return await signer.provider.send(method, [address, message]);
  } catch (error) {
    if (typeof error.message === "string" && (error.message.match(/not (found|implemented)/i) || error.message.match(/TrustWalletConnect.WCError error 1/) || error.message.match(/Missing or invalid/))) {
      logger.warn(
        "signing",
        "signTypedData",
        "signTypedData: wallet does not implement EIP-712, falling back to eth_sign",
        error
      );
      const hash = _TypedDataEncoder.hash(populated.domain, types, populated.value);
      return await signer.provider.send("eth_sign", [address, hash]);
    }
    throw error;
  }
}
