"use strict";
import { memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getCurrentPageFromLocation } from "utils/urlRoutes";
export const ResetPageScrollEffect = memo(function ResetPageScrollEffect2() {
  const location = useLocation();
  const { pathname } = location;
  const currentPage = getCurrentPageFromLocation(pathname);
  const [hasChangedOnce, setHasChangedOnce] = useState(false);
  useEffect(() => {
    if (!hasChangedOnce) {
      setHasChangedOnce(true);
    } else {
      window.scrollTo(0, 0);
    }
  }, [currentPage]);
  return null;
});
