"use strict";
import Explore, { ExploreTab } from "pages/Explore";
import { Navigate, useLocation, useParams } from "react-router-dom";
export function useExploreParams() {
  const { tab, chainName, tokenAddress } = useParams();
  const isLegacyUrl = !useLocation().pathname.includes("explore");
  const exploreTabs = Object.values(ExploreTab);
  if (tab && !chainName && exploreTabs.includes(tab)) {
    return { tab, chainName: void 0, tokenAddress };
  } else if (tab && !chainName) {
    return { tab: void 0, chainName: tab, tokenAddress };
  } else if (isLegacyUrl && !tab) {
    return { tab: ExploreTab.Tokens, chainName, tokenAddress };
  } else if (!tab) {
    return { tab: void 0, chainName: void 0, tokenAddress: void 0 };
  } else {
    return { tab, chainName, tokenAddress };
  }
}
export default function RedirectExplore() {
  const { tab, chainName, tokenAddress } = useExploreParams();
  const isLegacyUrl = !useLocation().pathname.includes("explore");
  if (isLegacyUrl) {
    if (tab && chainName && tokenAddress) {
      return <Navigate to={`/explore/${tab}/${chainName}/${tokenAddress}`} replace />;
    } else if (chainName && tokenAddress) {
      return <Navigate to={`/explore/tokens/${chainName}/${tokenAddress}`} replace />;
    } else if (tab && chainName) {
      return <Navigate to={`/explore/${tab}/${chainName}`} replace />;
    }
  }
  return <Explore initialTab={tab} />;
}
