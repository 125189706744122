"use strict";
import { useEffect, useRef } from "react";
function nodeContainsClick(node, e) {
  if (node.current?.contains(e.target)) {
    return true;
  }
  const rect = node.current?.getBoundingClientRect();
  if (!rect) {
    return false;
  }
  const withinX = e.clientX >= rect.left && e.clientX <= rect.right;
  const withinY = e.clientY >= rect.top && e.clientY <= rect.bottom;
  return withinX && withinY;
}
export function useOnClickOutside(node, handler, ignoredNodes = []) {
  const handlerRef = useRef(handler);
  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!node.current || nodeContainsClick(node, e) || ignoredNodes.reduce((reducer, val) => reducer || !!val.current?.contains(e.target), false)) {
        return;
      }
      handlerRef.current?.();
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [node, ignoredNodes]);
}
