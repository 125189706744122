export enum InterfaceEventNameLocal {
  PortfolioMenuOpened = 'Portfolio Menu Opened',
  UniswapXOrderDetailsSheetOpened = 'UniswapX Order Details Sheet Opened',
  UniswapXOrderCancelInitiated = 'UniswapX Order Cancel Initiated',
  UniswapXSignatureRequested = 'UniswapX Signature Requested',
  UniswapXSignatureDeadlineExpired = 'UniswapX Signature Deadline Expired',
  UniswapXOrderPostError = 'UniswapX Order Post Error',
  UniswapXOrderSubmitted = 'UniswapX Order Submitted',
  LimitPresetRateSelected = 'Limit Preset Rate Selected',
  LimitPriceReversed = 'Limit Price Reversed',
  LimitExpirySelected = 'Limit Expiry Selected',
  SwapTabClicked = 'Swap Tab Clicked',
  LocalCurrencySelected = 'Local Currency Selected',
  NoQuoteReceivedFromQuickrouteAPI = 'No quote received from quickroute API',
  NoQuoteReceivedFromRoutingAPI = 'No quote received from routing API',
}
