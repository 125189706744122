"use strict";
import { permit2Address } from "@uniswap/permit2-sdk";
import { CosignedPriorityOrder, CosignedV2DutchOrder, DutchOrder, getCancelMultipleParams } from "@uniswap/uniswapx-sdk";
import { getYear, isSameDay, isSameMonth, isSameWeek, isSameYear } from "date-fns";
import { useAccount } from "hooks/useAccount";
import { useContract } from "hooks/useContract";
import { useEthersWeb3Provider } from "hooks/useEthersProvider";
import useSelectChain from "hooks/useSelectChain";
import { useCallback } from "react";
import store from "state";
import { updateSignature } from "state/signatures/reducer";
import { SignatureType } from "state/signatures/types";
import { UniswapXOrderStatus } from "types/uniswapx";
import PERMIT2_ABI from "uniswap/src/abis/permit2.json";
import { TransactionStatus } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { InterfaceEventNameLocal } from "uniswap/src/features/telemetry/constants";
import { sendAnalyticsEvent } from "uniswap/src/features/telemetry/send";
import { t } from "uniswap/src/i18n";
import { getContract } from "utilities/src/contracts/getContract";
import { logger } from "utilities/src/logger/logger";
import { useAsyncData } from "utilities/src/react/hooks";
import { WrongChainError } from "utils/errors";
import { didUserReject } from "utils/swapErrorToUserReadableMessage";
const sortActivities = (a, b) => b.timestamp - a.timestamp;
export const createGroups = (activities = [], hideSpam = false) => {
  if (activities.length === 0) {
    return [];
  }
  const now = Date.now();
  const pending = [];
  const today = [];
  const currentWeek = [];
  const last30Days = [];
  const currentYear = [];
  const yearMap = {};
  activities.forEach((activity) => {
    if (hideSpam && activity.isSpam) {
      return;
    }
    const addedTime = activity.timestamp * 1e3;
    if (activity.status === TransactionStatus.Pending) {
      switch (activity.offchainOrderDetails?.type) {
        case SignatureType.SIGN_LIMIT:
          break;
        default:
          pending.push(activity);
      }
    } else if (isSameDay(now, addedTime)) {
      today.push(activity);
    } else if (isSameWeek(addedTime, now)) {
      currentWeek.push(activity);
    } else if (isSameMonth(addedTime, now)) {
      last30Days.push(activity);
    } else if (isSameYear(addedTime, now)) {
      currentYear.push(activity);
    } else {
      const year = getYear(addedTime);
      if (!yearMap[year]) {
        yearMap[year] = [activity];
      } else {
        yearMap[year].push(activity);
      }
    }
  });
  const sortedYears = Object.keys(yearMap).sort((a, b) => parseInt(b) - parseInt(a)).map((year) => ({ title: year, transactions: yearMap[year] }));
  const transactionGroups = [
    { title: t("common.pending"), transactions: pending.sort(sortActivities) },
    { title: t("common.today"), transactions: today.sort(sortActivities) },
    { title: t("common.thisWeek"), transactions: currentWeek.sort(sortActivities) },
    { title: t("common.thisMonth"), transactions: last30Days.sort(sortActivities) },
    { title: t("common.thisYear"), transactions: currentYear.sort(sortActivities) },
    ...sortedYears
  ];
  return transactionGroups.filter(({ transactions }) => transactions.length > 0);
};
function getCancelMultipleUniswapXOrdersParams(orders, chainId) {
  const nonces = orders.map(
    ({ encodedOrder, type }) => type === SignatureType.SIGN_UNISWAPX_V2_ORDER ? CosignedV2DutchOrder.parse(encodedOrder, chainId) : type === SignatureType.SIGN_PRIORITY_ORDER ? CosignedPriorityOrder.parse(encodedOrder, chainId) : DutchOrder.parse(encodedOrder, chainId)
  ).map((order) => order.info.nonce);
  return getCancelMultipleParams(nonces);
}
export function useCancelMultipleOrdersCallback(orders) {
  const provider = useEthersWeb3Provider({ chainId: orders?.[0]?.chainId });
  const account = useAccount();
  const selectChain = useSelectChain();
  return useCallback(async () => {
    if (!orders || orders.length === 0) {
      return void 0;
    }
    sendAnalyticsEvent(InterfaceEventNameLocal.UniswapXOrderCancelInitiated, {
      orders: orders.map((order) => order.orderHash)
    });
    return cancelMultipleUniswapXOrders({
      orders: orders.map((order) => {
        return { encodedOrder: order.encodedOrder, type: order.type };
      }),
      signer: account.address,
      provider,
      chainId: orders?.[0].chainId,
      selectChain
    }).then((result) => {
      orders.forEach((order) => {
        if (order.status === UniswapXOrderStatus.FILLED) {
          return;
        }
        store.dispatch(updateSignature({ ...order, status: UniswapXOrderStatus.PENDING_CANCELLATION }));
      });
      return result;
    });
  }, [orders, account.address, provider, selectChain]);
}
async function cancelMultipleUniswapXOrders({
  orders,
  chainId,
  signer,
  provider,
  selectChain
}) {
  const cancelParams = getCancelMultipleUniswapXOrdersParams(orders, chainId);
  const permit2 = provider && getContract(permit2Address(chainId), PERMIT2_ABI, provider, signer);
  if (!permit2 || !provider) {
    return void 0;
  }
  try {
    const switchChainResult = await selectChain(chainId);
    if (!switchChainResult) {
      throw new WrongChainError();
    }
    const transactions = [];
    for (const params of cancelParams) {
      const tx = await permit2.invalidateUnorderedNonces(params.word, params.mask);
      transactions.push(tx);
    }
    return transactions;
  } catch (error) {
    if (!didUserReject(error)) {
      logger.debug("utils", "cancelMultipleUniswapXOrders", "Failed to cancel multiple orders", { error, orders });
    }
    return void 0;
  }
}
async function getCancelMultipleUniswapXOrdersTransaction(orders, chainId, permit2) {
  const cancelParams = getCancelMultipleUniswapXOrdersParams(orders, chainId);
  if (!permit2 || cancelParams.length === 0) {
    return void 0;
  }
  try {
    const tx = await permit2.populateTransaction.invalidateUnorderedNonces(cancelParams[0].word, cancelParams[0].mask);
    return {
      ...tx,
      chainId
    };
  } catch (error) {
    const wrappedError = new Error("could not populate cancel transaction", { cause: error });
    logger.debug("utils", "getCancelMultipleUniswapXOrdersTransaction", wrappedError.message, {
      error: wrappedError,
      orders
    });
    return void 0;
  }
}
export function useCreateCancelTransactionRequest(params) {
  const permit2 = useContract(permit2Address(params?.chainId), PERMIT2_ABI, true);
  const transactionFetcher = useCallback(() => {
    if (!params || !params.orders || params.orders.filter(({ encodedOrder }) => Boolean(encodedOrder)).length === 0 || !permit2) {
      return void 0;
    }
    return getCancelMultipleUniswapXOrdersTransaction(params.orders, params.chainId, permit2);
  }, [params, permit2]);
  return useAsyncData(transactionFetcher).data;
}
export function isLimitCancellable(order) {
  return [UniswapXOrderStatus.OPEN, UniswapXOrderStatus.INSUFFICIENT_FUNDS].includes(order.status);
}
