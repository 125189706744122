export const NO_HAPTIC_FEEDBACK = {
  impact: async () => Promise.resolve(),
  light: async () => Promise.resolve(),
  medium: async () => Promise.resolve(),
  heavy: async () => Promise.resolve(),
  success: async () => Promise.resolve(),
  warning: async () => Promise.resolve(),
  error: async () => Promise.resolve(),
  selection: async () => Promise.resolve()
};
