import { G, Path, Svg } from "react-native-svg";
import { createIcon } from "../factories/createIcon";
export const [BlockaidLogo, AnimatedBlockaidLogo] = createIcon({
  name: "BlockaidLogo",
  getIcon: (props) => <Svg fill="none" viewBox="0 0 51 10" {...props}><G fill="currentColor">
    <Path d="m.319422.828115v.801765h3.480178 3.48898l.21146.22026c.29075.28194.29075.68723 0 .96916l-.21146.22027h-2.7577c-2.48459 0-2.79296.01762-3.11014.1674-.740085.34361-1.12775.93392-1.13656 1.73568 0 .6608.30837 1.24229.84581 1.60353l.36124.23788 2.87224.04405c2.02644.03525 2.92511.0793 3.05727.14978.35243.20265.40529.66961.11454 1.03084l-.17621.22908h-3.52423-3.515418v.80176.79295l3.594718-.02643c3.42731-.02643 3.60352-.03524 3.98237-.21146.56388-.26431.89868-.57268 1.13657-1.04845.2555-.51102.27313-1.32159.05286-1.87666-.18502-.45815-.87225-1.09251-1.34802-1.23348-.22907-.07048-1.19823-.10572-2.96035-.10572-1.63876 0-2.68722-.03525-2.79295-.08811-.22026-.12335-.22026-.49339 0-.61674.10573-.05286 1.163-.0881 2.80176-.0881 1.44494 0 2.76652-.04406 2.93392-.08811.42291-.11454.87225-.45815 1.163-.88987.74009-1.10132.34361-2.599117-.84582-3.207046l-.52863-.264317-3.59471-.026432-3.594718-.0264321z" />
    <Path d="m10.011 4.93386v4.88987h.881.8811v-4.88987-4.8898661h-.8811-.881z" />
    <Path d="m27.4561 4.93386v4.88987h.881.8811v-1.17181-1.1718l.4317-.42291.4317-.42291.2996.46696c.1674.26432.6079.97798.9867 1.60353l.6873 1.11894h1.0925 1.0925l-1.4097-2.11454c-.7753-1.16299-1.4097-2.14978-1.4097-2.19383s.5551-.66079 1.2423-1.36564l1.2423-1.28634-1.0837.00881h-1.0925l-1.1806 1.27753c-.652.69604-1.2159 1.26872-1.2599 1.26872-.0353 0-.0705-1.20704-.0705-2.68722v-2.6872261h-.8811-.881z" />
    <Path d="m41.3767.880769v.837001h.8811.881v-.837001-.8370043h-.881-.8811z" />
    <Path d="m48.8658 1.91189v1.87666l-.282-.34362c-.837-1.02202-2.6519-1.0044-3.6828.02644-.696.69603-1.0572 1.674-1.0572 2.87224.0088 1.64758.5991 2.84582 1.6916 3.38326.4581.23789.6343.27313 1.207.27313.8106 0 1.2952-.19383 1.8414-.73128l.3701-.36123v.45815.45815h.881.8811v-4.88987-4.8898679h-.9251-.9251zm-.7313 2.45815c.4846.29956.7401.95155.7489 1.86785.0088 1.49779-.5463 2.26431-1.6388 2.26431-.7401 0-1.1806-.3348-1.4273-1.0837-.2026-.60793-.141-1.91189.1146-2.39647.2114-.40529.5022-.69604.8193-.8282.3172-.14097 1.0221-.04405 1.3833.17621z" />
    <Path d="m15.1213 2.81057c-1.2952.36124-2.1674 1.2511-2.511 2.56388-.3348 1.29515 0 2.61674.9075 3.5859.6696.72247 1.4713 1.03965 2.6255 1.03965.8106 0 1.3656-.15859 2.0264-.57269 2.2555-1.43612 2.0705-5.11894-.3171-6.34361-.7489-.38766-1.9119-.5022-2.7313-.27313zm1.9735 1.52423c.2027.14097.4406.43172.5727.68723.1939.37885.2291.55506.2291 1.27753 0 .88987-.0969 1.24229-.467 1.7533-.5462.74009-1.7621.81939-2.4669.15859-.7842-.73127-.8546-2.73127-.1322-3.59471.5551-.65198 1.5507-.77533 2.2643-.28194z" />
    <Path d="m22.5574 2.81068c-.6344.21145-1.0485.46696-1.4626.9163-.696.74009-.9691 1.48899-.9603 2.65198.0088 1.56828.6696 2.68723 1.9471 3.30397.5375.2555.6344.27312 1.5859.27312.9075 0 1.0661-.02643 1.5066-.22907.8018-.37885 1.3745-1.0837 1.5683-1.9207l.0793-.36124h-.8546-.8546l-.1939.39648c-.2467.5022-.5374.68722-1.163.73127-.9163.07049-1.4625-.37004-1.7268-1.40088-.326-1.26872.1145-2.57268.9956-2.95154.6696-.29075 1.4977-.00881 1.8149.59912l.1674.3348h.8635.8634l-.0881-.3348c-.2115-.78414-.8811-1.55066-1.6476-1.89427-.511-.22908-1.9031-.29075-2.4405-.11454z" />
    <Path d="m36.3193 2.81933c-.9428.26432-1.6917.96916-1.7886 1.70044l-.0529.37004h.793.7841l.1146-.30837c.3524-.98678 2.4757-.79295 2.4757.22908 0 .32599-.3259.57268-.9074.68722-.2555.04405-.793.14978-1.2071.22908-.9075.17621-1.4449.40528-1.8414.77533-.3965.37885-.5374.74009-.5374 1.38326 0 1.24229.7665 1.98238 2.1585 2.0881.8547.07049 1.5507-.14097 2-.59031.3701-.37004.3701-.36123.4582.10573l.0617.3348h.8898c.8106 0 .8723-.00881.8106-.15859-.044-.07929-.0969-1.27753-.1233-2.66079l-.0441-2.51101-.2643-.52864c-.3172-.62555-.7313-.95154-1.4978-1.14537-.6256-.15859-1.6916-.15859-2.2819 0zm2.4141 3.98238c0 1.03964-.3877 1.67401-1.1454 1.87665-1.1102.29956-1.9295-.27313-1.6388-1.15419.1234-.38766.4758-.58149 1.5331-.84581.4669-.11454.9074-.25551.9867-.29075.2379-.14097.2644-.09692.2644.4141z" />
    <Path d="m41.3767 6.34355v3.48018h.8811.881v-3.48018-3.48017h-.881-.8811z" />
    <Path d="m9.94047 4.95176-.01762 4.96035h.96915.9692l-.0176-4.96035-.0265-4.95154447-.0176 4.90748447-.0264 4.9163h-.8811-.881l-.02647-4.9163-.01763-4.90748447z" />
    <Path d="m29.2356 2.70529c-.0088 1.4978.0176 2.71366.0529 2.71366.0352 0 .2114-.14978.3788-.33481l.2996-.32599-.326.29956-.3348.30837-.0265-2.68722-.0176-2.67841654z" />
    <Path d="m41.3237.881212c0 .484578.0088.669608.0265.414098.0176-.25551.0176-.651984 0-.881059-.0177-.229075-.0265-.017621-.0265.466961z" />
    <Path d="m23.5176 2.6611c.0793.01762.2203.01762.3084 0 .0793-.02643.0088-.04405-.1586-.04405s-.2379.01762-.1498.04405z" />
    <Path d="m46.7774 2.6611c.0793.01762.2203.01762.3084 0 .0793-.02643.0088-.04405-.1586-.04405s-.2379.01762-.1498.04405z" />
    <Path d="m32.8215 2.83742 1.1013.03524-.6608.67842-.6519.67841.6872-.66079c.37-.36123.6784-.68723.6784-.72247 0-.04405-.5022-.06167-1.1189-.05286l-1.1278.01762z" />
    <Path d="m41.3325 2.89017c-.0265.05286-.0353 1.65639-.0265 3.54185l.0265 3.43612.9515.00881h.9427l-.9251-.02643-.9251-.03524v-3.47137-3.47136l.9075-.03524.8987-.02644-.9075-.01762c-.6608-.00881-.9163.00881-.9427.09692z" />
    <Path d="m3.3765 3.01364c.69604.01762 1.82379.01762 2.51102 0 .69603-.00881.13216-.02643-1.2511-.02643s-1.94714.01762-1.25992.02643z" />
    <Path d="m30.848 3.76226-.3524.37885.3789-.35242c.2026-.18502.37-.35242.37-.37004 0-.07049-.0793 0-.3965.34361z" />
    <Path d="m3.38545 5.30417c.74008.01762 1.92951.01762 2.64317 0 .71365-.00881.10572-.02643-1.34802-.02643s-2.03524.01762-1.29515.02643z" />
    <Path d="m3.24445 6.8901c.72247.01763 1.88546.01763 2.59912 0 .72246-.00881.13216-.02643-1.29516-.02643-1.42731 0-2.01762.01762-1.30396.02643z" />
    <Path d="m29.4999 7.20735-.2819.29075v1.16299 1.15419l-.9427.03524-.9516.02643h.9692l.9692-.00881.0264-1.18942.0264-1.18943.2731-.28194c.1586-.15859.2644-.29075.2379-.29075-.0264 0-.1674.13216-.326.29075z" />
    <Path d="m48.9009 9.42761.0088.44053.8634.00881h.8547l-.8282-.02643-.837-.03524-.0353-.4141-.0352-.41409z" />
    <Path d="m32.0286 9.85067c.0353.03525.5639.05287 1.163.04406l1.0925-.02643-1.163-.04406c-.6431-.02643-1.1365-.00881-1.0925.02643z" />
    <Path d="m39.2884 9.88571c.2819.01762.7136.01762.9691 0s.0265-.02643-.511-.02643c-.5286 0-.7401.00881-.4581.02643z" />
  </G></Svg>,
  defaultFill: "#bfbfbf"
});
