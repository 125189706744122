import {
  StandardCurrency,
  TYPE_TO_FORMATTER_RULES,
  TwoDecimalsCurrency
} from "utilities/src/format/localeBasedFormats";
import { NumberType } from "utilities/src/format/types";
import { logger } from "utilities/src/logger/logger";
function getFormatterRule(input, type) {
  const { rules, defaultFormat } = TYPE_TO_FORMATTER_RULES[type];
  for (const rule of rules) {
    if (rule.exact !== void 0 && input === rule.exact || rule.upperBound !== void 0 && input < rule.upperBound) {
      return rule;
    }
  }
  logger.error("Invalid input or misconfigured formatter rules for type", {
    tags: {
      file: "localeBased",
      function: "getFormatterRule"
    },
    extra: { type, input }
  });
  return { formatter: defaultFormat };
}
export function formatNumber({
  input,
  locale,
  currencyCode = "USD",
  type = NumberType.TokenNonTx,
  placeholder = "-"
}) {
  if (input === null || input === void 0) {
    return placeholder;
  }
  const { formatter, overrideValue, postFormatModifier } = getFormatterRule(input, type);
  if (typeof formatter === "string") {
    return formatter;
  }
  const createdFormat = formatter.createFormat(locale, currencyCode);
  const formatted = createdFormat.format(overrideValue !== void 0 ? overrideValue : input);
  return postFormatModifier ? postFormatModifier(formatted) : formatted;
}
export function formatCurrencyAmount({
  amount,
  locale,
  type = NumberType.TokenNonTx,
  placeholder
}) {
  return formatNumber({
    input: amount ? parseFloat(amount.toFixed()) : void 0,
    locale,
    type,
    placeholder
  });
}
export function formatNumberOrString({
  price,
  locale,
  currencyCode,
  type,
  placeholder = "-"
}) {
  if (price === null || price === void 0) {
    return placeholder;
  }
  if (typeof price === "string") {
    return formatNumber({ input: parseFloat(price), locale, currencyCode, type, placeholder });
  }
  return formatNumber({ input: price, locale, currencyCode, type, placeholder });
}
export function formatPercent(rawPercentage, locale) {
  if (rawPercentage === null || rawPercentage === void 0) {
    return "-";
  }
  const percentage = typeof rawPercentage === "string" ? parseFloat(rawPercentage) : parseFloat(rawPercentage.toString());
  return formatNumber({ input: percentage / 100, type: NumberType.Percentage, locale });
}
export function addFiatSymbolToNumber({
  value,
  locale,
  currencyCode,
  currencySymbol
}) {
  const format = StandardCurrency.createFormat(locale, currencyCode);
  const parts = format.formatToParts(0);
  const isSymbolAtFront = parts[0]?.type === "currency";
  const extra = isSymbolAtFront ? parts[1]?.type === "literal" ? parts[1]?.value : "" : parts[parts.length - 2]?.type === "literal" ? parts[parts.length - 2]?.value : "";
  return isSymbolAtFront ? `${currencySymbol}${extra}${value}` : `${value}${extra}${currencySymbol}`;
}
export function getFiatCurrencyComponents(locale, currencyCode) {
  const format = TwoDecimalsCurrency.createFormat(locale, currencyCode);
  const parts = format.formatToParts(1e6);
  let groupingSeparator = ",";
  let decimalSeparator = ".";
  let symbol = "";
  let fullSymbol = "";
  let symbolAtFront = true;
  parts.forEach((part, index) => {
    if (part.type === "group") {
      groupingSeparator = part.value;
    } else if (part.type === "decimal") {
      decimalSeparator = part.value;
    } else if (part.type === "currency") {
      symbol = part.value;
      fullSymbol = symbol;
      symbolAtFront = index === 0;
      const nextPart = symbolAtFront ? parts[index + 1] : parts[index - 1];
      if (nextPart?.type === "literal") {
        fullSymbol = symbolAtFront ? symbol + nextPart.value : nextPart.value + symbol;
      }
    }
  });
  return {
    groupingSeparator,
    decimalSeparator,
    symbol,
    fullSymbol,
    symbolAtFront
  };
}
