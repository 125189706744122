"use strict";
import { area, curveStepAfter } from "d3";
import styled from "lib/styled-components";
import { useMemo } from "react";
const Path = styled.path`
  opacity: 0.5;
  stroke: ${({ fill, theme }) => fill ?? theme.accent1};
  fill: ${({ fill, theme }) => fill ?? theme.accent1};
`;
export const Area = ({
  series,
  xScale,
  yScale,
  xValue,
  yValue,
  fill
}) => useMemo(
  () => <Path
    fill={fill}
    d={area().curve(curveStepAfter).x((d) => xScale(xValue(d))).y1((d) => yScale(yValue(d))).y0(yScale(0))(
      series.filter((d) => {
        const value = xScale(xValue(d));
        return value > 0 && value <= window.innerWidth;
      })
    ) ?? void 0}
  />,
  [fill, series, xScale, xValue, yScale, yValue]
);
