"use strict";
export class PluginBase {
  _chart = void 0;
  _series = void 0;
  requestUpdate() {
    if (this._requestUpdate) {
      this._requestUpdate();
    }
  }
  _requestUpdate;
  attached({ chart, series, requestUpdate }) {
    this._chart = chart;
    this._series = series;
    this._series.subscribeDataChanged(this._fireDataUpdated);
    this._requestUpdate = requestUpdate;
    this.requestUpdate();
  }
  detached() {
    this._series?.unsubscribeDataChanged(this._fireDataUpdated);
    this._chart = void 0;
    this._series = void 0;
    this._requestUpdate = void 0;
  }
  get chart() {
    if (!this._chart) {
      throw new Error("Value is undefined");
    }
    return this._chart;
  }
  get series() {
    if (!this._series) {
      throw new Error("Value is undefined");
    }
    return this._series;
  }
  // This method is a class property to maintain the
  // lexical 'this' scope (due to the use of the arrow function)
  // and to ensure its reference stays the same, so we can unsubscribe later.
  _fireDataUpdated = (scope) => {
    if (this.dataUpdated) {
      this.dataUpdated(scope);
    }
  };
}
