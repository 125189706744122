"use strict";
import { useCallback, useReducer } from "react";
const useSimplePagination = () => {
  const [page, incrementPage] = useReducer((current) => current + 1, 1);
  const loadMore = useCallback(({ onComplete }) => {
    incrementPage();
    onComplete?.();
  }, []);
  return { page, loadMore };
};
export default useSimplePagination;
