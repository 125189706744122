"use strict";
import { createAction } from "@reduxjs/toolkit";
export const fetchTokenList = {
  pending: createAction("lists/fetchTokenList/pending"),
  fulfilled: createAction("lists/fetchTokenList/fulfilled"),
  rejected: createAction("lists/fetchTokenList/rejected")
};
export const addList = createAction("lists/addList");
export const removeList = createAction("lists/removeList");
export const acceptListUpdate = createAction("lists/acceptListUpdate");
