"use strict";
import { RouterPreference } from "state/routing/types";
export const migration2 = (state) => {
  if (state?.user && state.user?.userRouterPreference === "client") {
    return {
      ...state,
      user: {
        ...state.user,
        userRouterPreference: RouterPreference.API
      },
      _persist: {
        ...state._persist,
        version: 2
      }
    };
  }
  return state;
};
