"use strict";
import { useCallback, useMemo } from "react";
import { useDisconnect as useDisconnectWagmi } from "wagmi";
export function useDisconnect() {
  const { connectors, disconnect, ...rest } = useDisconnectWagmi();
  const disconnectAll = useCallback(() => {
    connectors.forEach((connector) => {
      disconnect({ connector });
    });
  }, [connectors, disconnect]);
  return useMemo(() => ({ ...rest, disconnect: disconnectAll, connectors }), [disconnectAll, connectors, rest]);
}
