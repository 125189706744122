"use strict";
import { mix } from "polished";
import { colors } from "theme/colors";
import { hex } from "wcag-contrast";
export function opacify(amount, hexColor) {
  if (!hexColor.startsWith("#")) {
    return hexColor;
  }
  let normalizedHexColor = hexColor;
  if (hexColor.length === 4) {
    normalizedHexColor = "#" + hexColor[1] + hexColor[1] + hexColor[2] + hexColor[2] + hexColor[3] + hexColor[3];
  }
  if (normalizedHexColor.length !== 7) {
    throw new Error(`opacify: provided color ${hexColor} was not in hexadecimal format (e.g. #000000 or #000)`);
  }
  if (amount < 0 || amount > 100) {
    throw new Error("opacify: provided amount should be between 0 and 100");
  }
  const opacityHex = Math.round(amount / 100 * 255).toString(16).padStart(2, "0");
  return `${normalizedHexColor}${opacityHex}`;
}
const MIN_COLOR_CONTRAST_THRESHOLD = 1.95;
export function passesContrast(color, backgroundColor) {
  const contrast = hex(color, backgroundColor);
  return contrast >= MIN_COLOR_CONTRAST_THRESHOLD;
}
export function getNeutralContrast(accent1) {
  return passesContrast(accent1, colors.white) ? colors.white : colors.black;
}
export function getAccent2(accent1, surface1) {
  return mix(0.12, accent1, surface1);
}
