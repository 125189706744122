"use strict";
import { CurrencyAmount, Fraction } from "@uniswap/sdk-core";
import { parseUnits } from "ethers/lib/utils";
import JSBI from "jsbi";
import { useMemo } from "react";
export var LimitPriceErrorType = /* @__PURE__ */ ((LimitPriceErrorType2) => {
  LimitPriceErrorType2["CALCULATION_ERROR"] = "CALCULATION_ERROR";
  LimitPriceErrorType2["BELOW_MARKET"] = "BELOW_MARKET";
  return LimitPriceErrorType2;
})(LimitPriceErrorType || {});
export function useCurrentPriceAdjustment({
  parsedLimitPrice,
  marketPrice,
  baseCurrency,
  quoteCurrency,
  limitPriceInverted
}) {
  return useMemo(() => {
    if (!parsedLimitPrice || !marketPrice || !baseCurrency || !quoteCurrency || !parsedLimitPrice.baseCurrency.equals(baseCurrency)) {
      return { currentPriceAdjustment: void 0, priceError: "CALCULATION_ERROR" /* CALCULATION_ERROR */ };
    }
    const oneUnitOfBaseCurrency = CurrencyAmount.fromRawAmount(
      baseCurrency,
      JSBI.BigInt(parseUnits("1", baseCurrency?.decimals))
    );
    const marketQuote = marketPrice.quote(oneUnitOfBaseCurrency);
    const parsedPriceQuote = parsedLimitPrice.quote(oneUnitOfBaseCurrency);
    const decimalsScalar = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18));
    const scaledMarketQuote = JSBI.BigInt(marketQuote.multiply(decimalsScalar).toFixed(0));
    const scaledPriceQuote = JSBI.BigInt(parsedPriceQuote.multiply(decimalsScalar).toFixed(0));
    const difference = JSBI.subtract(scaledPriceQuote, scaledMarketQuote);
    const percentageChange = new Fraction(difference, scaledMarketQuote);
    const currentPriceAdjustment = Math.round(Number(percentageChange.multiply(100).toFixed(2)));
    const priceBelowMarket = limitPriceInverted ? currentPriceAdjustment > 0 : currentPriceAdjustment < 0;
    return {
      currentPriceAdjustment,
      priceError: priceBelowMarket ? "BELOW_MARKET" /* BELOW_MARKET */ : void 0
    };
  }, [parsedLimitPrice, marketPrice, baseCurrency, quoteCurrency, limitPriceInverted]);
}
