"use strict";
import { useUnmountingAnimation } from "hooks/useUnmountingAnimation";
import styled, { css, keyframes } from "lib/styled-components";
import { useRef } from "react";
const fadeIn = keyframes`
  from { opacity: 0;}
  to { opacity: 1;}
`;
const fadeAndScaleIn = keyframes`
  from { opacity: 0; transform: scale(0); }
  to { opacity: 1; transform: scale(1); }
`;
const fadeAndTranslateUpIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`;
export const fadeInAnimation = css`
  animation: ${fadeIn}
    ${({ theme, $transitionDuration }) => `${$transitionDuration ?? theme.transition.duration.medium} ${theme.transition.timing.inOut}`}
    forwards;
`;
const fadeAndScaleInAnimation = css`
  animation: ${fadeAndScaleIn}
    ${({ theme, $transitionDuration }) => `${$transitionDuration ?? theme.transition.duration.medium} ${theme.transition.timing.inOut}`}
    forwards;
`;
const fadeAndTranslateUpAnimation = css`
  animation: ${fadeAndTranslateUpIn}
    ${({ theme, $transitionDuration }) => `${$transitionDuration ?? theme.transition.duration.medium} ${theme.transition.timing.inOut}`}
    forwards;
`;
const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0;  }
`;
const fadeAndScaleOut = keyframes`
  from { opacity: 1; transform: scale(1); }
  to { opacity: 0; transform: scale(0); }
`;
const fadeAndTranslateDownOut = keyframes`
  from {opacity: 1; transform: translateY(0); }
  to { opacity: 0; transform: translateY(10px); }
`;
const fadeOutAnimation = css`
  animation: ${fadeOut}
    ${({ theme, $transitionDuration }) => `${$transitionDuration ?? theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
`;
const fadeAndScaleOutAnimation = css`
  animation: ${fadeAndScaleOut}
    ${({ theme, $transitionDuration }) => `${$transitionDuration ?? theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
`;
const fadeAndTranslateDownAnimation = css`
  animation: ${fadeAndTranslateDownOut}
    ${({ theme, $transitionDuration }) => `${$transitionDuration ?? theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
`;
export var AnimationType = /* @__PURE__ */ ((AnimationType2) => {
  AnimationType2["EXITING"] = "exiting";
  return AnimationType2;
})(AnimationType || {});
export var FadePresenceAnimationType = /* @__PURE__ */ ((FadePresenceAnimationType2) => {
  FadePresenceAnimationType2["Fade"] = "fade";
  FadePresenceAnimationType2["FadeAndScale"] = "scale";
  FadePresenceAnimationType2["FadeAndTranslate"] = "translate";
  return FadePresenceAnimationType2;
})(FadePresenceAnimationType || {});
function getEntranceAnimationCss(animationType) {
  switch (animationType) {
    case "scale" /* FadeAndScale */:
      return fadeAndScaleInAnimation;
    case "translate" /* FadeAndTranslate */:
      return fadeAndTranslateUpAnimation;
    case "fade" /* Fade */:
    default:
      return fadeInAnimation;
  }
}
function getExitAnimationCss(animationType) {
  switch (animationType) {
    case "scale" /* FadeAndScale */:
      return fadeAndScaleOutAnimation;
    case "translate" /* FadeAndTranslate */:
      return fadeAndTranslateDownAnimation;
    case "fade" /* Fade */:
    default:
      return fadeOutAnimation;
  }
}
function getAnimationDelayCss($animationDelay, animationType) {
  switch (animationType) {
    case "translate" /* FadeAndTranslate */:
      return css`
        animation-delay: ${$animationDelay};
        opacity: 0;
        translate: translateY(10px);
      `;
    case "scale" /* FadeAndScale */:
    case "fade" /* Fade */:
    default:
      return css`
        animation-delay: ${$animationDelay};
        opacity: 0;
      `;
  }
}
const FadeWrapper = styled.div`
  transition:
    display
      ${({ theme, $transitionDuration }) => `${$transitionDuration ?? theme.transition.duration.medium} ${theme.transition.timing.inOut}`},
    transform
      ${({ theme, $transitionDuration }) => `${$transitionDuration ?? theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
  ${({ animationType }) => getEntranceAnimationCss(animationType)}

  ${({ $animationDelay, animationType }) => $animationDelay && getAnimationDelayCss($animationDelay, animationType)};

  &.${"exiting" /* EXITING */} {
    ${({ animationType }) => getExitAnimationCss(animationType)}
  }
  ${({ $zIndex }) => $zIndex && `z-index: ${$zIndex};`}
`;
export function FadePresence({
  children,
  className,
  animationType = "fade" /* Fade */,
  $transitionDuration,
  $delay,
  $zIndex,
  ...rest
}) {
  const ref = useRef(null);
  useUnmountingAnimation(ref, () => "exiting" /* EXITING */);
  return <FadeWrapper
    ref={ref}
    className={className}
    $transitionDuration={$transitionDuration}
    $animationDelay={$delay}
    animationType={animationType}
    $zIndex={$zIndex}
    {...rest}
  >{children}</FadeWrapper>;
}
