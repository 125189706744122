"use strict";
import { useAccount } from "hooks/useAccount";
import { useContext } from "react";
import { MultichainContext } from "state/multichain/types";
export function useMultichainContext() {
  const account = useAccount();
  const context = useContext(MultichainContext);
  return {
    ...context,
    chainId: context.isMultichainContext ? context.chainId : account.chainId
  };
}
