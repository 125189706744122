"use strict";
export var RoutingActions = /* @__PURE__ */ ((RoutingActions2) => {
  RoutingActions2["Buy"] = "Buy";
  RoutingActions2["Sell"] = "Sell";
  RoutingActions2["Swap"] = "Swap";
  return RoutingActions2;
})(RoutingActions || {});
export var TxStateType = /* @__PURE__ */ ((TxStateType2) => {
  TxStateType2["Success"] = "Success";
  TxStateType2["Denied"] = "Denied";
  TxStateType2["Invalid"] = "Invalid";
  TxStateType2["Failed"] = "Failed";
  TxStateType2["New"] = "New";
  TxStateType2["Signing"] = "Signing";
  TxStateType2["Confirming"] = "Confirming";
  return TxStateType2;
})(TxStateType || {});
export var BagItemStatus = /* @__PURE__ */ ((BagItemStatus2) => {
  BagItemStatus2["ADDED_TO_BAG"] = "Added to bag";
  BagItemStatus2["REVIEWED"] = "Reviewed";
  BagItemStatus2["REVIEWING_PRICE_CHANGE"] = "REVIEWING_PRICE_CHANGE";
  BagItemStatus2["UNAVAILABLE"] = "UNAVAILABLE";
  return BagItemStatus2;
})(BagItemStatus || {});
export var BagStatus = /* @__PURE__ */ ((BagStatus2) => {
  BagStatus2["ADDING_TO_BAG"] = "Adding to bag";
  BagStatus2["FETCHING_ROUTE"] = "Fetching route";
  BagStatus2["IN_REVIEW"] = "In review";
  BagStatus2["WARNING"] = "Warning";
  BagStatus2["CONFIRM_REVIEW"] = "Confirming review";
  BagStatus2["FETCHING_FINAL_ROUTE"] = "Fetching final route";
  BagStatus2["CONFIRMING_IN_WALLET"] = "Confirming in wallet";
  BagStatus2["PROCESSING_TRANSACTION"] = "Processing";
  BagStatus2["CONFIRM_QUOTE"] = "Confirm quote";
  return BagStatus2;
})(BagStatus || {});
