"use strict";
import { CurrencySearch } from "components/SearchModal/CurrencySearch";
import TokenSafety from "components/TokenSafety";
import useLast from "hooks/useLast";
import { memo, useCallback, useEffect, useState } from "react";
import { useUserAddedTokens } from "state/user/userAddedTokens";
import { AdaptiveWebModal } from "ui/src";
import { TOKEN_SELECTOR_WEB_MAX_WIDTH } from "uniswap/src/components/TokenSelector/TokenSelector";
import { INTERFACE_NAV_HEIGHT } from "uniswap/src/theme/heights";
import { CurrencyField } from "uniswap/src/types/currency";
var CurrencyModalView = /* @__PURE__ */ ((CurrencyModalView2) => {
  CurrencyModalView2[CurrencyModalView2["search"] = 0] = "search";
  CurrencyModalView2[CurrencyModalView2["importToken"] = 1] = "importToken";
  CurrencyModalView2[CurrencyModalView2["tokenSafety"] = 2] = "tokenSafety";
  return CurrencyModalView2;
})(CurrencyModalView || {});
export default memo(function CurrencySearchModal({
  isOpen,
  onDismiss,
  onCurrencySelect,
  currencyField = CurrencyField.INPUT
}) {
  const [modalView, setModalView] = useState(0 /* search */);
  const lastOpen = useLast(isOpen);
  const userAddedTokens = useUserAddedTokens();
  useEffect(() => {
    if (isOpen && !lastOpen) {
      setModalView(0 /* search */);
    }
  }, [isOpen, lastOpen]);
  const showTokenSafetySpeedbump = (token) => {
    setWarningToken(token);
    setModalView(2 /* tokenSafety */);
  };
  const handleCurrencySelect = useCallback(
    (currency, hasWarning) => {
      if (hasWarning && currency.isToken && !userAddedTokens.find((token) => token.equals(currency))) {
        showTokenSafetySpeedbump(currency);
      } else {
        onCurrencySelect(currency);
        onDismiss();
      }
    },
    [onDismiss, onCurrencySelect, userAddedTokens]
  );
  const [warningToken, setWarningToken] = useState();
  let content = null;
  switch (modalView) {
    case 0 /* search */:
      content = <CurrencySearch currencyField={currencyField} onCurrencySelect={onCurrencySelect} onDismiss={onDismiss} />;
      break;
    case 2 /* tokenSafety */:
      if (warningToken) {
        content = <TokenSafety
          token0={warningToken}
          onAcknowledge={() => handleCurrencySelect(warningToken)}
          closeModalOnly={() => setModalView(0 /* search */)}
          showCancel={true}
        />;
      }
      break;
  }
  return <AdaptiveWebModal
    isOpen={isOpen}
    onClose={onDismiss}
    maxHeight={modalView === 2 /* tokenSafety */ ? 400 : 700}
    maxWidth={TOKEN_SELECTOR_WEB_MAX_WIDTH}
    px={0}
    py={0}
    flex={1}
    $sm={{ height: `calc(100dvh - ${INTERFACE_NAV_HEIGHT}px)` }}
  >{content}</AdaptiveWebModal>;
});
