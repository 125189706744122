"use strict";
import styled from "lib/styled-components";
import { readableColor } from "polished";
export var BadgeVariant = /* @__PURE__ */ ((BadgeVariant2) => {
  BadgeVariant2["DEFAULT"] = "DEFAULT";
  BadgeVariant2["NEGATIVE"] = "NEGATIVE";
  BadgeVariant2["POSITIVE"] = "POSITIVE";
  BadgeVariant2["PRIMARY"] = "PRIMARY";
  BadgeVariant2["WARNING"] = "WARNING";
  BadgeVariant2["PROMOTIONAL"] = "PROMOTIONAL";
  BadgeVariant2["BRANDED"] = "BRANDED";
  BadgeVariant2["SOFT"] = "SOFT";
  BadgeVariant2["WARNING_OUTLINE"] = "WARNING_OUTLINE";
  return BadgeVariant2;
})(BadgeVariant || {});
function pickBackgroundColor(variant, theme) {
  switch (variant) {
    case "BRANDED" /* BRANDED */:
      return theme.brandedGradient;
    case "PROMOTIONAL" /* PROMOTIONAL */:
      return theme.promotionalGradient;
    case "NEGATIVE" /* NEGATIVE */:
      return theme.critical;
    case "POSITIVE" /* POSITIVE */:
      return theme.success;
    case "SOFT" /* SOFT */:
      return theme.accent2;
    case "PRIMARY" /* PRIMARY */:
      return theme.accent1;
    case "WARNING" /* WARNING */:
      return theme.deprecated_accentWarning;
    case "WARNING_OUTLINE" /* WARNING_OUTLINE */:
      return "transparent";
    default:
      return theme.surface2;
  }
}
function pickBorder(variant, theme) {
  switch (variant) {
    case "WARNING_OUTLINE" /* WARNING_OUTLINE */:
      return `1px solid ${theme.deprecated_accentWarning}`;
    default:
      return "unset";
  }
}
function pickFontColor(variant, theme) {
  switch (variant) {
    case "BRANDED" /* BRANDED */:
      return theme.darkMode ? theme.neutral1 : theme.white;
    case "NEGATIVE" /* NEGATIVE */:
      return readableColor(theme.critical);
    case "POSITIVE" /* POSITIVE */:
      return readableColor(theme.success);
    case "SOFT" /* SOFT */:
      return theme.accent1;
    case "WARNING" /* WARNING */:
      return readableColor(theme.deprecated_accentWarning);
    case "WARNING_OUTLINE" /* WARNING_OUTLINE */:
      return theme.deprecated_accentWarning;
    default:
      return theme.neutral2;
  }
}
const Badge = styled.div`
  align-items: center;
  background: ${({ theme, variant }) => pickBackgroundColor(variant, theme)};
  border: ${({ theme, variant }) => pickBorder(variant, theme)};
  border-radius: 0.5rem;
  color: ${({ theme, variant }) => pickFontColor(variant, theme)};
  display: inline-flex;
  padding: 4px 6px;
  justify-content: center;
  font-weight: 535;
`;
export default Badge;
