"use strict";
import { createReducer } from "@reduxjs/toolkit";
import { selectPercent } from "state/burn/v3/actions";
const initialState = {
  percent: 0
};
export default createReducer(
  initialState,
  (builder) => builder.addCase(selectPercent, (state, { payload: { percent } }) => {
    return {
      ...state,
      percent
    };
  })
);
