"use strict";
import { useENSRegistrarContract, useENSResolverContract } from "hooks/useContract";
import useDebounce from "hooks/useDebounce";
import useENSAddress from "hooks/useENSAddress";
import { NEVER_RELOAD, useMainnetSingleCallResult } from "lib/hooks/multicall";
import { useMemo } from "react";
import { isAddress } from "utilities/src/addresses";
import isZero from "utils/isZero";
import { safeNamehash } from "utils/safeNamehash";
export default function useENSName(address) {
  const debouncedAddress = useDebounce(address, 200);
  const ensNodeArgument = useMemo(() => {
    if (!debouncedAddress || !isAddress(debouncedAddress)) {
      return [void 0];
    }
    return [safeNamehash(`${debouncedAddress.toLowerCase().substr(2)}.addr.reverse`)];
  }, [debouncedAddress]);
  const registrarContract = useENSRegistrarContract();
  const resolverAddress = useMainnetSingleCallResult(registrarContract, "resolver", ensNodeArgument, NEVER_RELOAD);
  const resolverAddressResult = resolverAddress.result?.[0];
  const resolverContract = useENSResolverContract(
    resolverAddressResult && !isZero(resolverAddressResult) ? resolverAddressResult : void 0
  );
  const nameCallRes = useMainnetSingleCallResult(resolverContract, "name", ensNodeArgument, NEVER_RELOAD);
  const name = nameCallRes.result?.[0];
  const fwdAddr = useENSAddress(name);
  const checkedName = address === fwdAddr?.address ? name : null;
  const changed = debouncedAddress !== address;
  const loading = changed || resolverAddress.loading || nameCallRes.loading || fwdAddr.loading;
  return useMemo(
    () => ({
      ENSName: changed ? null : checkedName,
      loading
    }),
    [changed, checkedName, loading]
  );
}
