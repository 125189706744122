"use strict";
import AppJsonRpcProvider from "rpc/AppJsonRpcProvider";
import ConfiguredJsonRpcProvider from "rpc/ConfiguredJsonRpcProvider";
import { getChainInfo } from "uniswap/src/features/chains/chainInfo";
import { SUPPORTED_CHAIN_IDS } from "uniswap/src/features/chains/types";
function getAppProvider(chainId) {
  const info = getChainInfo(chainId);
  return new AppJsonRpcProvider(
    info.rpcUrls.interface.http.map((url) => new ConfiguredJsonRpcProvider(url, { chainId, name: info.interfaceName }))
  );
}
export const RPC_PROVIDERS = Object.fromEntries(
  SUPPORTED_CHAIN_IDS.map((chain) => [chain, getAppProvider(chain)])
);
