"use strict";
import { AppDownloadPlatform, InterfaceElementName, InterfaceEventName } from "@uniswap/analytics-events";
import { sendAnalyticsEvent } from "uniswap/src/features/telemetry/send";
import { isWebAndroid, isWebIOS } from "utilities/src/platform";
export const APP_DOWNLOAD_LINKS = {
  [InterfaceElementName.UNISWAP_WALLET_MODAL_DOWNLOAD_BUTTON]: "https://uniswapwallet.onelink.me/8q3y/qfwlncf9",
  [InterfaceElementName.UNISWAP_WALLET_NAVBAR_MENU_DOWNLOAD_BUTTON]: "https://uniswapwallet.onelink.me/8q3y/46tvu6pb",
  [InterfaceElementName.UNISWAP_WALLET_LANDING_PAGE_DOWNLOAD_BUTTON]: "https://uniswapwallet.onelink.me/8q3y/79gveilz",
  [InterfaceElementName.UNISWAP_WALLET_BANNER_DOWNLOAD_BUTTON]: "https://uniswapwallet.onelink.me/8q3y/jh9orof3"
};
export const MICROSITE_LINK = "https://wallet.uniswap.org/";
export function openDownloadApp({ element }) {
  if (isWebIOS) {
    openDownloadStore({ element, appPlatform: AppDownloadPlatform.IOS, linkTarget: "uniswap_wallet_appstore" });
  } else if (isWebAndroid) {
    openDownloadStore({ element, appPlatform: AppDownloadPlatform.ANDROID, linkTarget: "uniswap_wallet_playstore" });
  } else {
    sendAnalyticsEvent(InterfaceEventName.UNISWAP_WALLET_MICROSITE_OPENED, { element });
    window.open(
      APP_DOWNLOAD_LINKS[element],
      /* target = */
      "uniswap_wallet_microsite"
    );
  }
}
const openDownloadStore = (options) => {
  sendAnalyticsEvent(InterfaceEventName.UNISWAP_WALLET_APP_DOWNLOAD_OPENED, {
    element: options.element,
    appPlatform: options?.appPlatform
  });
  window.open(
    APP_DOWNLOAD_LINKS[options.element],
    /* target = */
    options.linkTarget
  );
};
