import { G, Path, Svg } from "react-native-svg";
import { createIcon } from "../factories/createIcon";
export const [SwapCoin, AnimatedSwapCoin] = createIcon({
  name: "SwapCoin",
  getIcon: (props) => <Svg viewBox="0 0 16 16" fill="none" {...props}><G id="swap-coin"><Path
    id="Vector"
    d="M2.0006 7.8667C1.9826 7.8667 1.96397 7.86601 1.94531 7.86401C1.67064 7.83401 1.47264 7.58666 1.50264 7.31266C1.74464 5.10133 3.07929 3.19668 5.07263 2.21802C5.22796 2.14202 5.41129 2.15134 5.55729 2.24268C5.70395 2.33401 5.79329 2.49465 5.79329 2.66732V4.70068C5.79329 4.97668 5.56929 5.20068 5.29329 5.20068C5.01729 5.20068 4.79329 4.97668 4.79329 4.70068V3.54004C3.51129 4.43204 2.6706 5.83264 2.49727 7.42131C2.46927 7.67664 2.25194 7.8667 2.0006 7.8667ZM10.9273 13.782C12.9206 12.804 14.2553 10.8993 14.4966 8.68734C14.5273 8.41267 14.3286 8.16599 14.0539 8.13599C13.7819 8.10665 13.5333 8.30403 13.5033 8.57869C13.3293 10.1674 12.4893 11.568 11.2066 12.46V11.2993C11.2066 11.0233 10.9826 10.7993 10.7066 10.7993C10.4306 10.7993 10.2066 11.0233 10.2066 11.2993V13.3327C10.2066 13.5053 10.296 13.666 10.4426 13.7573C10.5233 13.8073 10.6146 13.8327 10.7066 13.8327C10.782 13.8333 10.8573 13.8166 10.9273 13.782ZM7.99995 4.66667C7.99995 6.13933 9.19395 7.33333 10.6666 7.33333C12.1393 7.33333 13.3333 6.13933 13.3333 4.66667C13.3333 3.194 12.1393 2 10.6666 2C9.19395 2 7.99995 3.194 7.99995 4.66667ZM2.66662 11.3333C2.66662 12.806 3.86062 14 5.33329 14C6.80595 14 7.99995 12.806 7.99995 11.3333C7.99995 9.86067 6.80595 8.66667 5.33329 8.66667C3.86062 8.66667 2.66662 9.86067 2.66662 11.3333Z"
    fill="currentColor"
  /></G></Svg>,
  defaultFill: "#9B9B9B"
});
