import { split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
export function splitSubscription(subscriptionLink, httpLink) {
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    subscriptionLink,
    httpLink
  );
}
