"use strict";
import { Percent } from "@uniswap/sdk-core";
export var SlippageValidationResult = /* @__PURE__ */ ((SlippageValidationResult2) => {
  SlippageValidationResult2[SlippageValidationResult2["TooLow"] = 0] = "TooLow";
  SlippageValidationResult2[SlippageValidationResult2["TooHigh"] = 1] = "TooHigh";
  SlippageValidationResult2[SlippageValidationResult2["Valid"] = 2] = "Valid";
  return SlippageValidationResult2;
})(SlippageValidationResult || {});
export const MINIMUM_RECOMMENDED_SLIPPAGE = new Percent(5, 1e4);
export const MAXIMUM_RECOMMENDED_SLIPPAGE = new Percent(1, 100);
export default function validateUserSlippageTolerance(userSlippageTolerance) {
  if (userSlippageTolerance.lessThan(MINIMUM_RECOMMENDED_SLIPPAGE)) {
    return 0 /* TooLow */;
  } else if (userSlippageTolerance.greaterThan(MAXIMUM_RECOMMENDED_SLIPPAGE)) {
    return 1 /* TooHigh */;
  } else {
    return 2 /* Valid */;
  }
}
