"use strict";
import contenthashToUri from "lib/utils/contenthashToUri";
import parseENSAddress from "lib/utils/parseENSAddress";
import { uriToHttpUrls } from "utilities/src/format/urls";
import { logger } from "utilities/src/logger/logger";
import { validateTokenList } from "utils/validateTokenList";
const listCache = /* @__PURE__ */ new Map();
export default async function fetchTokenList(listUrl, resolveENSContentHash, skipValidation) {
  const cached = listCache?.get(listUrl);
  if (cached) {
    return cached;
  }
  let urls;
  const parsedENS = parseENSAddress(listUrl);
  if (parsedENS) {
    let contentHashUri;
    try {
      contentHashUri = await resolveENSContentHash(parsedENS.ensName);
    } catch (error) {
      const message = `failed to resolve ENS name: ${parsedENS.ensName}`;
      logger.debug("fetchTokenList", "fetchTokenList", message, error);
      throw new Error(message);
    }
    let translatedUri;
    try {
      translatedUri = contenthashToUri(contentHashUri);
    } catch (error) {
      const message = `failed to translate contenthash to URI: ${contentHashUri}`;
      logger.debug("fetchTokenList", "fetchTokenList", message, error);
      throw new Error(message);
    }
    urls = uriToHttpUrls(`${translatedUri}${parsedENS.ensPath ?? ""}`);
  } else {
    urls = uriToHttpUrls(listUrl);
  }
  if (urls.length === 0) {
    throw new Error("Unrecognized list URL protocol.");
  }
  for (let i = 0; i < urls.length; i++) {
    const url = urls[i];
    let response;
    try {
      response = await fetch(url, { credentials: "omit" });
    } catch (error) {
      logger.debug("fetchTokenList", "fetchTokenList", `failed to fetch list: ${listUrl} (${url})`, error);
      continue;
    }
    if (!response.ok) {
      logger.debug("fetchTokenList", "fetchTokenList", `failed to fetch list ${listUrl} (${url})`, response.statusText);
      continue;
    }
    try {
      const json = await response.json();
      const list = skipValidation ? json : await validateTokenList(json);
      listCache?.set(listUrl, list);
      return list;
    } catch (error) {
      logger.debug(
        "fetchTokenList",
        "fetchTokenList",
        `failed to parse and validate list response: ${listUrl} (${url})`,
        error
      );
      continue;
    }
  }
  throw new Error(`No valid token list found at any URLs derived from ${listUrl}.`);
}
