"use strict";
export const migration8 = (state) => {
  if (!state) {
    return state;
  }
  if (state?.user && "hideAppPromoBanner" in state.user) {
    delete state.user["hideAppPromoBanner"];
  }
  return {
    ...state,
    _persist: {
      ...state._persist,
      version: 8
    }
  };
};
