"use strict";
export var ListingStatus = /* @__PURE__ */ ((ListingStatus2) => {
  ListingStatus2["APPROVED"] = "Approved";
  ListingStatus2["CONTINUE"] = "Continue";
  ListingStatus2["DEFINED"] = "Defined";
  ListingStatus2["FAILED"] = "Failed";
  ListingStatus2["PAUSED"] = "Paused";
  ListingStatus2["PENDING"] = "Pending";
  ListingStatus2["REJECTED"] = "Rejected";
  ListingStatus2["SIGNING"] = "Signing";
  return ListingStatus2;
})(ListingStatus || {});
export var ProfilePageStateType = /* @__PURE__ */ ((ProfilePageStateType2) => {
  ProfilePageStateType2[ProfilePageStateType2["VIEWING"] = 0] = "VIEWING";
  ProfilePageStateType2[ProfilePageStateType2["LISTING"] = 1] = "LISTING";
  return ProfilePageStateType2;
})(ProfilePageStateType || {});
