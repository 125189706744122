"use strict";
import { useCurrencyInfo } from "hooks/Tokens";
import { useState } from "react";
import { PositionField } from "types/position";
import { Flex } from "ui/src";
import { CurrencyInputPanel } from "uniswap/src/components/CurrencyInputPanel/CurrencyInputPanel";
import { CurrencyField } from "uniswap/src/types/currency";
export function DepositInputForm({
  token0,
  token1,
  currencyAmounts,
  currencyBalances,
  currencyAmountsUSDValue,
  formattedAmounts,
  onUserInput,
  onSetMax,
  deposit0Disabled,
  deposit1Disabled
}) {
  const [focusedInputField, setFocusedInputField] = useState(PositionField.TOKEN0);
  const token0CurrencyInfo = useCurrencyInfo(token0);
  const token1CurrencyInfo = useCurrencyInfo(token1);
  const handleUserInput = (field) => {
    return (newValue) => {
      onUserInput(field, newValue);
    };
  };
  const handleOnSetMax = (field) => {
    return (amount) => {
      setFocusedInputField(field);
      onSetMax(field, amount);
    };
  };
  return <Flex gap="$gap4">
    {!deposit0Disabled && <CurrencyInputPanel
      enableInputOnly
      focus={focusedInputField === PositionField.TOKEN0}
      borderRadius="$rounded20"
      backgroundColor="$surface2"
      currencyInfo={token0CurrencyInfo}
      currencyField={CurrencyField.INPUT}
      currencyAmount={currencyAmounts?.[PositionField.TOKEN0]}
      currencyBalance={currencyBalances?.[PositionField.TOKEN0]}
      onSetExactAmount={handleUserInput(PositionField.TOKEN0)}
      onToggleIsFiatMode={() => void 0}
      usdValue={currencyAmountsUSDValue?.[PositionField.TOKEN0]}
      onSetMax={handleOnSetMax(PositionField.TOKEN0)}
      value={formattedAmounts?.[PositionField.TOKEN0]}
      onPressIn={() => setFocusedInputField(PositionField.TOKEN0)}
    />}
    {!deposit1Disabled && <CurrencyInputPanel
      enableInputOnly
      focus={focusedInputField === PositionField.TOKEN1}
      py="$spacing16"
      borderRadius="$rounded20"
      backgroundColor="$surface2"
      currencyInfo={token1CurrencyInfo}
      currencyField={CurrencyField.INPUT}
      currencyAmount={currencyAmounts?.[PositionField.TOKEN1]}
      currencyBalance={currencyBalances?.[PositionField.TOKEN1]}
      onSetExactAmount={handleUserInput(PositionField.TOKEN1)}
      onToggleIsFiatMode={() => void 0}
      usdValue={currencyAmountsUSDValue?.[PositionField.TOKEN1]}
      onSetMax={handleOnSetMax(PositionField.TOKEN1)}
      value={formattedAmounts?.[PositionField.TOKEN1]}
      onPressIn={() => setFocusedInputField(PositionField.TOKEN1)}
    />}
  </Flex>;
}
