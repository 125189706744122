"use strict";
import { useCurrencyInfo } from "hooks/Tokens";
import { useTheme } from "lib/styled-components";
import { useMemo } from "react";
import { useExtractedTokenColor } from "ui/src";
export function useColor(currency, contrastSettings) {
  const theme = useTheme();
  const currencyInfo = useCurrencyInfo(currency);
  const src = currencyInfo?.logoUrl ?? void 0;
  return useSrcColor(src, currency?.name, contrastSettings?.backgroundColor).tokenColor ?? theme.accent1;
}
export function useSrcColor(src, currencyName, backgroundColor) {
  const theme = useTheme();
  const extractSrc = useMemo(
    () => src?.includes("coingecko") ? "https://corsproxy.io/?" + encodeURIComponent(src) : src,
    [src]
  );
  return useExtractedTokenColor(extractSrc, currencyName, backgroundColor ?? theme.surface1, theme.accent1);
}
