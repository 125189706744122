"use strict";
import {
  TransactionType
} from "state/transactions/types";
import { TransactionStatus } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
export function isPendingTx(tx, skipDepositedBridgeTxs = false) {
  const skipBridgeTx = skipDepositedBridgeTxs && tx.info.type === TransactionType.BRIDGE && tx.info.depositConfirmed;
  return tx.status === TransactionStatus.Pending && !tx.cancelled && !skipBridgeTx;
}
export function isConfirmedTx(tx) {
  return tx.status === TransactionStatus.Confirmed || tx.status === TransactionStatus.Failed;
}
