export function trimToLength(value, maxLength) {
  if (!value) {
    return "";
  }
  const trimmed = value.trim();
  return trimmed.length > maxLength ? `${trimmed.substring(0, maxLength)}...` : trimmed;
}
export function normalizeTextInput(input, toLowerCase = true) {
  const trimmed = input.trim().replace(/\s+/g, " ");
  return toLowerCase ? trimmed.toLowerCase() : trimmed;
}
export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
export function normalizeTwitterUsername(input) {
  return input.replace(/[^a-zA-Z0-9_]/g, "");
}
export function concatStrings(list, endAdornmentText) {
  let result = list.join(", ");
  const lastCommaIndex = result.lastIndexOf(",");
  if (lastCommaIndex !== -1) {
    const before = result.slice(0, lastCommaIndex);
    const after = result.slice(lastCommaIndex + 1);
    result = before + " " + endAdornmentText + after;
  }
  return result;
}
export function containsNonPrintableChars(msg) {
  const regex = /[\p{C}\p{Z}]/gu;
  if (regex.test(msg)) {
    return ![...msg].every((char) => char === "\n" || char === "\r" || char === "	" || !/\p{C}/u.test(char));
  }
  return false;
}
