export var NumberType = /* @__PURE__ */ ((NumberType2) => {
  NumberType2["TokenNonTx"] = "token-non-tx";
  NumberType2["TokenTx"] = "token-tx";
  NumberType2["SwapPrice"] = "swap-price";
  NumberType2["SwapTradeAmount"] = "swap-trade-amount";
  NumberType2["FiatStandard"] = "fiat-standard";
  NumberType2["FiatTokenDetails"] = "fiat-token-details";
  NumberType2["FiatTokenPrice"] = "fiat-token-price";
  NumberType2["FiatTokenStats"] = "fiat-token-stats";
  NumberType2["FiatTokenQuantity"] = "fiat-token-quantity";
  NumberType2["FiatGasPrice"] = "fiat-gas-price";
  NumberType2["PortfolioBalance"] = "portfolio-balance";
  NumberType2["NFTTokenFloorPrice"] = "nft-token-floor-price";
  NumberType2["NFTCollectionStats"] = "nft-collection-stats";
  NumberType2["Percentage"] = "percentage";
  return NumberType2;
})(NumberType || {});
