/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SafetyLevel {
    BLOCKED = 'BLOCKED',
    MEDIUM_WARNING = 'MEDIUM_WARNING',
    STRONG_WARNING = 'STRONG_WARNING',
    VERIFIED = 'VERIFIED',
}
