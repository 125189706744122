"use strict";
export function filterToKey(filter) {
  return `${filter.address ?? ""}:${filter.topics?.map((topic) => topic ? Array.isArray(topic) ? topic.join(";") : topic : "\0")?.join("-") ?? ""}:${filter.fromBlock ?? ""}:${filter.toBlock ?? ""}`;
}
export function keyToFilter(key) {
  const pcs = key.split(":");
  const address = pcs[0];
  const topics = pcs[1].split("-").map((topic) => {
    if (topic === "\0") {
      return null;
    }
    const parts = topic.split(";");
    if (parts.length === 1) {
      return parts[0];
    }
    return parts;
  });
  const fromBlock = pcs[2];
  const toBlock = pcs[3];
  return {
    address: address.length === 0 ? void 0 : address,
    topics,
    fromBlock: fromBlock.length === 0 ? void 0 : fromBlock,
    toBlock: toBlock.length === 0 ? void 0 : toBlock
  };
}
export function isHistoricalLog(filter, blockNumber) {
  if (!filter.toBlock) {
    return false;
  }
  let toBlock = filter.toBlock;
  if (typeof toBlock === "string") {
    toBlock = Number.parseInt(toBlock);
  }
  return toBlock <= blockNumber;
}
