"use strict";
import { useWeb3React } from "@web3-react/core";
import { useAccount } from "hooks/useAccount";
import useBlockNumber from "lib/hooks/useBlockNumber";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { fetchedLogs, fetchedLogsError, fetchingLogs } from "state/logs/slice";
import { isHistoricalLog, keyToFilter } from "state/logs/utils";
import { logger } from "utilities/src/logger/logger";
export default function Updater() {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state2) => state2.logs);
  const { chainId } = useAccount();
  const { provider } = useWeb3React();
  const blockNumber = useBlockNumber;
  const filtersNeedFetch = useMemo(() => {
    if (!chainId || typeof blockNumber !== "number") {
      return [];
    }
    const active = state[chainId];
    if (!active) {
      return [];
    }
    return Object.keys(active).filter((key) => {
      const { fetchingBlockNumber, results, listeners } = active[key];
      if (listeners === 0) {
        return false;
      }
      if (typeof fetchingBlockNumber === "number" && fetchingBlockNumber >= blockNumber) {
        return false;
      }
      if (results && typeof results.blockNumber === "number" && results.blockNumber >= blockNumber) {
        return false;
      }
      if (isHistoricalLog(keyToFilter(key), blockNumber) && results?.logs !== void 0) {
        return false;
      }
      return true;
    }).map((key) => keyToFilter(key));
  }, [blockNumber, chainId, state]);
  useEffect(() => {
    if (!provider || !chainId || typeof blockNumber !== "number" || filtersNeedFetch.length === 0) {
      return;
    }
    dispatch(fetchingLogs({ chainId, filters: filtersNeedFetch, blockNumber }));
    filtersNeedFetch.forEach((filter) => {
      let fromBlock = filter.fromBlock ?? 0;
      let toBlock = filter.toBlock ?? blockNumber;
      if (typeof fromBlock === "string") {
        fromBlock = Number.parseInt(fromBlock);
      }
      if (typeof toBlock === "string") {
        toBlock = Number.parseInt(toBlock);
      }
      provider.getLogs({
        ...filter,
        fromBlock,
        toBlock
      }).then((logs) => {
        dispatch(
          fetchedLogs({
            chainId,
            filter,
            results: { logs, blockNumber }
          })
        );
      }).catch((error) => {
        logger.warn("logs/updater", "Updater#useEffect", "Failed to fetch logs", { error, filter });
        dispatch(
          fetchedLogsError({
            chainId,
            filter,
            blockNumber
          })
        );
      });
    });
  }, [blockNumber, chainId, dispatch, filtersNeedFetch, provider]);
  return null;
}
