"use strict";
import { TradeType } from "@uniswap/sdk-core";
import { useAssetActivitySubscription } from "graphql/data/apollo/AssetActivityProvider";
import { supportedChainIdFromGQLChain } from "graphql/data/util";
import { useCallback, useEffect, useRef } from "react";
import { usePendingOrders } from "state/signatures/hooks";
import { parseRemote as parseRemoteOrder } from "state/signatures/parseRemote";
import { useMultichainTransactions } from "state/transactions/hooks";
import { TransactionType } from "state/transactions/types";
import {
  TransactionDirection,
  TransactionStatus
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
export function useOnAssetActivity(onActivityUpdate) {
  const onOrderActivity = useOnOrderActivity(onActivityUpdate);
  const onTransactionActivity = useOnTransactionActivity(onActivityUpdate);
  const onActivity = useCallback(
    (activity2) => {
      if (activity2?.details.__typename === "SwapOrderDetails") {
        onOrderActivity(activity2);
      } else if (activity2?.details.__typename === "TransactionDetails") {
        onTransactionActivity(activity2);
      }
    },
    [onOrderActivity, onTransactionActivity]
  );
  const result = useAssetActivitySubscription();
  const activity = result.data?.onAssetActivity;
  useEffect(() => onActivity(activity), [activity, onActivity]);
}
function useOnOrderActivity(onActivityUpdate) {
  const pendingOrders = useRef([]);
  pendingOrders.current = usePendingOrders();
  return useCallback(
    async (activity) => {
      const updatedOrder = parseRemoteOrder(activity);
      const pendingOrder = pendingOrders.current.find((order) => order.id === updatedOrder.id) ?? updatedOrder;
      onActivityUpdate({
        type: "signature",
        chainId: updatedOrder.chainId,
        original: pendingOrder,
        update: updatedOrder
      });
    },
    [onActivityUpdate]
  );
}
function useOnTransactionActivity(onActivityUpdate) {
  const pendingTransactions = useRef();
  pendingTransactions.current = useMultichainTransactions();
  return useCallback(
    async (activity) => {
      const chainId = supportedChainIdFromGQLChain(activity.chain);
      if (activity.details.status === TransactionStatus.Pending || !chainId) {
        return;
      }
      const pendingTransaction = pendingTransactions.current?.find(
        ([tx, txChainId]) => tx.hash === activity.details.hash && txChainId === chainId
      )?.[0];
      if (!pendingTransaction) {
        return;
      }
      const updatedTransaction = {
        status: activity.details.status,
        info: { ...pendingTransaction.info }
      };
      if (updatedTransaction.info.type === TransactionType.SWAP) {
        if (updatedTransaction.info.tradeType === TradeType.EXACT_INPUT) {
          const change = activity.details.assetChanges.find(
            (change2) => change2?.__typename === "TokenTransfer" && change2?.direction === TransactionDirection.Out
          );
          if (change.asset.decimals && change.quantity) {
            const amountRaw = (change.asset.decimals * parseFloat(change.quantity)).toFixed(0);
            updatedTransaction.info.settledOutputCurrencyAmountRaw = amountRaw;
          }
        } else if (updatedTransaction.info.tradeType === TradeType.EXACT_OUTPUT) {
        }
      }
      onActivityUpdate({
        type: "transaction",
        chainId,
        original: pendingTransaction,
        update: updatedTransaction
      });
    },
    [onActivityUpdate]
  );
}
