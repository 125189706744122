"use strict";
import {
  CrosshairMode
} from "lightweight-charts";
function centreOffset(lineBitmapWidth) {
  return Math.floor(lineBitmapWidth * 0.5);
}
export function positionsLine(positionMedia, pixelRatio, desiredWidthMedia = 1, widthIsBitmap) {
  const scaledPosition = Math.round(pixelRatio * positionMedia);
  const lineBitmapWidth = widthIsBitmap ? desiredWidthMedia : Math.round(desiredWidthMedia * pixelRatio);
  const offset = centreOffset(lineBitmapWidth);
  const position = scaledPosition - offset;
  return { position, length: lineBitmapWidth };
}
class CrosshairHighlightPaneRenderer {
  _data;
  constructor(data) {
    this._data = data;
  }
  draw(target) {
    if (!this._data.visible) {
      return;
    }
    target.useBitmapCoordinateSpace((scope) => {
      const ctx = scope.context;
      const crosshairPos = positionsLine(this._data.x, scope.horizontalPixelRatio, Math.max(1, this._data.barSpacing));
      ctx.fillStyle = this._data.color;
      const crosshairYPosition = this._data.crosshairYPosition * scope.verticalPixelRatio;
      const margin = Math.min(
        Math.max(scope.horizontalPixelRatio, crosshairPos.length),
        this._data.barSpacing * scope.horizontalPixelRatio
      ) * 0.035;
      const crosshairXPosition = crosshairPos.position + margin;
      ctx.beginPath();
      if (this._data.useThinCrosshair) {
        ctx.fillRect(
          crosshairXPosition + crosshairPos.length / 2,
          crosshairYPosition,
          2,
          scope.bitmapSize.height - crosshairYPosition
        );
      } else {
        ctx.roundRect(
          crosshairXPosition,
          crosshairYPosition,
          crosshairPos.length,
          scope.bitmapSize.height - crosshairYPosition,
          9
        );
        ctx.fill();
      }
      ctx.globalCompositeOperation = "destination-out";
      ctx.globalAlpha = 0.76;
      ctx.fillStyle = "black";
      ctx.fillRect(0, crosshairYPosition, crosshairXPosition, scope.bitmapSize.height - crosshairYPosition);
      ctx.fillRect(
        crosshairXPosition + crosshairPos.length,
        crosshairYPosition,
        scope.bitmapSize.width - (crosshairXPosition + crosshairPos.length),
        scope.bitmapSize.height - crosshairYPosition
      );
      ctx.globalAlpha = 1;
      ctx.globalCompositeOperation = "source-over";
    });
  }
}
class CrosshairHighlightPaneView {
  _data;
  _options;
  constructor(data, options) {
    this._data = data;
    this._options = options;
  }
  update(data, options) {
    this._data = data;
    this._options = options;
  }
  renderer() {
    return new CrosshairHighlightPaneRenderer({ ...this._data, ...this._options });
  }
}
export class CrosshairHighlightPrimitive {
  _options;
  _paneViews;
  _data = {
    x: 0,
    visible: false,
    barSpacing: 6
  };
  _attachedParams;
  constructor(options) {
    this._options = {
      ...options
    };
    this._paneViews = [new CrosshairHighlightPaneView(this._data, this._options)];
  }
  attached(param) {
    this._attachedParams = param;
    this._setCrosshairMode();
    param.chart.subscribeCrosshairMove(this._moveHandler);
  }
  detached() {
    const chart = this.chart();
    if (chart) {
      chart.unsubscribeCrosshairMove(this._moveHandler);
    }
  }
  paneViews() {
    return this._paneViews;
  }
  updateAllViews() {
    this._paneViews.forEach((pw) => pw.update(this._data, this._options));
  }
  setData(data) {
    this._data = data;
    this._attachedParams?.requestUpdate();
    this.updateAllViews();
  }
  applyOptions(options) {
    this._options = {
      ...this._options,
      ...options
    };
    this.updateAllViews();
  }
  chart() {
    return this._attachedParams?.chart;
  }
  // We need to disable magnet mode for this to work nicely
  _setCrosshairMode() {
    const chart = this.chart();
    if (!chart) {
      throw new Error("Unable to change crosshair mode because the chart instance is undefined");
    }
    chart.applyOptions({
      crosshair: {
        mode: CrosshairMode.Normal,
        vertLine: {
          visible: false,
          labelVisible: false
        }
      }
    });
  }
  _moveHandler = (param) => this._onMouseMove(param);
  _barSpacing() {
    const chart = this.chart();
    if (!chart) {
      return 6;
    }
    const ts = chart.timeScale();
    const visibleLogicalRange = ts.getVisibleLogicalRange();
    if (!visibleLogicalRange) {
      return 6;
    }
    return ts.width() / (visibleLogicalRange.to + 1 - visibleLogicalRange.from);
  }
  _onMouseMove(param) {
    const chart = this.chart();
    const logical = param.logical;
    if (logical === null || logical === void 0 || !chart) {
      this.setData({
        x: 0,
        visible: false,
        barSpacing: this._barSpacing()
      });
      return;
    }
    const coordinate = chart.timeScale().logicalToCoordinate(logical);
    this.setData({
      x: coordinate ?? 0,
      visible: coordinate !== null,
      barSpacing: this._barSpacing()
    });
  }
}
