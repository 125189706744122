import JSBI from "jsbi";
import { logger } from "utilities/src/logger/logger";
export function convertScientificNotationToNumber(value) {
  let convertedValue = value;
  if (value.includes("e") && !value.startsWith("0x")) {
    const [xStr, eStr] = value.split("e");
    let x = Number(xStr);
    let e = Number(eStr);
    if (xStr?.includes(".")) {
      const splitX = xStr.split(".");
      const decimalPlaces = splitX[1]?.split("").length ?? 0;
      e -= decimalPlaces;
      x *= Math.pow(10, decimalPlaces);
    }
    try {
      convertedValue = JSBI.multiply(JSBI.BigInt(x), JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(e))).toString();
    } catch (error) {
      logger.debug(
        "convertScientificNotation",
        "convertScientificNotationToNumber",
        "BigInt arithmetic unsuccessful",
        e
      );
      convertedValue = (x * Math.pow(10, e)).toString();
    }
  }
  return convertedValue;
}
