"use strict";
import { isAddress } from "utilities/src/addresses";
export class TokenFromList {
  isNative = false;
  isToken = true;
  list;
  tokenInfo;
  _checksummedAddress;
  constructor(tokenInfo, list) {
    this.tokenInfo = tokenInfo;
    this.list = list;
    const checksummedAddress = isAddress(this.tokenInfo.address);
    if (!checksummedAddress) {
      throw new Error(`Invalid token address: ${this.tokenInfo.address}`);
    }
    this._checksummedAddress = checksummedAddress;
  }
  get address() {
    return this._checksummedAddress;
  }
  get chainId() {
    return this.tokenInfo.chainId;
  }
  get decimals() {
    return this.tokenInfo.decimals;
  }
  get name() {
    return this.tokenInfo.name;
  }
  get symbol() {
    return this.tokenInfo.symbol;
  }
  get logoURI() {
    return this.tokenInfo.logoURI;
  }
  _tags = null;
  get tags() {
    if (this._tags !== null) {
      return this._tags;
    }
    if (!this.tokenInfo.tags) {
      return this._tags = [];
    }
    const listTags = this.list?.tags;
    if (!listTags) {
      return this._tags = [];
    }
    return this._tags = this.tokenInfo.tags.map((tagId) => {
      return {
        ...listTags[tagId],
        id: tagId
      };
    });
  }
  equals(other) {
    return other.chainId === this.chainId && other.isToken && other.address.toLowerCase() === this.address.toLowerCase();
  }
  sortsBefore(other) {
    if (this.equals(other)) {
      throw new Error("Addresses should not be equal");
    }
    return this.address.toLowerCase() < other.address.toLowerCase();
  }
  get wrapped() {
    return this;
  }
}
