"use strict";
import { useENSRegistrarContract, useENSResolverContract } from "hooks/useContract";
import useDebounce from "hooks/useDebounce";
import { NEVER_RELOAD, useMainnetSingleCallResult } from "lib/hooks/multicall";
import { useMemo } from "react";
import isZero from "utils/isZero";
import { safeNamehash } from "utils/safeNamehash";
export default function useENSAddress(ensName) {
  const debouncedName = useDebounce(ensName, 200);
  const ensNodeArgument = useMemo(() => [debouncedName ? safeNamehash(debouncedName) : void 0], [debouncedName]);
  const registrarContract = useENSRegistrarContract();
  const resolverAddressCall = useMainnetSingleCallResult(registrarContract, "resolver", ensNodeArgument, NEVER_RELOAD);
  const resolverAddress = resolverAddressCall.result?.[0];
  const resolverContract = useENSResolverContract(
    resolverAddress && !isZero(resolverAddress) ? resolverAddress : void 0
  );
  const addressCall = useMainnetSingleCallResult(resolverContract, "addr", ensNodeArgument, NEVER_RELOAD);
  const address = addressCall.result?.[0];
  const changed = debouncedName !== ensName;
  return useMemo(
    () => ({
      address: changed ? null : address ?? null,
      loading: changed || resolverAddressCall.loading || addressCall.loading
    }),
    [addressCall.loading, address, changed, resolverAddressCall.loading]
  );
}
