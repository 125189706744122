export function truncateToMaxDecimals({
  value,
  maxDecimals,
  decimalSeparator = "."
}) {
  const [beforeDecimalSeparator, afterDecimalSeparator] = value.split(decimalSeparator);
  if (afterDecimalSeparator === void 0) {
    return value;
  }
  return `${beforeDecimalSeparator}.${afterDecimalSeparator.substring(0, maxDecimals)}`;
}
export function maxDecimalsReached({
  value,
  maxDecimals,
  decimalSeparator = "."
}) {
  const numberOfDecimals = value.split(decimalSeparator)[1]?.length ?? 0;
  return numberOfDecimals >= maxDecimals;
}
