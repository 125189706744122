"use strict";
import { InterfaceEventName, WalletConnectionResult } from "@uniswap/analytics-events";
import { useAccountDrawer } from "components/AccountDrawer/MiniPortfolio/hooks";
import { walletTypeToAmplitudeWalletType } from "components/Web3Provider/walletConnect";
import { useDisconnect } from "hooks/useDisconnect";
import { createContext, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { sendAnalyticsEvent } from "uniswap/src/features/telemetry/send";
import { logger } from "utilities/src/logger/logger";
import { getCurrentPageFromLocation } from "utils/urlRoutes";
import { UserRejectedRequestError } from "viem";
import { useConnect as useConnectWagmi } from "wagmi";
const ConnectionContext = createContext(void 0);
export function ConnectionProvider({ children }) {
  const { pathname } = useLocation();
  const accountDrawer = useAccountDrawer();
  const { disconnect } = useDisconnect();
  const connection = useConnectWagmi({
    mutation: {
      onMutate({ connector }) {
        logger.debug("useConnect", "ConnectionProvider", `Connection activating: ${connector.name}`);
      },
      onSuccess(_, { connector }) {
        logger.debug("useConnect", "ConnectionProvider", `Connection activated: ${connector.name}`);
        accountDrawer.close();
      },
      onError(error, { connector }) {
        if (error instanceof UserRejectedRequestError) {
          connection.reset();
          return;
        }
        logger.warn("useConnect", "ConnectionProvider", `Connection failed: ${connector.name}`);
        sendAnalyticsEvent(InterfaceEventName.WALLET_CONNECTED, {
          result: WalletConnectionResult.FAILED,
          wallet_name: connector.name,
          wallet_type: walletTypeToAmplitudeWalletType("type" in connector ? connector.type : void 0),
          page: getCurrentPageFromLocation(pathname),
          error: error.message
        });
      }
    }
  });
  useEffect(() => {
    if (!accountDrawer.isOpen && connection.isPending) {
      connection.reset();
      disconnect();
    }
  }, [connection, accountDrawer.isOpen, disconnect]);
  return <ConnectionContext.Provider value={connection}>{children}</ConnectionContext.Provider>;
}
export function useConnect() {
  const value = useContext(ConnectionContext);
  if (!value) {
    throw new Error("useConnect must be used within a ConnectionProvider");
  }
  return value;
}
