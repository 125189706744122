"use strict";
import { NftStandard } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
export const useWalletCollections = create()(
  devtools(
    (set) => ({
      walletAssets: [],
      walletCollections: [],
      displayAssets: [],
      collectionFilters: [],
      listFilter: "All",
      setWalletAssets: (assets) => set(() => {
        return {
          walletAssets: assets?.filter((asset) => asset.asset_contract?.tokenType === NftStandard.Erc721)
        };
      }),
      setWalletCollections: (collections) => set(() => {
        return { walletCollections: collections };
      }),
      setCollectionFilters: (address) => set(({ collectionFilters }) => {
        if (collectionFilters.length === 0) {
          return { collectionFilters: [address] };
        } else if (collectionFilters.some((x) => x === address)) {
          return { collectionFilters: collectionFilters.filter((n) => n !== address) };
        } else {
          return { collectionFilters: [...collectionFilters, address] };
        }
      }),
      clearCollectionFilters: () => set(() => {
        return { collectionFilters: [] };
      }),
      setListFilter: (value) => set(() => {
        return { listFilter: value };
      }),
      setDisplayAssets: (walletAssets, listFilter) => set(() => {
        return { displayAssets: filterWalletAssets(walletAssets, listFilter) };
      })
    }),
    { name: "useWalletCollections" }
  )
);
const filterWalletAssets = (walletAssets, listFilter) => {
  let displayAssets = walletAssets;
  if (listFilter === "Listed") {
    displayAssets = displayAssets?.filter((x) => {
      return x.listing_date !== null;
    });
  }
  if (listFilter === "Unlisted") {
    displayAssets = displayAssets?.filter((x) => {
      return x.listing_date === null;
    });
  }
  return displayAssets;
};
