"use strict";
import clsx from "clsx";
import * as resetStyles from "nft/css/reset.css";
import { sprinkles } from "nft/css/sprinkles.css";
export const atoms = ({ reset, ...rest }) => {
  if (!reset) {
    return sprinkles(rest);
  }
  const elementReset = resetStyles.element[reset];
  const sprinklesClasses = sprinkles(rest);
  return clsx(resetStyles.base, elementReset, sprinklesClasses);
};
