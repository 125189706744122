"use strict";
import "zone.js";
import * as Sentry from "@sentry/react";
Zone[Zone.__symbol__("ignoreConsoleErrorUncaughtError")] = true;
async function sentryAdaptor(context, callback, span) {
  const start = performance.now();
  let isStatusSet = false;
  const callbackContext = {
    child(context2, callback2) {
      const { name, op, tags, data } = context2;
      const sentryContext = { name, description: name, op, tags, data };
      return sentryAdaptor(context2, callback2, span?.startChild(sentryContext));
    },
    setData(key, value) {
      span?.setData(key, value);
    },
    setHttpStatus(status) {
      span?.setHttpStatus(status);
    },
    setStatus(status) {
      span?.setStatus(status);
      isStatusSet = true;
    },
    setError(error, status) {
      span?.setData("error", error);
      if (!isStatusSet && !status) {
        status = "internal_error";
      }
      if (status) {
        span?.setStatus(status);
      }
    },
    now() {
      return performance.now() - start;
    }
  };
  try {
    if (span) {
      const name = span.description ?? span.spanId;
      return await Zone.current.fork({ name, properties: { trace: callbackContext.child } }).run(() => callback(callbackContext));
    } else {
      return await callback(callbackContext);
    }
  } catch (error) {
    if (!span?.status) {
      span?.setStatus("unknown_error");
    }
    if (!span?.data.error) {
      span?.setData("error", error);
    }
    throw error;
  } finally {
    if (!context.op.startsWith("http")) {
      performance.measure(context.op, { start });
    }
    if (!span?.status) {
      span?.setStatus("ok");
    }
    span?.finish();
  }
}
export function isTracing() {
  const parentTrace = Zone.current.get("trace");
  return !!parentTrace;
}
export async function trace(context, callback) {
  const parentTrace = Zone.current.get("trace");
  if (parentTrace) {
    return parentTrace?.(context, callback);
  } else {
    const { name, op, tags, data } = context;
    const sentryContext = { name, description: name, op, tags, data };
    const span = Sentry.startInactiveSpan(sentryContext);
    return sentryAdaptor(context, callback, span);
  }
}
