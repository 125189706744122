import { logger } from "utilities/src/logger/logger";
import { isInterface } from "utilities/src/platform";
import { analytics } from "utilities/src/telemetry/analytics/analytics";
export function getEventHandlers(child, consumedProps, triggers, eventName, element, properties) {
  const eventHandlers = {};
  for (const event of triggers) {
    eventHandlers[event] = (eventHandlerArgs) => {
      if (!child.props[event] && !isInterface) {
        logger.info("trace/utils.ts", "getEventHandlers", "Found a null handler while logging an event", {
          eventName,
          ...consumedProps,
          ...properties
        });
      }
      child.props[event]?.apply(child, [eventHandlerArgs]);
      analytics.sendEvent(eventName, {
        element,
        ...consumedProps,
        ...properties
      });
    };
  }
  return eventHandlers;
}
