"use strict";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { TradeType } from "@uniswap/sdk-core";
import useIsWindowVisible from "hooks/useIsWindowVisible";
import { useRoutingAPIArguments } from "lib/hooks/routing/useRoutingAPIArguments";
import ms from "ms";
import { useMemo } from "react";
import { useGetQuoteQuery, useGetQuoteQueryState } from "state/routing/slice";
import {
  INTERNAL_ROUTER_PREFERENCE_PRICE,
  QuoteState,
  TradeState
} from "state/routing/types";
import { AVERAGE_L1_BLOCK_TIME_MS } from "uniswap/src/features/transactions/swap/hooks/usePollingIntervalByChain";
const TRADE_NOT_FOUND = { state: TradeState.NO_ROUTE_FOUND, trade: void 0, currentData: void 0 };
const TRADE_LOADING = { state: TradeState.LOADING, trade: void 0, currentData: void 0 };
export function useRoutingAPITrade(skipFetch = false, tradeType, amountSpecified, otherCurrency, routerPreference, account, protocolPreferences) {
  const [currencyIn, currencyOut] = useMemo(
    () => tradeType === TradeType.EXACT_INPUT ? [amountSpecified?.currency, otherCurrency] : [otherCurrency, amountSpecified?.currency],
    [amountSpecified, otherCurrency, tradeType]
  );
  const queryArgs = useRoutingAPIArguments({
    account,
    tokenIn: currencyIn,
    tokenOut: currencyOut,
    amount: amountSpecified,
    tradeType,
    routerPreference,
    protocolPreferences
  });
  const isWindowVisible = useIsWindowVisible();
  const { isError, data: tradeResult, error, currentData } = useGetQuoteQueryState(queryArgs);
  useGetQuoteQuery(skipFetch || !isWindowVisible ? skipToken : queryArgs, {
    // Price-fetching is informational and costly, so it's done less frequently.
    pollingInterval: routerPreference === INTERNAL_ROUTER_PREFERENCE_PRICE ? ms(`1m`) : AVERAGE_L1_BLOCK_TIME_MS,
    // If latest quote from cache was fetched > 2m ago, instantly repoll for another instead of waiting for next poll period
    refetchOnMountOrArgChange: 2 * 60
  });
  const isFetching = currentData !== tradeResult || !currentData;
  return useMemo(() => {
    if (amountSpecified && otherCurrency && queryArgs === skipToken) {
      return {
        state: TradeState.STALE,
        trade: tradeResult?.trade,
        currentTrade: currentData?.trade,
        swapQuoteLatency: tradeResult?.latencyMs
      };
    } else if (!amountSpecified || isError || queryArgs === skipToken) {
      return {
        state: TradeState.INVALID,
        trade: void 0,
        currentTrade: currentData?.trade,
        error: JSON.stringify(error)
      };
    } else if (tradeResult?.state === QuoteState.NOT_FOUND && !isFetching) {
      return TRADE_NOT_FOUND;
    } else if (!tradeResult?.trade) {
      return TRADE_LOADING;
    } else {
      return {
        state: isFetching ? TradeState.LOADING : TradeState.VALID,
        trade: tradeResult?.trade,
        currentTrade: currentData?.trade,
        swapQuoteLatency: tradeResult?.latencyMs
      };
    }
  }, [
    amountSpecified,
    error,
    isError,
    isFetching,
    queryArgs,
    tradeResult?.latencyMs,
    tradeResult?.state,
    tradeResult?.trade,
    currentData?.trade,
    otherCurrency
  ]);
}
