"use strict";
import copy from "copy-to-clipboard";
import { useCallback, useEffect, useState } from "react";
export default function useCopyClipboard(timeout = 500) {
  const [isCopied, setIsCopied] = useState(false);
  const staticCopy = useCallback((text) => {
    const didCopy = copy(text);
    setIsCopied(didCopy);
  }, []);
  useEffect(() => {
    if (isCopied) {
      const hide = setTimeout(() => {
        setIsCopied(false);
      }, timeout);
      return () => {
        clearTimeout(hide);
      };
    }
    return void 0;
  }, [isCopied, setIsCopied, timeout]);
  return [isCopied, staticCopy];
}
