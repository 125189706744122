"use strict";
import { useLocalActivities } from "components/AccountDrawer/MiniPortfolio/Activity/parseLocal";
import { parseRemoteActivities } from "components/AccountDrawer/MiniPortfolio/Activity/parseRemote";
import { useCreateCancelTransactionRequest } from "components/AccountDrawer/MiniPortfolio/Activity/utils";
import { useAssetActivity } from "graphql/data/apollo/AssetActivityProvider";
import { GasSpeed, useTransactionGasFee } from "hooks/useTransactionGasFee";
import { useEffect, useMemo } from "react";
import { usePendingOrders } from "state/signatures/hooks";
import { SignatureType } from "state/signatures/types";
import { usePendingTransactions, useTransactionCanceller } from "state/transactions/hooks";
import { TransactionStatus } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { useFormatter } from "utils/formatNumbers";
function findCancelTx(localActivity, remoteMap, account) {
  if (!localActivity.nonce || localActivity.status !== TransactionStatus.Pending) {
    return void 0;
  }
  for (const remoteTx of Object.values(remoteMap)) {
    if (!remoteTx) {
      continue;
    }
    if (remoteTx.nonce === localActivity.nonce && remoteTx.from.toLowerCase() === account.toLowerCase() && remoteTx.hash.toLowerCase() !== localActivity.hash.toLowerCase() && remoteTx.chainId === localActivity.chainId) {
      return remoteTx.hash;
    }
  }
  return void 0;
}
function combineActivities(localMap = {}, remoteMap = {}) {
  const txHashes = [.../* @__PURE__ */ new Set([...Object.keys(localMap), ...Object.keys(remoteMap)])];
  return txHashes.reduce((acc, hash) => {
    const localActivity = localMap?.[hash] ?? {};
    const remoteActivity = remoteMap?.[hash] ?? {};
    if (localActivity.cancelled) {
      if (remoteActivity.chainId && localActivity.chainId !== remoteActivity.chainId) {
        acc.push(remoteActivity);
        return acc;
      }
      acc.push(localActivity);
    } else {
      acc.push({ ...localActivity, ...remoteActivity });
    }
    return acc;
  }, []);
}
export function useAllActivities(account) {
  const { formatNumberOrString } = useFormatter();
  const { activities, loading } = useAssetActivity();
  const localMap = useLocalActivities(account);
  const remoteMap = useMemo(
    () => parseRemoteActivities(activities, account, formatNumberOrString),
    [account, activities, formatNumberOrString]
  );
  const updateCancelledTx = useTransactionCanceller();
  useEffect(() => {
    if (!remoteMap) {
      return;
    }
    Object.values(localMap).forEach((localActivity) => {
      if (!localActivity) {
        return;
      }
      const cancelHash = findCancelTx(localActivity, remoteMap, account);
      if (cancelHash) {
        updateCancelledTx(localActivity.hash, localActivity.chainId, cancelHash);
      }
    });
  }, [account, localMap, remoteMap, updateCancelledTx]);
  const combinedActivities = useMemo(() => combineActivities(localMap, remoteMap ?? {}), [localMap, remoteMap]);
  return { loading, activities: combinedActivities };
}
export function useOpenLimitOrders(account) {
  const { activities, loading } = useAllActivities(account);
  const openLimitOrders = activities?.filter(
    (activity) => activity.offchainOrderDetails?.type === SignatureType.SIGN_LIMIT && activity.status === TransactionStatus.Pending
  ) ?? [];
  return {
    openLimitOrders,
    loading
  };
}
export function usePendingActivity() {
  const pendingTransactions = usePendingTransactions();
  const pendingOrders = usePendingOrders();
  const pendingOrdersWithoutLimits = pendingOrders.filter((order) => order.type !== SignatureType.SIGN_LIMIT);
  const hasPendingActivity = pendingTransactions.length > 0 || pendingOrdersWithoutLimits.length > 0;
  const pendingActivityCount = pendingTransactions.length + pendingOrdersWithoutLimits.length;
  return { hasPendingActivity, pendingActivityCount };
}
export function useCancelOrdersGasEstimate(orders) {
  const cancelTransactionParams = useMemo(
    () => orders && orders.length > 0 ? {
      orders: orders.map((order) => {
        return {
          encodedOrder: order.encodedOrder,
          type: order.type
        };
      }),
      chainId: orders[0].chainId
    } : void 0,
    [orders]
  );
  const cancelTransaction = useCreateCancelTransactionRequest(cancelTransactionParams);
  const gasEstimate = useTransactionGasFee(cancelTransaction, GasSpeed.Fast);
  return gasEstimate;
}
