"use strict";
import { ReactComponent as WinterUni } from "assets/svg/winter-uni.svg";
import { t } from "uniswap/src/i18n";
const MONTH_TO_HOLIDAY_UNI = {
  "12": (props) => <WinterUni title={t("common.happyHolidays")} {...props} />,
  "1": (props) => <WinterUni {...props} />
};
export default function HolidayUniIcon(props) {
  const currentMonth = `${(/* @__PURE__ */ new Date()).getMonth() + 1}`;
  const HolidayUni = MONTH_TO_HOLIDAY_UNI[currentMonth];
  return HolidayUni ? <HolidayUni {...props} /> : null;
}
