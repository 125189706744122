"use strict";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { addConnectedWallet } from "state/wallets/reducer";
export function useConnectedWallets() {
  const dispatch = useAppDispatch();
  const connectedWallets = useAppSelector((state) => state.wallets.connectedWallets);
  const addWallet = useCallback(
    (wallet) => {
      dispatch(addConnectedWallet(wallet));
    },
    [dispatch]
  );
  return [connectedWallets, addWallet];
}
