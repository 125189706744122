"use strict";
import { createAction } from "@reduxjs/toolkit";
export var Field = /* @__PURE__ */ ((Field2) => {
  Field2["LIQUIDITY_PERCENT"] = "LIQUIDITY_PERCENT";
  Field2["LIQUIDITY"] = "LIQUIDITY";
  Field2["CURRENCY_A"] = "CURRENCY_A";
  Field2["CURRENCY_B"] = "CURRENCY_B";
  return Field2;
})(Field || {});
export const typeInput = createAction("burn/typeInputBurn");
