"use strict";
import { Interface } from "@ethersproject/abi";
import { V3_CORE_FACTORY_ADDRESSES } from "@uniswap/sdk-core";
import IUniswapV3PoolStateJSON from "@uniswap/v3-core/artifacts/contracts/interfaces/pool/IUniswapV3PoolState.sol/IUniswapV3PoolState.json";
import { Pool, computePoolAddress } from "@uniswap/v3-sdk";
import { useContractMultichain } from "components/AccountDrawer/MiniPortfolio/Pools/hooks";
import { useAccount } from "hooks/useAccount";
import JSBI from "jsbi";
import { useMultipleContractSingleData } from "lib/hooks/multicall";
import { useEffect, useMemo, useRef } from "react";
import { getChainInfo } from "uniswap/src/features/chains/chainInfo";
import { logger } from "utilities/src/logger/logger";
const POOL_STATE_INTERFACE = new Interface(IUniswapV3PoolStateJSON.abi);
export class PoolCache {
  // Evict after 128 entries. Empirically, a swap uses 64 entries.
  static MAX_ENTRIES = 128;
  // These are FIFOs, using unshift/pop. This makes recent entries faster to find.
  static pools = [];
  static addresses = [];
  static getPoolAddress(factoryAddress, tokenA, tokenB, fee, chainId) {
    if (this.addresses.length > this.MAX_ENTRIES) {
      this.addresses = this.addresses.slice(0, this.MAX_ENTRIES / 2);
    }
    const { address: addressA } = tokenA;
    const { address: addressB } = tokenB;
    const key = `${factoryAddress}:${addressA}:${addressB}:${fee.toString()}`;
    const found = this.addresses.find((address2) => address2.key === key);
    if (found) {
      return found.address;
    }
    const address = {
      key,
      address: computePoolAddress({
        factoryAddress,
        tokenA,
        tokenB,
        fee,
        chainId: getChainInfo(chainId).sdkId
      })
    };
    this.addresses.unshift(address);
    return address.address;
  }
  static getPool(tokenA, tokenB, fee, sqrtPriceX96, liquidity, tick) {
    if (this.pools.length > this.MAX_ENTRIES) {
      this.pools = this.pools.slice(0, this.MAX_ENTRIES / 2);
    }
    const found = this.pools.find(
      (pool2) => pool2.token0 === tokenA && pool2.token1 === tokenB && pool2.fee === fee && JSBI.EQ(pool2.sqrtRatioX96, sqrtPriceX96) && JSBI.EQ(pool2.liquidity, liquidity) && pool2.tickCurrent === tick
    );
    if (found) {
      return found;
    }
    const pool = new Pool(tokenA, tokenB, fee, sqrtPriceX96, liquidity, tick);
    this.pools.unshift(pool);
    return pool;
  }
}
export var PoolState = /* @__PURE__ */ ((PoolState2) => {
  PoolState2[PoolState2["LOADING"] = 0] = "LOADING";
  PoolState2[PoolState2["NOT_EXISTS"] = 1] = "NOT_EXISTS";
  PoolState2[PoolState2["EXISTS"] = 2] = "EXISTS";
  PoolState2[PoolState2["INVALID"] = 3] = "INVALID";
  return PoolState2;
})(PoolState || {});
export function usePools(poolKeys) {
  const { chainId } = useAccount();
  const poolTokens = useMemo(() => {
    if (!chainId) {
      return new Array(poolKeys.length);
    }
    return poolKeys.map(([currencyA, currencyB, feeAmount]) => {
      if (currencyA && currencyB && feeAmount) {
        const tokenA = currencyA.wrapped;
        const tokenB = currencyB.wrapped;
        if (tokenA.equals(tokenB)) {
          return void 0;
        }
        return tokenA.sortsBefore(tokenB) ? [tokenA, tokenB, feeAmount] : [tokenB, tokenA, feeAmount];
      }
      return void 0;
    });
  }, [chainId, poolKeys]);
  const poolAddresses = useMemo(() => {
    const v3CoreFactoryAddress = chainId && V3_CORE_FACTORY_ADDRESSES[chainId];
    if (!v3CoreFactoryAddress) {
      return new Array(poolTokens.length);
    }
    return poolTokens.map((value) => value && PoolCache.getPoolAddress(v3CoreFactoryAddress, ...value, chainId));
  }, [chainId, poolTokens]);
  const slot0s = useMultipleContractSingleData(poolAddresses, POOL_STATE_INTERFACE, "slot0");
  const liquidities = useMultipleContractSingleData(poolAddresses, POOL_STATE_INTERFACE, "liquidity");
  return useMemo(() => {
    return poolKeys.map((_key, index) => {
      const tokens = poolTokens[index];
      if (!tokens) {
        return [3 /* INVALID */, null];
      }
      const [token0, token1, fee] = tokens;
      if (!slot0s[index]) {
        return [3 /* INVALID */, null];
      }
      const { result: slot0, loading: slot0Loading, valid: slot0Valid } = slot0s[index];
      if (!liquidities[index]) {
        return [3 /* INVALID */, null];
      }
      const { result: liquidity, loading: liquidityLoading, valid: liquidityValid } = liquidities[index];
      if (!tokens || !slot0Valid || !liquidityValid) {
        return [3 /* INVALID */, null];
      }
      if (slot0Loading || liquidityLoading) {
        return [0 /* LOADING */, null];
      }
      if (!slot0 || !liquidity) {
        return [1 /* NOT_EXISTS */, null];
      }
      if (!slot0.sqrtPriceX96 || slot0.sqrtPriceX96.eq(0)) {
        return [1 /* NOT_EXISTS */, null];
      }
      try {
        const pool = PoolCache.getPool(token0, token1, fee, slot0.sqrtPriceX96, liquidity[0], slot0.tick);
        return [2 /* EXISTS */, pool];
      } catch (error) {
        logger.error(error, {
          tags: {
            file: "usePools",
            function: "usePools"
          },
          extra: {
            token0: token0.address,
            token1: token1.address,
            chainId: token0.chainId,
            fee
          }
        });
        return [1 /* NOT_EXISTS */, null];
      }
    });
  }, [liquidities, poolKeys, slot0s, poolTokens]);
}
export function usePool(currencyA, currencyB, feeAmount) {
  const poolKeys = useMemo(
    () => [[currencyA, currencyB, feeAmount]],
    [currencyA, currencyB, feeAmount]
  );
  return usePools(poolKeys)[0];
}
export function usePoolMultichain(tokenA, tokenB, fee, chainId) {
  const poolData = useRef([0 /* LOADING */, null]);
  const poolAddress = tokenA && tokenB && fee ? PoolCache.getPoolAddress(V3_CORE_FACTORY_ADDRESSES[chainId], tokenA, tokenB, fee, chainId) : void 0;
  const contractMap = useMemo(() => poolAddress ? { [chainId]: poolAddress } : {}, [chainId, poolAddress]);
  const contract = useContractMultichain(contractMap, IUniswapV3PoolStateJSON.abi)[chainId];
  useEffect(() => {
    async function getPool() {
      try {
        if (!tokenA || !tokenB || !fee || !poolAddress || !contract) {
          poolData.current = [3 /* INVALID */, null];
          return;
        }
        const slot0 = await contract.slot0();
        const liquidity = await contract.liquidity();
        poolData.current = [1 /* NOT_EXISTS */, null];
        const pool = new Pool(tokenA, tokenB, fee, slot0.sqrtPriceX96.toString(), liquidity.toString(), slot0.tick);
        poolData.current = [2 /* EXISTS */, pool];
      } catch (e) {
        poolData.current = [3 /* INVALID */, null];
      }
    }
    getPool();
  }, [contract, fee, poolAddress, tokenA, tokenB]);
  return poolData.current;
}
