"use strict";
import UniswapXRouterLabel from "components/RouterLabel/UniswapXRouterLabel";
import { ThemedText } from "theme/components";
import { Trans } from "uniswap/src/i18n";
export default function UniswapXBrandMark({ fontWeight, ...props }) {
  return <UniswapXRouterLabel {...props}><ThemedText.BodySecondary
    fontSize="inherit"
    {...fontWeight === "bold" && {
      fontWeight: 535
    }}
  ><Trans i18nKey="common.uniswapX" /></ThemedText.BodySecondary></UniswapXRouterLabel>;
}
