"use strict";
import { CurrencyAmount, Percent, TradeType } from "@uniswap/sdk-core";
import { formatTimestamp } from "components/AccountDrawer/MiniPortfolio/formatTimestamp";
import { LoadingRow } from "components/Loader/styled";
import RouterLabel from "components/RouterLabel";
import { TooltipSize } from "components/Tooltip";
import Row from "components/deprecated/Row";
import { DetailLineItem } from "components/swap/DetailLineItem";
import { GasBreakdownTooltip, UniswapXDescription } from "components/swap/GasBreakdownTooltip";
import GasEstimateTooltip from "components/swap/GasEstimateTooltip";
import { RoutingTooltip, SwapRoute } from "components/swap/SwapRoute";
import TradePrice from "components/swap/TradePrice";
import { useUSDPrice } from "hooks/useUSDPrice";
import styled from "lib/styled-components";
import React, { useEffect, useState } from "react";
import { animated } from "react-spring";
import { TradeFillType } from "state/routing/types";
import { isLimitTrade, isPreviewTrade, isUniswapXTrade, isUniswapXTradeType } from "state/routing/utils";
import { useUserSlippageTolerance } from "state/user/hooks";
import { SlippageTolerance } from "state/user/types";
import { ExternalLink, ThemedText } from "theme/components";
import { chainSupportsGasEstimates } from "uniswap/src/features/chains/utils";
import { Trans, t } from "uniswap/src/i18n";
import { NumberType, useFormatter } from "utils/formatNumbers";
import { getPriceImpactColor } from "utils/prices";
export var SwapLineItemType = /* @__PURE__ */ ((SwapLineItemType2) => {
  SwapLineItemType2[SwapLineItemType2["EXCHANGE_RATE"] = 0] = "EXCHANGE_RATE";
  SwapLineItemType2[SwapLineItemType2["NETWORK_COST"] = 1] = "NETWORK_COST";
  SwapLineItemType2[SwapLineItemType2["INPUT_TOKEN_FEE_ON_TRANSFER"] = 2] = "INPUT_TOKEN_FEE_ON_TRANSFER";
  SwapLineItemType2[SwapLineItemType2["OUTPUT_TOKEN_FEE_ON_TRANSFER"] = 3] = "OUTPUT_TOKEN_FEE_ON_TRANSFER";
  SwapLineItemType2[SwapLineItemType2["PRICE_IMPACT"] = 4] = "PRICE_IMPACT";
  SwapLineItemType2[SwapLineItemType2["MAX_SLIPPAGE"] = 5] = "MAX_SLIPPAGE";
  SwapLineItemType2[SwapLineItemType2["SWAP_FEE"] = 6] = "SWAP_FEE";
  SwapLineItemType2[SwapLineItemType2["MAXIMUM_INPUT"] = 7] = "MAXIMUM_INPUT";
  SwapLineItemType2[SwapLineItemType2["MINIMUM_OUTPUT"] = 8] = "MINIMUM_OUTPUT";
  SwapLineItemType2[SwapLineItemType2["ROUTING_INFO"] = 9] = "ROUTING_INFO";
  SwapLineItemType2[SwapLineItemType2["EXPIRY"] = 10] = "EXPIRY";
  return SwapLineItemType2;
})(SwapLineItemType || {});
const ColorWrapper = styled.span`
  ${({ textColor, theme }) => textColor && `color: ${theme[textColor]};`}
`;
const AutoBadge = styled(ThemedText.LabelMicro).attrs({ fontWeight: 535 })`
  display: flex;
  background: ${({ theme }) => theme.surface3};
  border-radius: 8px;
  color: ${({ theme }) => theme.neutral2};
  height: 20px;
  padding: 0 6px;
  align-items: center;
`;
function BaseTooltipContent({ children, url }) {
  return <>
    {children}
    <br />
    <ExternalLink href={url}><Trans i18nKey="common.button.learn" /></ExternalLink>
  </>;
}
export function FOTTooltipContent() {
  return <BaseTooltipContent url="https://support.uniswap.org/hc/en-us/articles/18673568523789-What-is-a-token-fee-"><Trans i18nKey="swap.tokenOwnFees" /></BaseTooltipContent>;
}
function SwapFeeTooltipContent({ hasFee }) {
  const message = hasFee ? <Trans i18nKey="swap.fees.experience" /> : <Trans i18nKey="swap.fees.noFee" />;
  return <BaseTooltipContent url="https://support.uniswap.org/hc/en-us/articles/20131678274957">{message}</BaseTooltipContent>;
}
export function SlippageTooltipContent() {
  return <BaseTooltipContent url="https://support.uniswap.org/hc/en-us/articles/20131678274957"><Trans i18nKey="swap.slippage.tooltip" /></BaseTooltipContent>;
}
function MinimumOutputTooltipContent({ amount }) {
  const { formatCurrencyAmount } = useFormatter();
  const formattedAmount = formatCurrencyAmount({ amount, type: NumberType.SwapDetailsAmount });
  return <BaseTooltipContent url="https://support.uniswap.org/hc/en-us/articles/8643794102669-Price-Impact-vs-Price-Slippage"><Trans i18nKey="swap.minPriceSlip.revert" values={{ amount: `${formattedAmount} ${amount.currency.symbol}` }} /></BaseTooltipContent>;
}
function Loading({ width = 50 }) {
  return <LoadingRow data-testid="loading-row" height={15} width={width} />;
}
function ColoredPercentRow({ percent }) {
  const { formatPercent } = useFormatter();
  const formattedPercent = formatPercent(percent);
  const positivePercent = percent.lessThan(0) ? percent.multiply(-1) : percent;
  return <ColorWrapper textColor={getPriceImpactColor(positivePercent)}>{formattedPercent}</ColorWrapper>;
}
function CurrencyAmountRow({ amount }) {
  const { formatCurrencyAmount } = useFormatter();
  const formattedAmount = formatCurrencyAmount({ amount, type: NumberType.SwapDetailsAmount });
  return <>{`${formattedAmount} ${amount.currency.symbol}`}</>;
}
function FeeRow({ trade: { swapFee, outputAmount } }) {
  const { formatNumber } = useFormatter();
  const feeCurrencyAmount = CurrencyAmount.fromRawAmount(outputAmount.currency, swapFee?.amount ?? 0);
  const { data: outputFeeFiatValue } = useUSDPrice(feeCurrencyAmount, feeCurrencyAmount?.currency);
  if (outputFeeFiatValue === void 0) {
    return <CurrencyAmountRow amount={feeCurrencyAmount} />;
  }
  return <>{formatNumber({ input: outputFeeFiatValue, type: NumberType.FiatGasPrice })}</>;
}
function useLineItem(props) {
  const { trade, syncing, allowedSlippage, type, priceImpact } = props;
  const { formatPercent } = useFormatter();
  const isAutoSlippage = useUserSlippageTolerance()[0] === SlippageTolerance.Auto;
  const isUniswapX = isUniswapXTrade(trade);
  const isPreview = isPreviewTrade(trade);
  const chainId = trade.inputAmount.currency.chainId;
  const [lastSubmittableFillType, setLastSubmittableFillType] = useState();
  useEffect(() => {
    if (trade.fillType !== TradeFillType.None) {
      setLastSubmittableFillType(trade.fillType);
    }
  }, [trade.fillType]);
  switch (type) {
    case 0 /* EXCHANGE_RATE */:
      return {
        Label: () => isLimitTrade(trade) ? <Trans i18nKey="limits.price.label" /> : <Trans i18nKey="common.rate" />,
        Value: () => <TradePrice price={trade.executionPrice} />,
        TooltipBody: !isPreview ? () => <RoutingTooltip trade={trade} /> : void 0,
        tooltipSize: isUniswapX ? TooltipSize.Small : TooltipSize.Large
      };
    case 1 /* NETWORK_COST */:
      if (!chainSupportsGasEstimates(chainId)) {
        return void 0;
      }
      return {
        Label: () => <Trans i18nKey="common.networkCost" />,
        TooltipBody: () => <GasBreakdownTooltip trade={trade} />,
        Value: () => {
          if (isPreview) {
            return <Loading />;
          }
          return <GasEstimateTooltip trade={trade} loading={!!syncing} />;
        }
      };
    case 4 /* PRICE_IMPACT */:
      if (isUniswapX || !priceImpact || isPreview && isUniswapXTradeType(lastSubmittableFillType)) {
        return void 0;
      }
      return {
        Label: () => <Trans i18nKey="swap.priceImpact" />,
        TooltipBody: () => <Trans i18nKey="swap.impactOfTrade" />,
        Value: () => isPreview ? <Loading /> : <ColoredPercentRow percent={priceImpact} />
      };
    case 5 /* MAX_SLIPPAGE */:
      return {
        Label: () => <Trans i18nKey="settings.maxSlippage" />,
        TooltipBody: () => <SlippageTooltipContent />,
        Value: () => <Row gap="8px">
          {isAutoSlippage && <AutoBadge>{t(`common.automatic`)}</AutoBadge>}
          {" "}
          {formatPercent(allowedSlippage)}
        </Row>
      };
    case 6 /* SWAP_FEE */: {
      if (isPreview) {
        return { Label: () => <Trans i18nKey="common.fee" />, Value: () => <Loading /> };
      }
      return {
        Label: () => <>
          <Trans i18nKey="common.fee" />
          {" "}
          {trade.swapFee && `(${formatPercent(trade.swapFee.percent)})`}
        </>,
        TooltipBody: () => <SwapFeeTooltipContent hasFee={Boolean(trade.swapFee)} />,
        Value: () => <FeeRow trade={trade} />
      };
    }
    case 7 /* MAXIMUM_INPUT */:
      if (trade.tradeType === TradeType.EXACT_INPUT) {
        return void 0;
      }
      return {
        Label: () => <Trans i18nKey="swap.payAtMost" />,
        TooltipBody: () => <Trans i18nKey="swap.maxPriceSlip.revert" />,
        Value: () => <CurrencyAmountRow amount={trade.maximumAmountIn(allowedSlippage ?? new Percent(0))} />,
        loaderWidth: 70
      };
    case 8 /* MINIMUM_OUTPUT */:
      return {
        Label: () => <Trans i18nKey="swap.receive.atLeast" />,
        TooltipBody: () => <MinimumOutputTooltipContent amount={trade.minimumAmountOut(allowedSlippage ?? new Percent(0))} />,
        Value: () => <CurrencyAmountRow amount={trade.minimumAmountOut(allowedSlippage ?? new Percent(0))} />,
        loaderWidth: 70
      };
    case 9 /* ROUTING_INFO */:
      if (isPreview || syncing) {
        return { Label: () => <Trans i18nKey="swap.orderRouting" />, Value: () => <Loading /> };
      }
      return {
        Label: () => <Trans i18nKey="swap.orderRouting" />,
        TooltipBody: () => {
          if (isUniswapX) {
            return <UniswapXDescription />;
          }
          return <SwapRoute data-testid="swap-route-info" trade={trade} />;
        },
        tooltipSize: isUniswapX ? TooltipSize.Small : TooltipSize.Large,
        Value: () => <RouterLabel trade={trade} />
      };
    case 2 /* INPUT_TOKEN_FEE_ON_TRANSFER */:
    case 3 /* OUTPUT_TOKEN_FEE_ON_TRANSFER */:
      return getFOTLineItem(props);
    case 10 /* EXPIRY */:
      if (!isLimitTrade(trade)) {
        return void 0;
      }
      return {
        Label: () => <Trans i18nKey="common.expiry" />,
        Value: () => <Row>{formatTimestamp(trade.deadline, true)}</Row>
      };
  }
}
function getFOTLineItem({ type, trade }) {
  const isInput = type === 2 /* INPUT_TOKEN_FEE_ON_TRANSFER */;
  const currency = isInput ? trade.inputAmount.currency : trade.outputAmount.currency;
  const tax = isInput ? trade.inputTax : trade.outputTax;
  if (tax.equalTo(0)) {
    return void 0;
  }
  const tokenSymbol = currency.symbol ?? currency.name;
  return {
    Label: () => <>{tokenSymbol ? t("swap.details.feeOnTransfer", { tokenSymbol }) : t("swap.details.feeOnTransfer.default")}</>,
    TooltipBody: FOTTooltipContent,
    Value: () => <ColoredPercentRow percent={tax} />
  };
}
function SwapLineItem(props) {
  const LineItem = useLineItem(props);
  if (!LineItem) {
    return null;
  }
  return <animated.div style={{ opacity: props.animatedOpacity }}><DetailLineItem LineItem={LineItem} syncing={props.syncing} /></animated.div>;
}
export default React.memo(SwapLineItem);
