export const isAndroid = false;
export const isIOS = false;
export const isWeb = true;
export const isMobileWeb = (
  // https://stackoverflow.com/a/29509267
  typeof navigator !== "undefined" && /iPhone|iPad|iPod|Android|Mobi/i.test(navigator.userAgent)
);
export const isWebIOS = typeof document !== "undefined" && typeof navigator !== "undefined" && (["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone"].includes(navigator.platform) || // iPad on iOS 13 detection
navigator.userAgent.includes("Mac") && "ontouchend" in document);
export const isWebAndroid = typeof navigator !== "undefined" && navigator.userAgent.toLowerCase().includes("android");
export const isTouchable = typeof window !== "undefined" && typeof navigator !== "undefined" && ("ontouchstart" in window || navigator.maxTouchPoints > 0);
export const isChrome = typeof navigator !== "undefined" && /Chrome/.test(navigator.userAgent || "");
export const isSafari = typeof navigator !== "undefined" && /Safari/.test(navigator.userAgent || "");
export const isMobileWebSafari = isTouchable && isSafari;
export const isMobileWebAndroid = isTouchable && isWebAndroid;
export const isExtension = process.env.IS_UNISWAP_EXTENSION === "true";
export const isMobileApp = false;
export const isInterface = process.env.REACT_APP_IS_UNISWAP_INTERFACE === "true";
