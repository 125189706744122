"use strict";
import { useRecentTokenTransfers } from "graphql/data/RecentTokenTransfers";
import { useMemo } from "react";
export function useGroupedRecentTransfers(account) {
  const { data: recentTransfers, loading } = useRecentTokenTransfers(account);
  return useMemo(
    () => ({
      transfers: recentTransfers?.reduce(
        (acc, transfer) => {
          const address = transfer.recipient;
          if (acc[address]) {
            acc[address]++;
          } else {
            acc[address] = 1;
          }
          return acc;
        },
        {}
      ),
      loading
    }),
    [loading, recentTransfers]
  );
}
