"use strict";
import { TICK_SPACINGS, TickMath, nearestUsableTick } from "@uniswap/v3-sdk";
import { useMemo } from "react";
import { Bound } from "state/mint/v3/actions";
export default function useIsTickAtLimit(feeAmount, tickLower, tickUpper) {
  return useMemo(
    () => ({
      [Bound.LOWER]: feeAmount && tickLower ? tickLower === nearestUsableTick(TickMath.MIN_TICK, TICK_SPACINGS[feeAmount]) : void 0,
      [Bound.UPPER]: feeAmount && tickUpper ? tickUpper === nearestUsableTick(TickMath.MAX_TICK, TICK_SPACINGS[feeAmount]) : void 0
    }),
    [feeAmount, tickLower, tickUpper]
  );
}
