"use strict";
import { atomWithReset, useResetAtom, useUpdateAtom } from "jotai/utils";
import { ProtocolVersion } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { UniverseChainId } from "uniswap/src/features/chains/types";
import { FeatureFlags } from "uniswap/src/features/gating/flags";
import { useFeatureFlag } from "uniswap/src/features/gating/hooks";
export const manualChainOutageAtom = atomWithReset(void 0);
export function useUpdateManualOutage({
  chainId,
  errorV3,
  errorV2
}) {
  const setManualOutage = useUpdateAtom(manualChainOutageAtom);
  const resetManualOutage = useResetAtom(manualChainOutageAtom);
  resetManualOutage();
  if (errorV3 && chainId) {
    setManualOutage({ chainId });
  }
  if (errorV2 && chainId) {
    setManualOutage({ chainId, version: ProtocolVersion.V2 });
  }
}
export function useOutageBanners() {
  return {
    [UniverseChainId.Optimism]: useFeatureFlag(FeatureFlags.OutageBannerOptimism),
    [UniverseChainId.ArbitrumOne]: useFeatureFlag(FeatureFlags.OutageBannerArbitrum),
    [UniverseChainId.Polygon]: useFeatureFlag(FeatureFlags.OutageBannerPolygon)
  };
}
