"use strict";
import { namehash } from "@ethersproject/hash";
import { logger } from "utilities/src/logger/logger";
export function safeNamehash(name) {
  if (name === void 0) {
    return void 0;
  }
  try {
    return namehash(name);
  } catch (error) {
    logger.info("safeNamehash", "safeNamehash", "error", error, { name });
    return void 0;
  }
}
