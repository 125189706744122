export function calculateElapsedTimeWithPerformanceMarkMs(markName, fallbackStartTime) {
  const elapsedTime = performance.mark(markName);
  if (elapsedTime) {
    return elapsedTime.startTime;
  }
  if (fallbackStartTime) {
    return Date.now() - fallbackStartTime;
  }
  return void 0;
}
