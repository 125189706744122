"use strict";
import { useEffect, useState } from "react";
export default function useLast(value, filterFn) {
  const [last, setLast] = useState(value);
  useEffect(() => {
    setLast((last2) => {
      const shouldUse = filterFn ? filterFn(value) : true;
      if (shouldUse) {
        return value;
      }
      return last2;
    });
  }, [filterFn, value]);
  return last;
}
