"use strict";
function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
function waitRandom(min, max) {
  return wait(min + Math.round(Math.random() * Math.max(0, max - min)));
}
export class CanceledError extends Error {
  name = "CanceledError";
  message = "Retryable was canceled";
}
export class RetryableError extends Error {
  name = "RetryableError";
}
export function retry(fn, { n, minWait, maxWait }) {
  let completed = false;
  let rejectCancelled;
  const promise = new Promise(async (resolve, reject) => {
    rejectCancelled = reject;
    while (true) {
      let result;
      try {
        result = await fn();
        if (!completed) {
          resolve(result);
          completed = true;
        }
        break;
      } catch (error) {
        if (completed) {
          break;
        }
        if (n <= 0 || !(error instanceof RetryableError)) {
          reject(error);
          completed = true;
          break;
        }
        n--;
      }
      await waitRandom(minWait, maxWait);
    }
  });
  return {
    promise,
    cancel: () => {
      if (completed) {
        return;
      }
      completed = true;
      rejectCancelled(new CanceledError());
    }
  };
}
