"use strict";
import { SettingsToggle } from "components/AccountDrawer/SettingsToggle";
import { useState } from "react";
import { t } from "uniswap/src/i18n";
import { analytics, getAnalyticsAtomDirect } from "utilities/src/telemetry/analytics/analytics";
export function AnalyticsToggle() {
  const [x, setCounter] = useState(0);
  const [allowAnalytics, setAllowAnalytics] = useState(true);
  getAnalyticsAtomDirect(true).then((v) => setAllowAnalytics(v));
  return <SettingsToggle
    title={t("analytics.allow")}
    description={t("analytics.allow.message")}
    isActive={allowAnalytics}
    toggle={() => {
      analytics.setAllowAnalytics(!allowAnalytics);
      setCounter((c) => c + 1);
    }}
  />;
}
