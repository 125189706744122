"use strict";
export var UniformAspectRatios = /* @__PURE__ */ ((UniformAspectRatios2) => {
  UniformAspectRatios2[UniformAspectRatios2["unset"] = 0] = "unset";
  UniformAspectRatios2[UniformAspectRatios2["square"] = 1] = "square";
  return UniformAspectRatios2;
})(UniformAspectRatios || {});
export var ActivityEventType = /* @__PURE__ */ ((ActivityEventType2) => {
  ActivityEventType2["Listing"] = "LISTING";
  ActivityEventType2["Sale"] = "SALE";
  ActivityEventType2["CancelListing"] = "CANCEL_LISTING";
  ActivityEventType2["Transfer"] = "TRANSFER";
  return ActivityEventType2;
})(ActivityEventType || {});
export var ActivityEventTypeDisplay = /* @__PURE__ */ ((ActivityEventTypeDisplay2) => {
  ActivityEventTypeDisplay2["LISTING"] = "Listed";
  ActivityEventTypeDisplay2["SALE"] = "Sold";
  ActivityEventTypeDisplay2["TRANSFER"] = "Transferred";
  ActivityEventTypeDisplay2["CANCEL_LISTING"] = "Cancellation";
  return ActivityEventTypeDisplay2;
})(ActivityEventTypeDisplay || {});
