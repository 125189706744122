"use strict";
import { NATIVE_CHAIN_ID } from "constants/tokens";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { MAX_RECENT_SEARCH_RESULTS } from "uniswap/src/components/TokenSelector/constants";
import { nativeOnChain } from "uniswap/src/constants/tokens";
import {
  useRecentlySearchedAssetsQuery
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { toGraphQLChain } from "uniswap/src/features/chains/utils";
import {
  SearchResultType,
  isNFTCollectionSearchResult,
  isTokenSearchResult
} from "uniswap/src/features/search/SearchResult";
import { selectSearchHistory } from "uniswap/src/features/search/selectSearchHistory";
import { isNativeCurrencyAddress } from "uniswap/src/utils/currencyId";
export function useRecentlySearchedAssets() {
  const history = useSelector(selectSearchHistory);
  const shortenedHistory = useMemo(
    () => history.filter((item) => item.type !== SearchResultType.NFTCollection).slice(0, MAX_RECENT_SEARCH_RESULTS),
    [history]
  );
  const { data: queryData, loading } = useRecentlySearchedAssetsQuery({
    variables: {
      collectionAddresses: shortenedHistory.filter(isNFTCollectionSearchResult).map((asset) => asset.address),
      contracts: shortenedHistory.filter(isTokenSearchResult).map((token) => ({
        address: token.address ?? void 0,
        chain: toGraphQLChain(token.chainId)
      }))
    },
    skip: shortenedHistory.length === 0
  });
  const data = useMemo(() => {
    if (shortenedHistory.length === 0) {
      return [];
    } else if (!queryData) {
      return void 0;
    }
    const resultsMap = {};
    queryData.tokens?.filter(Boolean).forEach((token) => {
      if (token) {
        resultsMap[token.address ?? getNativeQueryAddress(token.chain)] = token;
      }
    });
    const data2 = [];
    shortenedHistory.forEach((asset) => {
      const result = generateInterfaceHistoryItem(asset, resultsMap);
      if (result) {
        data2.push(result);
      }
    });
    return data2;
  }, [queryData, shortenedHistory]);
  return { data, loading };
}
function generateInterfaceHistoryItem(asset, resultsMap) {
  if (!isTokenSearchResult(asset)) {
    return void 0;
  }
  if (!isNativeCurrencyAddress(asset.chainId, asset.address) && asset.address) {
    return resultsMap[asset.address];
  }
  if (isNativeCurrencyAddress(asset.chainId, asset.address)) {
    const chain = toGraphQLChain(asset.chainId);
    const native = nativeOnChain(asset.chainId);
    const queryAddress = asset.address ?? getNativeQueryAddress(chain);
    const result = resultsMap[queryAddress];
    return { ...result, address: NATIVE_CHAIN_ID, ...native };
  }
  return void 0;
}
function getNativeQueryAddress(chain) {
  return `NATIVE-${chain}`;
}
