"use strict";
import { ChartType } from "components/Charts/utils";
import ms from "ms";
import { HistoryDuration } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
export var DataQuality = /* @__PURE__ */ ((DataQuality2) => {
  DataQuality2[DataQuality2["VALID"] = 0] = "VALID";
  DataQuality2[DataQuality2["INVALID"] = 1] = "INVALID";
  DataQuality2[DataQuality2["STALE"] = 2] = "STALE";
  return DataQuality2;
})(DataQuality || {});
const CONSTANT_STALENESS = {
  [HistoryDuration.Hour]: ms("15m"),
  [HistoryDuration.Day]: ms("15m"),
  [HistoryDuration.Week]: ms("15m"),
  [HistoryDuration.Month]: ms("15m"),
  [HistoryDuration.Year]: ms("15m")
};
const GRANULAR_STALENESS = {
  [HistoryDuration.Hour]: ms("15m"),
  [HistoryDuration.Day]: ms("4h"),
  [HistoryDuration.Week]: ms("1d"),
  [HistoryDuration.Month]: ms("4d"),
  [HistoryDuration.Year]: ms("30d")
};
const CHART_DURATION_STALE_THRESHOLD_MAP = {
  [ChartType.PRICE]: CONSTANT_STALENESS,
  [ChartType.VOLUME]: GRANULAR_STALENESS,
  [ChartType.TVL]: CONSTANT_STALENESS,
  // Liquidity chart does not have a time axis
  [ChartType.LIQUIDITY]: void 0
};
export function checkDataQuality(data, chartType, duration) {
  if (data.length < 3) {
    return 1 /* INVALID */;
  }
  const timeInMs = data[data.length - 1].time * 1e3;
  const stalenessThreshold = CHART_DURATION_STALE_THRESHOLD_MAP[chartType]?.[duration];
  if (!stalenessThreshold || Date.now() - timeInMs < stalenessThreshold) {
    return 0 /* VALID */;
  } else {
    return 2 /* STALE */;
  }
}
export function withUTCTimestamp(entry) {
  return { ...entry, time: entry.timestamp };
}
