"use strict";
import { useEffect } from "react";
import store from "state";
import { useAppDispatch } from "state/hooks";
import { DEFAULT_LOCALE, mapLocaleToLanguage } from "uniswap/src/features/language/constants";
import { getLocale, navigatorLocale, parseLocale, useCurrentLocale } from "uniswap/src/features/language/hooks";
import { setCurrentLanguage } from "uniswap/src/features/settings/slice";
import { changeLanguage } from "uniswap/src/i18n";
function getStoreLocale() {
  const storeLanguage = store.getState().userSettings.currentLanguage;
  return getLocale(storeLanguage);
}
function setupInitialLanguage() {
  const lngQuery = typeof window !== "undefined" ? new URL(window.location.href).searchParams.get("lng") : "";
  const initialLocale = parseLocale(lngQuery) ?? getStoreLocale() ?? navigatorLocale() ?? DEFAULT_LOCALE;
  changeLanguage(initialLocale);
}
if (process.env.NODE_ENV !== "test") {
  setupInitialLanguage();
}
export function LanguageProvider({ children }) {
  const dispatch = useAppDispatch();
  const locale = useCurrentLocale();
  useEffect(() => {
    changeLanguage(locale);
    document.documentElement.setAttribute("lang", locale);
    dispatch(setCurrentLanguage(mapLocaleToLanguage[locale]));
  }, [locale, dispatch]);
  return <>{children}</>;
}
