"use strict";
import { TimePeriod } from "graphql/data/util";
import { atom, useAtom } from "jotai";
import { atomWithReset, useUpdateAtom } from "jotai/utils";
import { useCallback } from "react";
export var TokenSortMethod = /* @__PURE__ */ ((TokenSortMethod2) => {
  TokenSortMethod2["FULLY_DILUTED_VALUATION"] = "FDV";
  TokenSortMethod2["PRICE"] = "Price";
  TokenSortMethod2["VOLUME"] = "Volume";
  TokenSortMethod2["HOUR_CHANGE"] = "1 hour";
  TokenSortMethod2["DAY_CHANGE"] = "1 day";
  return TokenSortMethod2;
})(TokenSortMethod || {});
export const exploreSearchStringAtom = atomWithReset("");
export const filterTimeAtom = atom(TimePeriod.DAY);
export const sortMethodAtom = atom("Volume" /* VOLUME */);
export const sortAscendingAtom = atom(false);
export function useSetSortMethod(newSortMethod) {
  const [sortMethod, setSortMethod] = useAtom(sortMethodAtom);
  const setSortAscending = useUpdateAtom(sortAscendingAtom);
  return useCallback(() => {
    if (sortMethod === newSortMethod) {
      setSortAscending((sortAscending) => !sortAscending);
    } else {
      setSortMethod(newSortMethod);
      setSortAscending(false);
    }
  }, [sortMethod, setSortMethod, setSortAscending, newSortMethod]);
}
