"use strict";
import { useAccount } from "hooks/useAccount";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useEffect } from "react";
import { useUnitagByAddress } from "uniswap/src/features/unitags/hooks";
import { shortenAddress } from "utilities/src/addresses";
import { useAccountEffect, useEnsName } from "wagmi";
const recentAccountIdentifierMapAtom = atomWithStorage("recentAccountIdentifierMap", { recent: {} });
export function useAccountIdentifier() {
  const [recentAccountIdentifierMap, updateRecentAccountIdentifierMap] = useAtom(recentAccountIdentifierMapAtom);
  const account = useAccount();
  const { unitag: unitagResponse } = useUnitagByAddress(account.address);
  const { data: ensNameResponse } = useEnsName({ address: account.address });
  useAccountEffect({
    onDisconnect() {
      updateRecentAccountIdentifierMap((prev) => ({ ...prev, recent: void 0 }));
    }
  });
  useEffect(() => {
    if (!account.address) {
      return;
    }
    updateRecentAccountIdentifierMap((prev) => {
      const updatedIdentifiers = prev[account.address] ?? {};
      if (unitagResponse) {
        updatedIdentifiers.unitag = unitagResponse.username;
      }
      if (ensNameResponse) {
        updatedIdentifiers.ensName = ensNameResponse;
      }
      return { ...prev, [account.address]: updatedIdentifiers, recent: updatedIdentifiers };
    });
  }, [account.address, unitagResponse, ensNameResponse, updateRecentAccountIdentifierMap]);
  const { unitag, ensName } = (account.address ? recentAccountIdentifierMap[account.address] : recentAccountIdentifierMap["recent"]) ?? {};
  const accountIdentifier = unitag ?? ensName ?? shortenAddress(account.address);
  return {
    accountIdentifier,
    hasUnitag: Boolean(unitag),
    hasRecent: Boolean(Object.keys(recentAccountIdentifierMap["recent"] || {}).length)
  };
}
