import { PlainImage } from "ui/src/components/UniversalImage/internal/PlainImage";
import { useSvgData } from "ui/src/components/UniversalImage/utils";
import { Flex } from "ui/src/components/layout/Flex";
export function SvgImage({ uri, size, autoplay, fallback }) {
  const svgData = useSvgData(uri, autoplay);
  if (!svgData?.content || !svgData?.aspectRatio) {
    return fallback ?? <Flex />;
  }
  return <PlainImage fallback={fallback} size={size} uri={uri} />;
}
