"use strict";
import { Area } from "components/LiquidityChartRangeInput/Area";
import { AxisBottom } from "components/LiquidityChartRangeInput/AxisBottom";
import { Brush } from "components/LiquidityChartRangeInput/Brush";
import { Line } from "components/LiquidityChartRangeInput/Line";
import Zoom, { ZoomOverlay } from "components/LiquidityChartRangeInput/Zoom";
import { max, scaleLinear } from "d3";
import { useEffect, useMemo, useRef, useState } from "react";
import { Bound } from "state/mint/v3/actions";
const xAccessor = (d) => d.price0;
const yAccessor = (d) => d.activeLiquidity;
export function Chart({
  id = "liquidityChartRangeInput",
  data: { series, current },
  ticksAtLimit,
  styles,
  dimensions: { width, height },
  margins,
  interactive = true,
  brushDomain,
  brushLabels,
  onBrushDomainChange,
  zoomLevels
}) {
  const zoomRef = useRef(null);
  const [zoom, setZoom] = useState(null);
  const [innerHeight, innerWidth] = useMemo(
    () => [height - margins.top - margins.bottom, width - margins.left - margins.right],
    [width, height, margins]
  );
  const { xScale, yScale } = useMemo(() => {
    const scales = {
      xScale: scaleLinear().domain([current * zoomLevels.initialMin, current * zoomLevels.initialMax]).range([0, innerWidth]),
      yScale: scaleLinear().domain([0, max(series, yAccessor)]).range([innerHeight, 0])
    };
    if (zoom) {
      const newXscale = zoom.rescaleX(scales.xScale);
      scales.xScale.domain(newXscale.domain());
    }
    return scales;
  }, [current, zoomLevels.initialMin, zoomLevels.initialMax, innerWidth, series, innerHeight, zoom]);
  useEffect(() => {
    setZoom(null);
  }, [zoomLevels]);
  useEffect(() => {
    if (!brushDomain) {
      onBrushDomainChange(xScale.domain(), void 0);
    }
  }, [brushDomain, onBrushDomainChange, xScale]);
  return <>
    <Zoom
      svg={zoomRef.current}
      xScale={xScale}
      setZoom={setZoom}
      width={innerWidth}
      height={
        // allow zooming inside the x-axis
        height
      }
      resetBrush={() => {
        onBrushDomainChange(
          [current * zoomLevels.initialMin, current * zoomLevels.initialMax],
          "reset"
        );
      }}
      showResetButton={Boolean(ticksAtLimit[Bound.LOWER] || ticksAtLimit[Bound.UPPER])}
      zoomLevels={zoomLevels}
    />
    <svg width="100%" height="100%" viewBox={`0 0 ${width} ${height}`} style={{ overflow: "visible" }}>
      <defs>
        <clipPath id={`${id}-chart-clip`}><rect x="0" y="0" width={innerWidth} height={height} /></clipPath>
        {brushDomain && // mask to highlight selected area
        <mask id={`${id}-chart-area-mask`}><rect
          fill="white"
          x={xScale(brushDomain[0])}
          y="0"
          width={xScale(brushDomain[1]) - xScale(brushDomain[0])}
          height={innerHeight}
        /></mask>}
      </defs>
      <g transform={`translate(${margins.left},${margins.top})`}>
        <g clipPath={`url(#${id}-chart-clip)`}>
          <Area series={series} xScale={xScale} yScale={yScale} xValue={xAccessor} yValue={yAccessor} />
          {brushDomain && // duplicate area chart with mask for selected area
          <g mask={`url(#${id}-chart-area-mask)`}><Area
            series={series}
            xScale={xScale}
            yScale={yScale}
            xValue={xAccessor}
            yValue={yAccessor}
            fill={styles.area.selection}
          /></g>}
          <Line value={current} xScale={xScale} innerHeight={innerHeight} />
          <AxisBottom xScale={xScale} innerHeight={innerHeight} />
        </g>
        <ZoomOverlay width={innerWidth} height={height} ref={zoomRef} />
        <Brush
          id={id}
          xScale={xScale}
          interactive={interactive}
          brushLabelValue={brushLabels}
          brushExtent={brushDomain ?? xScale.domain()}
          innerWidth={innerWidth}
          innerHeight={innerHeight}
          setBrushExtent={onBrushDomainChange}
          westHandleColor={styles.brush.handle.west}
          eastHandleColor={styles.brush.handle.east}
        />
      </g>
    </svg>
  </>;
}
