/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SwapStatus {
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    NOT_FOUND = 'NOT_FOUND',
    FAILED = 'FAILED',
    EXPIRED = 'EXPIRED',
}
