"use strict";
import { RowBetween } from "components/deprecated/Row";
import styled from "lib/styled-components";
import { darken } from "polished";
import { forwardRef } from "react";
import { ChevronDown } from "react-feather";
import { Button as RebassButton } from "rebass/styled-components";
export { default as LoadingButtonSpinner } from "./LoadingButtonSpinner";
const ButtonOverlay = styled.div`
  background-color: transparent;
  bottom: 0;
  border-radius: inherit;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 150ms ease background-color;
  width: 100%;
`;
export const BaseButton = styled(RebassButton)`
  padding: ${({ padding }) => padding ?? "16px"};
  width: ${({ width }) => width ?? "100%"};
  line-height: 24px;
  font-weight: 535;
  text-align: center;
  border-radius: ${({ $borderRadius }) => $borderRadius ?? "16px"};
  outline: none;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.neutral1};
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    opacity: 50%;
    cursor: auto;
    pointer-events: none;
  }

  will-change: transform;
  transition: transform 450ms ease;
  transform: perspective(1px) translateZ(0);

  > * {
    user-select: none;
  }

  > a {
    text-decoration: none;
  }
`;
export const ButtonPrimary = styled(BaseButton)`
  background-color: ${({ theme }) => theme.accent1};
  font-size: 20px;
  font-weight: 535;
  padding: 16px;
  color: ${({ theme }) => theme.neutralContrast};
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.accent1)};
    background-color: ${({ theme }) => darken(0.05, theme.accent1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.accent1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.accent1)};
    background-color: ${({ theme }) => darken(0.1, theme.accent1)};
  }
  &:disabled {
    background-color: ${({ theme, altDisabledStyle, disabled }) => altDisabledStyle ? disabled ? theme.accent1 : theme.surface3 : theme.surface3};
    color: ${({ altDisabledStyle, disabled, theme }) => altDisabledStyle ? disabled ? theme.neutralContrast : theme.neutral2 : theme.neutral2};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
  }
`;
export const SmallButtonPrimary = styled(ButtonPrimary)`
  width: auto;
  font-size: 16px;
  padding: ${({ padding }) => padding ?? "8px 12px"};

  border-radius: 12px;
`;
const BaseButtonLight = styled(BaseButton)`
  background-color: ${({ theme }) => theme.accent2};
  color: ${({ theme }) => theme.accent1};
  font-size: 20px;
  font-weight: 535;

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && theme.accent2};
    background-color: ${({ theme, disabled }) => !disabled && theme.accent2};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.accent2};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && theme.accent2};
    background-color: ${({ theme, disabled }) => !disabled && theme.accent2};
  }

  :hover {
    ${ButtonOverlay} {
      background-color: ${({ theme }) => theme.deprecated_stateOverlayHover};
    }
  }

  :active {
    ${ButtonOverlay} {
      background-color: ${({ theme }) => theme.deprecated_stateOverlayPressed};
    }
  }

  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: transparent;
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`;
export const ButtonGray = styled(BaseButton)`
  background-color: ${({ theme }) => theme.surface1};
  color: ${({ theme }) => theme.neutral2};
  border: 1px solid ${({ theme }) => theme.surface3};
  font-size: 16px;
  font-weight: 535;

  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.surface2)};
  }
  &:active {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.surface2)};
  }
`;
export const ButtonSecondary = styled(BaseButton)`
  border: 1px solid ${({ theme }) => theme.accent2};
  color: ${({ theme }) => theme.accent1};
  background-color: transparent;
  font-size: 16px;
  border-radius: 12px;
  padding: ${({ padding }) => padding ? padding : "10px"};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.accent2};
    border: 1px solid ${({ theme }) => theme.accent1};
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.accent1};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.accent2};
    border: 1px solid ${({ theme }) => theme.accent1};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  a:hover {
    text-decoration: none;
  }
`;
export const ButtonOutlined = styled(BaseButton)`
  border: 1px solid ${({ theme }) => theme.surface3};
  background-color: transparent;
  color: ${({ theme }) => theme.neutral1};
  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.surface3};
  }
  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.neutral3};
  }
  &:active {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.surface3};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;
export const ButtonEmpty = styled(BaseButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.accent1};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;
export const ButtonText = styled(BaseButton)`
  padding: 0;
  width: fit-content;
  background: none;
  text-decoration: none;
  &:focus {
    text-decoration: underline;
  }
  &:hover {
    opacity: 0.9;
  }
  &:active {
    text-decoration: underline;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`;
const ButtonConfirmedStyle = styled(BaseButton)`
  background-color: ${({ theme }) => theme.surface2};
  color: ${({ theme }) => theme.neutral1};
  /* border: 1px solid ${({ theme }) => theme.success}; */

  &:disabled {
    opacity: 50%;
    background-color: ${({ theme }) => theme.surface3};
    color: ${({ theme }) => theme.neutral2};
    cursor: auto;
  }
`;
const ButtonErrorStyle = styled(BaseButton)`
  background-color: ${({ theme }) => theme.critical};
  border: 1px solid ${({ theme }) => theme.critical};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.critical)};
    background-color: ${({ theme }) => darken(0.05, theme.critical)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.critical)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.critical)};
    background-color: ${({ theme }) => darken(0.1, theme.critical)};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
    box-shadow: none;
    background-color: ${({ theme }) => theme.critical};
    border: 1px solid ${({ theme }) => theme.critical};
  }
`;
export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  ...rest
}) {
  if (confirmed) {
    return <ButtonConfirmedStyle {...rest} />;
  } else {
    return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />;
  }
}
export function ButtonError({ error, ...rest }) {
  if (error) {
    return <ButtonErrorStyle {...rest} />;
  } else {
    return <ButtonPrimary {...rest} />;
  }
}
export function ButtonDropdownLight({ disabled = false, children, ...rest }) {
  return <ButtonOutlined {...rest} disabled={disabled}><RowBetween>
    <div style={{ display: "flex", alignItems: "center" }}>{children}</div>
    <ChevronDown size={24} />
  </RowBetween></ButtonOutlined>;
}
export var ButtonSize = /* @__PURE__ */ ((ButtonSize2) => {
  ButtonSize2[ButtonSize2["small"] = 0] = "small";
  ButtonSize2[ButtonSize2["medium"] = 1] = "medium";
  ButtonSize2[ButtonSize2["large"] = 2] = "large";
  return ButtonSize2;
})(ButtonSize || {});
export var ButtonEmphasis = /* @__PURE__ */ ((ButtonEmphasis2) => {
  ButtonEmphasis2[ButtonEmphasis2["high"] = 0] = "high";
  ButtonEmphasis2[ButtonEmphasis2["promotional"] = 1] = "promotional";
  ButtonEmphasis2[ButtonEmphasis2["highSoft"] = 2] = "highSoft";
  ButtonEmphasis2[ButtonEmphasis2["medium"] = 3] = "medium";
  ButtonEmphasis2[ButtonEmphasis2["low"] = 4] = "low";
  ButtonEmphasis2[ButtonEmphasis2["warning"] = 5] = "warning";
  ButtonEmphasis2[ButtonEmphasis2["destructive"] = 6] = "destructive";
  ButtonEmphasis2[ButtonEmphasis2["failure"] = 7] = "failure";
  return ButtonEmphasis2;
})(ButtonEmphasis || {});
function pickThemeButtonBackgroundColor({ theme, emphasis }) {
  switch (emphasis) {
    case 0 /* high */:
      return theme.accent1;
    case 1 /* promotional */:
    case 2 /* highSoft */:
      return theme.accent2;
    case 4 /* low */:
      return "transparent";
    case 5 /* warning */:
      return theme.deprecated_accentWarningSoft;
    case 6 /* destructive */:
      return theme.critical;
    case 7 /* failure */:
      return theme.deprecated_accentFailureSoft;
    case 3 /* medium */:
    default:
      return theme.surface3;
  }
}
function pickThemeButtonFontSize({ size }) {
  switch (size) {
    case 2 /* large */:
      return "20px";
    case 1 /* medium */:
      return "16px";
    case 0 /* small */:
      return "14px";
    default:
      return "16px";
  }
}
function pickThemeButtonLineHeight({ size }) {
  switch (size) {
    case 2 /* large */:
      return "24px";
    case 1 /* medium */:
      return "20px";
    case 0 /* small */:
      return "16px";
    default:
      return "20px";
  }
}
function pickThemeButtonPadding({ size }) {
  switch (size) {
    case 2 /* large */:
      return "16px";
    case 1 /* medium */:
      return "10px 12px";
    case 0 /* small */:
      return "8px";
    default:
      return "10px 12px";
  }
}
function pickThemeButtonTextColor({ theme, emphasis }) {
  switch (emphasis) {
    case 0 /* high */:
      return theme.white;
    case 1 /* promotional */:
      return theme.accent1;
    case 2 /* highSoft */:
      return theme.accent1;
    case 4 /* low */:
      return theme.neutral2;
    case 5 /* warning */:
      return theme.deprecated_accentWarning;
    case 6 /* destructive */:
      return theme.neutral1;
    case 7 /* failure */:
      return theme.critical;
    case 3 /* medium */:
    default:
      return theme.neutral1;
  }
}
const BaseThemeButton = styled.button`
  align-items: center;
  background-color: ${pickThemeButtonBackgroundColor};
  border-radius: 16px;
  border: 0;
  color: ${pickThemeButtonTextColor};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: ${pickThemeButtonFontSize};
  font-weight: 535;
  gap: 12px;
  justify-content: center;
  line-height: ${pickThemeButtonLineHeight};
  padding: ${pickThemeButtonPadding};
  position: relative;
  transition: 150ms ease opacity;
  user-select: none;

  :active {
    ${ButtonOverlay} {
      background-color: ${({ theme }) => theme.deprecated_stateOverlayPressed};
    }
  }
  :focus {
    ${ButtonOverlay} {
      background-color: ${({ theme }) => theme.deprecated_stateOverlayPressed};
    }
  }
  :hover {
    ${ButtonOverlay} {
      background-color: ${({ theme }) => theme.deprecated_stateOverlayHover};
    }
  }
  :disabled {
    cursor: default;
    opacity: 0.6;
  }
  :disabled:active,
  :disabled:focus,
  :disabled:hover {
    ${ButtonOverlay} {
      background-color: transparent;
    }
  }
`;
export const ThemeButton = forwardRef(function ThemeButton2({ children, ...rest }, ref) {
  return <BaseThemeButton {...rest} ref={ref}>
    <ButtonOverlay />
    {children}
  </BaseThemeButton>;
});
export const ButtonLight = ({ children, ...rest }) => {
  return <BaseButtonLight {...rest}>
    <ButtonOverlay />
    {children}
  </BaseButtonLight>;
};
