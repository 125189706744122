"use strict";
import { useEffect } from "react";
export var KeyAction = /* @__PURE__ */ ((KeyAction2) => {
  KeyAction2["UP"] = "keyup";
  KeyAction2["DOWN"] = "keydown";
  return KeyAction2;
})(KeyAction || {});
export const useKeyPress = ({
  callback,
  keys,
  keyAction,
  disabled,
  preventDefault
}) => {
  useEffect(() => {
    if (!keys || disabled) {
      return void 0;
    }
    const onKeyPress = (event) => {
      const wasAnyKeyPressed = keys.some((key) => event.key === key);
      const targetWasNotAnInput = !["input", "textarea"].includes(event.target.tagName.toLowerCase());
      if (wasAnyKeyPressed && targetWasNotAnInput) {
        if (preventDefault) {
          event.preventDefault();
        }
        callback(event);
      }
    };
    const keyActionType = keyAction || "keydown" /* DOWN */;
    document.addEventListener(keyActionType, onKeyPress);
    return () => {
      document.removeEventListener(keyActionType, onKeyPress);
    };
  }, [callback, keys, keyAction, disabled, preventDefault]);
};
