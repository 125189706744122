"use strict";
import ConfiguredJsonRpcProvider from "rpc/ConfiguredJsonRpcProvider";
import { AVERAGE_L1_BLOCK_TIME_MS } from "uniswap/src/features/transactions/swap/hooks/usePollingIntervalByChain";
import { logger } from "utilities/src/logger/logger";
class Controller {
  constructor(minimumBackoffTime) {
    this.minimumBackoffTime = minimumBackoffTime;
  }
  isEnabled = true;
  timeout;
  exponentialBackoffFactor = 1;
  reset() {
    this.isEnabled = true;
    clearTimeout(this.timeout);
    this.timeout = void 0;
  }
  onSuccess() {
    this.reset();
    this.exponentialBackoffFactor = 1;
  }
  /**
   * Called onError.
   * Idempotent - calling this multiple times will *not* reset the exponential backoff timer.
   */
  onError() {
    this.isEnabled = false;
    if (!this.timeout) {
      this.timeout = setTimeout(() => {
        this.reset();
        this.exponentialBackoffFactor *= 2;
      }, this.minimumBackoffTime * this.exponentialBackoffFactor);
    }
  }
  get enabled() {
    return this.isEnabled;
  }
}
export default class AppJsonRpcProvider extends ConfiguredJsonRpcProvider {
  providers;
  constructor(providers, { minimumBackoffTime = AVERAGE_L1_BLOCK_TIME_MS } = {}) {
    if (providers.length === 0) {
      throw new Error("Missing providers for AppJsonRpcProvider");
    }
    super(void 0, providers[0].network);
    this.providers = providers.map((provider) => ({ provider, controller: new Controller(minimumBackoffTime) }));
  }
  async perform(method, params) {
    const sortedProviders = AppJsonRpcProvider.sortProviders(this.providers);
    for (const { provider, controller } of sortedProviders) {
      try {
        const result = await provider.perform(method, params);
        controller.onSuccess();
        return result;
      } catch (error) {
        logger.warn("AppJsonRpcProvider", "perform", "rpc action failed", error);
        controller.onError();
      }
    }
    throw new Error(`All providers failed to perform the operation: ${method}`);
  }
  static sortProviders(providers) {
    return [...providers].sort(({ controller: { enabled: a } }, { controller: { enabled: b } }) => {
      if (a && !b) {
        return -1;
      } else if (!a && b) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
