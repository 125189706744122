"use strict";
import { ButtonGray } from "components/Button/buttons";
import { select, zoom, zoomIdentity } from "d3";
import styled from "lib/styled-components";
import { useEffect, useMemo, useRef } from "react";
import { RefreshCcw, ZoomIn, ZoomOut } from "react-feather";
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ count }) => count.toString()}, 1fr);
  grid-gap: 6px;

  position: absolute;
  top: -32px;
  right: 0;
`;
const Button = styled(ButtonGray)`
  &:hover {
    background-color: ${({ theme }) => theme.surface3};
    color: ${({ theme }) => theme.neutral1};
  }

  width: 32px;
  height: 32px;
  padding: 4px;
`;
export const ZoomOverlay = styled.rect`
  fill: transparent;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;
export default function Zoom({
  svg,
  xScale,
  setZoom,
  width,
  height,
  resetBrush,
  showResetButton,
  zoomLevels
}) {
  const zoomBehavior = useRef();
  const [zoomIn, zoomOut, zoomInitial, zoomReset] = useMemo(
    () => [
      () => svg && zoomBehavior.current && select(svg).transition().call(zoomBehavior.current.scaleBy, 2),
      () => svg && zoomBehavior.current && select(svg).transition().call(zoomBehavior.current.scaleBy, 0.5),
      () => svg && zoomBehavior.current && select(svg).transition().call(zoomBehavior.current.scaleTo, 0.5),
      () => svg && zoomBehavior.current && select(svg).call(zoomBehavior.current.transform, zoomIdentity.translate(0, 0).scale(1)).transition().call(zoomBehavior.current.scaleTo, 0.5)
    ],
    [svg]
  );
  useEffect(() => {
    if (!svg) {
      return;
    }
    zoomBehavior.current = zoom().scaleExtent([zoomLevels.min, zoomLevels.max]).extent([
      [0, 0],
      [width, height]
    ]).on("zoom", ({ transform }) => setZoom(transform));
    select(svg).call(zoomBehavior.current);
  }, [height, width, setZoom, svg, xScale, zoomBehavior, zoomLevels, zoomLevels.max, zoomLevels.min]);
  useEffect(() => {
    zoomInitial();
  }, [zoomInitial, zoomLevels]);
  return <Wrapper count={showResetButton ? 3 : 2}>
    {showResetButton && <Button
      onClick={() => {
        resetBrush();
        zoomReset();
      }}
      disabled={false}
    ><RefreshCcw size={16} /></Button>}
    <Button onClick={zoomIn} disabled={false}><ZoomIn size={16} /></Button>
    <Button onClick={zoomOut} disabled={false}><ZoomOut size={16} /></Button>
  </Wrapper>;
}
