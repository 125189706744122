"use strict";
import { useState } from "react";
export const SuspendConditionally = (props) => {
  useSuspendIf(props.if);
  return <>{props.children}</>;
};
function useSuspendIf(shouldSuspend = false) {
  const [resolve, setResolve] = useState();
  if (!resolve && shouldSuspend) {
    const promise = new Promise((res) => {
      setResolve(res);
    });
    throw promise;
  } else if (resolve && !shouldSuspend) {
    resolve();
    setResolve(void 0);
  }
}
