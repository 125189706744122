"use strict";
export function EthMini() {
  return <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9227 10.306L9.99918 11.6873L6.07687 10.306C5.86579 10.231 5.77039 9.97478 5.87772 9.76976L9.42794 3.01292C9.67838 2.55038 10.3224 2.55038 10.5728 3.01292L14.123 9.76976C14.2292 9.97353 14.1337 10.231 13.9227 10.306Z"
      fill="#9B9B9B"
    />
    <path
      d="M13.5005 12.9175L10.5358 17.3754C10.2735 17.7629 9.72488 17.7629 9.46252 17.3754L6.49788 12.9175C6.36669 12.7199 6.55153 12.4612 6.76857 12.5375L9.71298 13.5625C9.80838 13.6 9.90378 13.6125 9.99918 13.6125C10.0946 13.6125 10.19 13.6 10.2854 13.5625L13.2298 12.5375C13.4468 12.4612 13.6317 12.7199 13.5005 12.9175Z"
      fill="#9B9B9B"
    />
  </svg>;
}
