export const borderRadii = {
  none: 0,
  rounded4: 4,
  rounded6: 6,
  rounded8: 8,
  rounded12: 12,
  rounded16: 16,
  rounded20: 20,
  rounded24: 24,
  rounded32: 32,
  roundedFull: 999999
};
