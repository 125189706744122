"use strict";
import { createContext } from "react";
import { UniverseChainId } from "uniswap/src/features/chains/types";
export const MultichainContext = createContext({
  setSelectedChainId: () => void 0,
  isUserSelectedToken: false,
  setIsUserSelectedToken: () => void 0,
  chainId: UniverseChainId.Mainnet,
  initialChainId: UniverseChainId.Mainnet,
  isMultichainContext: false
});
