"use strict";
import { ProfilePageStateType } from "nft/types";
import { devtools } from "zustand/middleware";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
export const useProfilePageState = createWithEqualityFn()(
  devtools(
    (set) => ({
      state: ProfilePageStateType.VIEWING,
      setProfilePageState: (newState) => set(() => ({
        state: newState
      }))
    }),
    { name: "useProfilePageState" }
  ),
  shallow
);
