import { BaseTransport } from "@amplitude/analytics-core";
export class ApplicationTransport extends BaseTransport {
  serverUrl;
  appOrigin;
  originOverride;
  appBuild;
  reportOriginCountry;
  shouldReportOriginCountry = true;
  constructor(config) {
    super();
    this.serverUrl = config.serverUrl;
    this.appOrigin = config.appOrigin;
    this.originOverride = config.originOverride;
    this.appBuild = config.appBuild;
    this.reportOriginCountry = config.reportOriginCountry;
    if (typeof fetch === "undefined") {
      throw new Error("FetchTransport is not supported");
    }
  }
  async send(_serverUrl, payload) {
    const headers = {
      "x-origin-application": this.appOrigin,
      "Content-Type": "application/json",
      Accept: "*/*"
    };
    if (this.originOverride) {
      headers.Origin = this.originOverride;
    }
    if (this.appBuild) {
      headers["x-application-build"] = this.appBuild;
    }
    const request = {
      headers,
      keepalive: true,
      // allow the request to outlive the page
      body: JSON.stringify(payload),
      method: "POST"
    };
    const response = await fetch(this.serverUrl, request);
    const responseJSON = await response.json();
    if (response.headers.has("Origin-Country") && this.shouldReportOriginCountry) {
      this.reportOriginCountry?.(response.headers.get("Origin-Country"));
      this.shouldReportOriginCountry = false;
    }
    return this.buildResponse(responseJSON);
  }
}
