"use strict";
import { Percent } from "@uniswap/sdk-core";
import { OrderDirection } from "graphql/data/util";
import { BIPS_BASE } from "uniswap/src/constants/misc";
export function sortPools(pools, sortState) {
  return pools.sort((a, b) => {
    switch (sortState.sortBy) {
      case "1 day volume/TVL" /* VolOverTvl */:
        return sortState.sortDirection === OrderDirection.Desc ? b.volOverTvl - a.volOverTvl : a.volOverTvl - b.volOverTvl;
      case "1 day volume" /* Volume24h */:
        return sortState.sortDirection === OrderDirection.Desc ? b.volume24h - a.volume24h : a.volume24h - b.volume24h;
      case "30 day volume" /* Volume30D */:
        return sortState.sortDirection === OrderDirection.Desc ? b.volume30d - a.volume30d : a.volume30d - b.volume30d;
      case "APR" /* Apr */:
        return sortState.sortDirection === OrderDirection.Desc ? b.apr.greaterThan(a.apr) ? 1 : -1 : a.apr.greaterThan(b.apr) ? 1 : -1;
      default:
        return sortState.sortDirection === OrderDirection.Desc ? b.tvl - a.tvl : a.tvl - b.tvl;
    }
  });
}
export function calculate1DVolOverTvl(volume24h, tvl) {
  if (!volume24h || !tvl) {
    return void 0;
  }
  return volume24h / tvl;
}
export function calculateApr(volume24h, tvl, feeTier) {
  if (!volume24h || !feeTier || !tvl || !Math.round(tvl)) {
    return new Percent(0);
  }
  return new Percent(Math.round(volume24h * (feeTier / (BIPS_BASE * 100)) * 365), Math.round(tvl));
}
export const V2_BIPS = 3e3;
export var PoolSortFields = /* @__PURE__ */ ((PoolSortFields2) => {
  PoolSortFields2["TVL"] = "TVL";
  PoolSortFields2["Apr"] = "APR";
  PoolSortFields2["Volume24h"] = "1 day volume";
  PoolSortFields2["Volume30D"] = "30 day volume";
  PoolSortFields2["VolOverTvl"] = "1 day volume/TVL";
  return PoolSortFields2;
})(PoolSortFields || {});
