"use strict";
import { createReducer } from "@reduxjs/toolkit";
import {
  Field,
  resetMintState,
  setFullRange,
  typeInput,
  typeLeftRangeInput,
  typeRightRangeInput,
  typeStartPriceInput
} from "state/mint/v3/actions";
const initialState = {
  independentField: Field.CURRENCY_A,
  typedValue: "",
  startPriceTypedValue: "",
  leftRangeTypedValue: "",
  rightRangeTypedValue: ""
};
export default createReducer(
  initialState,
  (builder) => builder.addCase(resetMintState, () => initialState).addCase(setFullRange, (state) => {
    return {
      ...state,
      leftRangeTypedValue: true,
      rightRangeTypedValue: true
    };
  }).addCase(typeStartPriceInput, (state, { payload: { typedValue } }) => {
    return {
      ...state,
      startPriceTypedValue: typedValue
    };
  }).addCase(typeLeftRangeInput, (state, { payload: { typedValue } }) => {
    return {
      ...state,
      leftRangeTypedValue: typedValue
    };
  }).addCase(typeRightRangeInput, (state, { payload: { typedValue } }) => {
    return {
      ...state,
      rightRangeTypedValue: typedValue
    };
  }).addCase(typeInput, (state, { payload: { field, typedValue, noLiquidity } }) => {
    if (noLiquidity) {
      if (field === state.independentField) {
        return {
          ...state,
          independentField: field,
          typedValue
        };
      } else {
        return {
          ...state,
          independentField: field,
          typedValue
        };
      }
    } else {
      return {
        ...state,
        independentField: field,
        typedValue
      };
    }
  })
);
