"use strict";
import { useCurrencyInfo } from "hooks/Tokens";
import { useMemo } from "react";
import { SafetyLevel } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
export function useIsSwapUnsupported(currencyIn, currencyOut) {
  const currencyInInfo = useCurrencyInfo(currencyIn);
  const currencyOutInfo = useCurrencyInfo(currencyOut);
  return useMemo(() => {
    return currencyInInfo?.safetyLevel === SafetyLevel.Blocked || currencyOutInfo?.safetyLevel === SafetyLevel.Blocked;
  }, [currencyInInfo?.safetyLevel, currencyOutInfo?.safetyLevel]);
}
