"use strict";
import Row from "components/deprecated/Row";
import styled, { css } from "lib/styled-components";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { TRANSITION_DURATIONS } from "theme/styles";
import useResizeObserver from "use-resize-observer";
export const IconHoverText = styled.span`
  color: ${({ theme }) => theme.neutral1};
  position: absolute;
  top: 28px;
  border-radius: 8px;
  transform: translateX(-50%);
  opacity: 0;
  font-size: 12px;
  padding: 5px;
  left: 10px;
`;
const getWidthTransition = ({ theme }) => `width ${theme.transition.timing.inOut} ${theme.transition.duration.fast}`;
const IconStyles = css`
  background-color: ${({ theme }) => theme.surface1};
  transition: ${getWidthTransition};
  border-radius: 12px;
  display: flex;
  padding: 0;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  height: 32px;
  width: ${({ hideHorizontal }) => hideHorizontal ? "0px" : "32px"};
  color: ${({ theme }) => theme.neutral2};
  :hover {
    background-color: ${({ theme }) => theme.surface2};
    transition: ${({
  theme: {
    transition: { duration, timing }
  }
}) => `${duration.fast} background-color ${timing.in},`}
      ${getWidthTransition};

    ${IconHoverText} {
      opacity: 1;
    }
  }
  :active {
    background-color: ${({ theme }) => theme.surface1};
    transition:
      background-color ${({ theme }) => theme.transition.duration.fast} linear,
      ${getWidthTransition};
  }
`;
const IconBlockLink = styled.a`
  ${IconStyles};
`;
const IconBlockButton = styled.button`
  ${IconStyles};
  border: none;
  outline: none;
`;
const IconWrapper = styled.span`
  width: 24px;
  height: 24px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const IconBlock = forwardRef(function IconBlock2(props, ref) {
  if ("href" in props) {
    return <IconBlockLink ref={ref} {...props} />;
  }
  return <IconBlockButton ref={ref} {...props} />;
});
const IconButton = ({ Icon: Icon2, ...rest }) => <IconBlock {...rest}><IconWrapper><Icon2 size={24} /></IconWrapper></IconBlock>;
const TextWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  min-width: min-content;
  font-weight: 485;
`;
const TextHide = styled.div`
  overflow: hidden;
  transition:
    width ${({ theme }) => theme.transition.timing.inOut} ${({ theme }) => theme.transition.duration.fast},
    max-width ${({ theme }) => theme.transition.timing.inOut} ${({ theme }) => theme.transition.duration.fast};
`;
export const IconWithConfirmTextButton = ({
  Icon: Icon2,
  text,
  onConfirm,
  onShowConfirm,
  onClick,
  dismissOnHoverOut,
  dismissOnHoverDurationMs = TRANSITION_DURATIONS.slow,
  ...rest
}) => {
  const [showText, setShowTextWithoutCallback] = useState(false);
  const [frame, setFrame] = useState();
  const frameObserver = useResizeObserver();
  const hiddenObserver = useResizeObserver();
  const setShowText = useCallback(
    (val) => {
      setShowTextWithoutCallback(val);
      onShowConfirm?.(val);
    },
    [onShowConfirm]
  );
  const dimensionsRef = useRef({
    frame: 0,
    innerText: 0
  });
  const dimensions = (() => {
    if (!showText) {
      dimensionsRef.current = {
        frame: frameObserver.width || 0,
        innerText: hiddenObserver.width || 0
      };
    }
    return dimensionsRef.current;
  })();
  useEffect(() => {
    if (typeof window === "undefined") {
      return void 0;
    }
    if (!showText || !frame) {
      return void 0;
    }
    const closeAndPrevent = (e) => {
      setShowText(false);
      e.preventDefault();
      e.stopPropagation();
    };
    const clickHandler = (e) => {
      const { target } = e;
      const shouldClose = !(target instanceof HTMLElement) || !frame.contains(target);
      if (shouldClose) {
        closeAndPrevent(e);
      }
    };
    const keyHandler = (e) => {
      if (e.key === "Escape") {
        closeAndPrevent(e);
      }
    };
    window.addEventListener("click", clickHandler, { capture: true });
    window.addEventListener("keydown", keyHandler, { capture: true });
    return () => {
      window.removeEventListener("click", clickHandler, { capture: true });
      window.removeEventListener("keydown", keyHandler, { capture: true });
    };
  }, [frame, setShowText, showText]);
  const xPad = showText ? 8 : 0;
  const width = showText ? dimensions.frame + dimensions.innerText + xPad * 2 : 32;
  const mouseLeaveTimeout = useRef();
  return <IconBlock
    ref={(node) => {
      frameObserver.ref(node);
      setFrame(node);
    }}
    {...rest}
    style={{
      width,
      paddingLeft: xPad,
      paddingRight: xPad
    }}
    onClick={(e) => {
      if (showText) {
        onConfirm?.();
      } else {
        onClick?.(e);
        setShowText(!showText);
      }
    }}
    {...dismissOnHoverOut && {
      onMouseLeave() {
        mouseLeaveTimeout.current = setTimeout(() => {
          setShowText(false);
        }, dismissOnHoverDurationMs);
      },
      onMouseEnter() {
        if (mouseLeaveTimeout.current) {
          clearTimeout(mouseLeaveTimeout.current);
        }
      }
    }}
  ><Row height="100%" align="center">
    <IconWrapper><Icon2 width={24} height={24} /></IconWrapper>
    {
      /* this outer div is so we can cut it off but keep the inner text width full-width so we can measure it */
    }
    <TextHide
      style={{
        maxWidth: showText ? dimensions.innerText : 0,
        width: showText ? dimensions.innerText : 0,
        margin: showText ? "auto" : 0,
        // this negative transform offsets for the shift it does due to being 0 width
        transform: showText ? void 0 : `translateX(-8px)`,
        minWidth: showText ? dimensions.innerText : 0
      }}
    ><TextWrapper ref={hiddenObserver.ref}>{text}</TextWrapper></TextHide>
  </Row></IconBlock>;
};
export default IconButton;
