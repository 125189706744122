"use strict";
function cumulativeBuildUp(arr) {
  let sum = 0;
  return arr.map((value) => {
    const newValue = sum + value;
    sum = newValue;
    return newValue;
  });
}
export class StackedAreaSeriesRenderer {
  _data = null;
  _options = null;
  draw(target, priceConverter) {
    target.useBitmapCoordinateSpace((scope) => this._drawImpl(scope, priceConverter));
  }
  update(data, options) {
    this._data = data;
    this._options = options;
  }
  _drawImpl(renderingScope, priceToCoordinate) {
    if (this._data === null || this._data.bars.length === 0 || this._data.visibleRange === null || this._options === null) {
      return;
    }
    const ctx = renderingScope.context;
    const options = this._options;
    const bars = this._data.bars.map((bar) => {
      return {
        x: bar.x,
        ys: cumulativeBuildUp(bar.originalData.values).map((value) => priceToCoordinate(value) ?? 0)
      };
    });
    const zeroY = priceToCoordinate(0) ?? 0;
    const colorsCount = options.colors.length;
    const isV4EverywhereEnabled = options.colors.length === 3;
    const { linesMeshed, hoverInfo } = this._createLinePaths(
      bars,
      this._data.visibleRange,
      renderingScope,
      zeroY * renderingScope.verticalPixelRatio,
      options.hoveredLogicalIndex,
      isV4EverywhereEnabled
    );
    const fullLinesMeshed = linesMeshed.slice(0, colorsCount + 1);
    const highlightLinesMeshed = options.hoveredLogicalIndex ? linesMeshed.slice(colorsCount + 1) : [];
    const areaPaths = this._createAreas(fullLinesMeshed);
    const isHovered = options.hoveredLogicalIndex && options.hoveredLogicalIndex !== -1;
    areaPaths.forEach((areaPath, index) => {
      if (areaPaths.length === 1) {
        ctx.globalAlpha = 0.12;
      }
      const gradient = options.gradients ? ctx.createLinearGradient(0, 0, renderingScope.mediaSize.width * 2.25, 0) : void 0;
      gradient?.addColorStop(0, options.gradients?.[index % colorsCount].start ?? "transparent");
      const gradientStop = Math.max(hoverInfo.x ? hoverInfo.x / renderingScope.bitmapSize.width : 1, 0);
      gradient?.addColorStop(gradientStop, options.gradients?.[index % colorsCount].end ?? "transparent");
      ctx.fillStyle = gradient ?? options.colors[index % colorsCount];
      ctx.fill(areaPath);
    });
    ctx.lineWidth = options.lineWidth;
    ctx.lineJoin = "round";
    fullLinesMeshed.toReversed().forEach((linePath, index) => {
      const color = options.colors[colorsCount - (index + 1)];
      ctx.strokeStyle = color;
      ctx.fillStyle = color;
      ctx.globalAlpha = isHovered ? 0.24 : 1;
      if (index !== fullLinesMeshed.length - 1) {
        ctx.beginPath();
        ctx.stroke(linePath.path);
        const hoverY = hoverInfo.points.toReversed()[index];
        ctx.globalAlpha = 1;
        ctx.globalCompositeOperation = "destination-out";
        ctx.beginPath();
        ctx.arc(hoverInfo.x, hoverY, 5 * renderingScope.verticalPixelRatio, 0, 2 * Math.PI);
        ctx.fill();
        ctx.globalCompositeOperation = "source-over";
        ctx.beginPath();
        ctx.arc(hoverInfo.x, hoverY, 3 * renderingScope.verticalPixelRatio, 0, 2 * Math.PI);
        ctx.fill();
        ctx.globalAlpha = 0.2;
        ctx.beginPath();
        ctx.arc(hoverInfo.x, hoverY, 8 * renderingScope.verticalPixelRatio, 0, 2 * Math.PI);
        ctx.fill();
        ctx.globalAlpha = 0.3;
        ctx.beginPath();
        ctx.arc(hoverInfo.x, hoverY, 12 * renderingScope.verticalPixelRatio, 0, 2 * Math.PI);
        ctx.fill();
      }
      ctx.globalAlpha = 1;
    });
    highlightLinesMeshed.toReversed().forEach((linePath, index) => {
      if (!linePath) {
        ctx.globalAlpha = 1;
        return;
      }
      const color = options.colors[colorsCount - (index + 1)];
      ctx.strokeStyle = color;
      ctx.fillStyle = color;
      ctx.globalAlpha = 1;
      ctx.beginPath();
      ctx.stroke(linePath.path);
    });
  }
  /** Builds canvas line paths based on input data  */
  _createLinePaths(bars, visibleRange, renderingScope, zeroY, hoveredIndex, isV4EverywhereEnabled) {
    const { horizontalPixelRatio, verticalPixelRatio } = renderingScope;
    const v2Lines = [];
    const v3Lines = [];
    const v4Lines = [];
    const v2HighlightLines = [];
    const v3HighlightLines = [];
    const v4HighlightLines = [];
    let firstBar = true;
    const hoverInfo = { points: new Array(), x: 0 };
    const numLines = isV4EverywhereEnabled ? 3 : 2;
    for (let i = visibleRange.from - 1; i < visibleRange.to + 1; i++) {
      if (i >= bars.length || i < 0) {
        continue;
      }
      const stack = bars[i];
      let lineIndex = 0;
      stack.ys.forEach((yMedia, index) => {
        if (index % numLines !== 0) {
          return;
        }
        const x = stack.x * horizontalPixelRatio;
        const y = yMedia * verticalPixelRatio;
        if (i === hoveredIndex) {
          hoverInfo.points[index] = y;
          hoverInfo.x = x;
        }
        if (firstBar) {
          v2Lines[lineIndex] = {
            path: new Path2D(),
            first: { x, y },
            last: { x, y }
          };
          v2Lines[lineIndex].path.moveTo(x, y);
        } else {
          v2Lines[lineIndex].path.lineTo(x, y);
          v2Lines[lineIndex].last.x = x;
          v2Lines[lineIndex].last.y = y;
        }
        if (firstBar && hoveredIndex && i <= hoveredIndex) {
          v2HighlightLines[lineIndex] = {
            path: new Path2D(),
            first: { x, y },
            last: { x, y }
          };
          v2HighlightLines[lineIndex].path.moveTo(x, y);
        } else if (hoveredIndex && i <= hoveredIndex) {
          v2HighlightLines[lineIndex].path.lineTo(x, y);
          v2HighlightLines[lineIndex].last.x = x;
          v2HighlightLines[lineIndex].last.y = y;
        }
        lineIndex += 1;
      });
      firstBar = false;
    }
    firstBar = true;
    for (let i = visibleRange.to + 1; i >= visibleRange.from - 1; i--) {
      if (i >= bars.length || i < 0) {
        continue;
      }
      const stack = bars[i];
      let lineIndex = 0;
      stack.ys.forEach((yMedia, index) => {
        if (index % numLines !== 1) {
          return;
        }
        const x = stack.x * horizontalPixelRatio;
        const y = yMedia * verticalPixelRatio;
        if (i === hoveredIndex) {
          hoverInfo.points[index] = y;
          hoverInfo.x = x;
        }
        if (firstBar) {
          v3Lines[lineIndex] = {
            path: new Path2D(),
            first: { x, y },
            last: { x, y }
          };
          v3Lines[lineIndex].path.moveTo(x, y);
        } else {
          v3Lines[lineIndex].path.lineTo(x, y);
          v3Lines[lineIndex].last.x = x;
          v3Lines[lineIndex].last.y = y;
        }
        if (v3HighlightLines.length <= lineIndex && hoveredIndex && i <= hoveredIndex) {
          v3HighlightLines[lineIndex] = {
            path: new Path2D(),
            first: { x, y },
            last: { x, y }
          };
          v3HighlightLines[lineIndex].path.moveTo(x, y);
        } else if (hoveredIndex && i <= hoveredIndex) {
          v3HighlightLines[lineIndex].path.lineTo(x, y);
          v3HighlightLines[lineIndex].last.x = x;
          v3HighlightLines[lineIndex].last.y = y;
        }
        lineIndex += 1;
      });
      firstBar = false;
    }
    firstBar = true;
    for (let i = visibleRange.to + 1; i >= visibleRange.from - 1; i--) {
      if (i >= bars.length || i < 0) {
        continue;
      }
      const stack = bars[i];
      let lineIndex = 0;
      stack.ys.forEach((yMedia, index) => {
        if (index % numLines !== 2 || !isV4EverywhereEnabled) {
          return;
        }
        const x = stack.x * horizontalPixelRatio;
        const y = yMedia * verticalPixelRatio;
        if (i === hoveredIndex) {
          hoverInfo.points[index] = y;
          hoverInfo.x = x;
        }
        if (firstBar) {
          v4Lines[lineIndex] = {
            path: new Path2D(),
            first: { x, y },
            last: { x, y }
          };
          v4Lines[lineIndex].path.moveTo(x, y);
        } else {
          v4Lines[lineIndex].path.lineTo(x, y);
          v4Lines[lineIndex].last.x = x;
          v4Lines[lineIndex].last.y = y;
        }
        if (v4HighlightLines.length <= lineIndex && hoveredIndex && i <= hoveredIndex) {
          v4HighlightLines[lineIndex] = {
            path: new Path2D(),
            first: { x, y },
            last: { x, y }
          };
          v4HighlightLines[lineIndex].path.moveTo(x, y);
        } else if (hoveredIndex && i <= hoveredIndex) {
          v4HighlightLines[lineIndex].path.lineTo(x, y);
          v4HighlightLines[lineIndex].last.x = x;
          v4HighlightLines[lineIndex].last.y = y;
        }
        lineIndex += 1;
      });
      firstBar = false;
    }
    const baseLine = {
      path: new Path2D(),
      first: { x: v2Lines[0].last.x, y: zeroY },
      last: { x: v2Lines[0].first.x, y: zeroY }
    };
    baseLine.path.moveTo(v2Lines[0].last.x, zeroY);
    baseLine.path.lineTo(v2Lines[0].first.x, zeroY);
    const linesMeshed = [baseLine];
    for (let i = 0; i < v2Lines.length; i++) {
      linesMeshed.push(v2Lines[i]);
      if (i < v3Lines.length) {
        linesMeshed.push(v3Lines[i]);
      }
      if (i < v4Lines.length && isV4EverywhereEnabled) {
        linesMeshed.push(v4Lines[i]);
      }
      if (hoveredIndex) {
        linesMeshed.push(v2HighlightLines[i]);
        linesMeshed.push(v3HighlightLines[i]);
        isV4EverywhereEnabled && linesMeshed.push(v4HighlightLines[i]);
      }
    }
    return { linesMeshed, hoverInfo };
  }
  /** Builds canvas area paths to fill under lines */
  _createAreas(linesMeshed) {
    const areas = [];
    for (let i = 1; i < linesMeshed.length; i++) {
      const baseReferenceIndex = Math.min(i - 1, 1);
      const areaPath = new Path2D(linesMeshed[baseReferenceIndex].path);
      areaPath.lineTo(linesMeshed[i].first.x, linesMeshed[i].first.y);
      areaPath.addPath(linesMeshed[i].path);
      areaPath.lineTo(linesMeshed[baseReferenceIndex].first.x, linesMeshed[baseReferenceIndex].first.y);
      areaPath.closePath();
      areas.push(areaPath);
    }
    return areas;
  }
}
