"use strict";
export var FormatType = /* @__PURE__ */ ((FormatType2) => {
  FormatType2["Short"] = "short";
  FormatType2["Long"] = "long";
  return FormatType2;
})(FormatType || {});
export function formatTimestamp(timestamp, includeYear, type = "long" /* Long */) {
  const options = type === "long" /* Long */ ? {
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    year: includeYear ? "numeric" : void 0
  } : {
    year: includeYear ? "2-digit" : void 0,
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true
  };
  return new Intl.DateTimeFormat("en-US", options).format(timestamp);
}
