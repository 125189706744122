"use strict";
import { ChartType } from "components/Charts/utils";
import { DataQuality } from "components/Tokens/TokenDetails/ChartSection/util";
import { getCurrencyAddressWithWrap } from "pages/Pool/Positions/create/utils";
import { useMemo } from "react";
import {
  usePoolPriceHistoryQuery
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { isSameAddress } from "utilities/src/addresses";
export function usePoolPriceChartData(variables, currencyA, currencyB, protocolVersion, sortedCurrencyAAddress, isReversed) {
  const { data, loading } = usePoolPriceHistoryQuery({ variables });
  return useMemo(() => {
    const { priceHistory } = data?.v2Pair ?? data?.v3Pool ?? {};
    const referenceToken = isReversed ? currencyA : currencyB;
    const entries = priceHistory?.filter((price) => price !== null).map((price) => {
      const value = isSameAddress(
        sortedCurrencyAAddress,
        getCurrencyAddressWithWrap(referenceToken, protocolVersion)
      ) ? price?.token0Price : price?.token1Price;
      return {
        time: price.timestamp,
        value,
        open: value,
        high: value,
        low: value,
        close: value
      };
    }) ?? [];
    const dataQuality = loading || !priceHistory || !priceHistory.length ? DataQuality.INVALID : DataQuality.VALID;
    return { chartType: ChartType.PRICE, entries, loading, dataQuality };
  }, [data?.v2Pair, data?.v3Pool, isReversed, loading, currencyA, currencyB, sortedCurrencyAAddress, protocolVersion]);
}
