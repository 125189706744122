"use strict";
import { routingPreferencesAtom } from "components/Settings/MultipleRoutingOptions";
import { useAccount } from "hooks/useAccount";
import useDebounce from "hooks/useDebounce";
import { useAtomValue } from "jotai/utils";
import { useMemo } from "react";
import { usePreviewTrade } from "state/routing/usePreviewTrade";
import { useRoutingAPITrade } from "state/routing/useRoutingAPITrade";
import { useRouterPreference } from "state/user/hooks";
import { WRAPPED_NATIVE_CURRENCY } from "uniswap/src/constants/tokens";
import { FeatureFlags } from "uniswap/src/features/gating/flags";
import { useFeatureFlag } from "uniswap/src/features/gating/hooks";
const DEBOUNCE_TIME = 350;
const DEBOUNCE_TIME_QUICKROUTE = 50;
export function useDebouncedTrade(tradeType, amountSpecified, otherCurrency, routerPreferenceOverride, account, inputTax, outputTax) {
  const { chainId } = useAccount();
  const inputs = useMemo(
    () => [amountSpecified, otherCurrency],
    [amountSpecified, otherCurrency]
  );
  const isDebouncing = useDebounce(inputs, DEBOUNCE_TIME) !== inputs;
  const isPreviewTradeDebouncing = useDebounce(inputs, DEBOUNCE_TIME_QUICKROUTE) !== inputs;
  const isWrap = useMemo(() => {
    if (!chainId || !amountSpecified || !otherCurrency) {
      return false;
    }
    const weth = WRAPPED_NATIVE_CURRENCY[chainId];
    return Boolean(
      amountSpecified.currency.isNative && weth?.equals(otherCurrency) || otherCurrency.isNative && weth?.equals(amountSpecified.currency)
    );
  }, [amountSpecified, chainId, otherCurrency]);
  const [routerPreference] = useRouterPreference();
  const multipleRouteOptionsEnabled = useFeatureFlag(FeatureFlags.MultipleRoutingOptions);
  const multipleRouteOptionsRoutingPreference = useAtomValue(routingPreferencesAtom);
  const routingPreference = multipleRouteOptionsEnabled ? multipleRouteOptionsRoutingPreference : void 0;
  const skipBothFetches = isWrap;
  const skipRoutingFetch = skipBothFetches || isDebouncing;
  const skipPreviewTradeFetch = skipBothFetches || isPreviewTradeDebouncing;
  const previewTradeResult = usePreviewTrade(
    skipPreviewTradeFetch,
    tradeType,
    amountSpecified,
    otherCurrency,
    inputTax,
    outputTax
  );
  const routingApiTradeResult = useRoutingAPITrade(
    skipRoutingFetch,
    tradeType,
    amountSpecified,
    otherCurrency,
    routerPreferenceOverride ?? routingPreference?.router ?? routerPreference,
    account,
    routingPreference?.protocols,
    inputTax,
    outputTax
  );
  return previewTradeResult.currentTrade && !routingApiTradeResult.currentTrade ? previewTradeResult : routingApiTradeResult;
}
