"use strict";
import styled, { css, keyframes } from "lib/styled-components";
const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const RotationStyle = css`
  animation: 2s ${rotateAnimation} linear infinite;
`;
export const StyledSVG = styled.svg`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  path {
    stroke: ${({ stroke }) => stroke};
    background: ${({ theme }) => theme.neutral2};
    fill: ${({ fill }) => fill};
  }
`;
export const StyledRotatingSVG = styled(StyledSVG)`
  ${RotationStyle}
`;
