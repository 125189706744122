"use strict";
import { V3_CORE_FACTORY_ADDRESSES } from "@uniswap/sdk-core";
import { Pool, TICK_SPACINGS, tickToPrice } from "@uniswap/v3-sdk";
import { PoolState, usePoolMultichain } from "hooks/usePools";
import JSBI from "jsbi";
import ms from "ms";
import { useEffect, useMemo, useState } from "react";
import { useMultichainContext } from "state/multichain/useMultichainContext";
import { useAllV3TicksQuery } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { useEnabledChains, useSupportedChainId } from "uniswap/src/features/chains/hooks";
import { UniverseChainId } from "uniswap/src/features/chains/types";
import { toGraphQLChain } from "uniswap/src/features/chains/utils";
import { logger } from "utilities/src/logger/logger";
import computeSurroundingTicks from "utils/computeSurroundingTicks";
const PRICE_FIXED_DIGITS = 8;
const getActiveTick = (tickCurrent, feeAmount) => tickCurrent && feeAmount ? Math.floor(tickCurrent / TICK_SPACINGS[feeAmount]) * TICK_SPACINGS[feeAmount] : void 0;
const MAX_TICK_FETCH_VALUE = 1e3;
function usePaginatedTickQuery(currencyA, currencyB, feeAmount, skip = 0, chainId) {
  const { defaultChainId } = useEnabledChains();
  const poolAddress = currencyA && currencyB && feeAmount ? Pool.getAddress(
    currencyA?.wrapped,
    currencyB?.wrapped,
    feeAmount,
    void 0,
    chainId ? V3_CORE_FACTORY_ADDRESSES[chainId] : void 0
  ) : void 0;
  const supportedChainId = useSupportedChainId(chainId);
  return useAllV3TicksQuery({
    variables: {
      address: poolAddress?.toLowerCase() ?? "",
      chain: toGraphQLChain(supportedChainId ?? defaultChainId),
      skip,
      first: MAX_TICK_FETCH_VALUE
    },
    skip: !poolAddress,
    pollInterval: ms(`30s`)
  });
}
function useAllV3Ticks(currencyA, currencyB, feeAmount, chainId) {
  const [skipNumber, setSkipNumber] = useState(0);
  const [tickData, setTickData] = useState([]);
  const {
    data,
    error,
    loading: isLoading
  } = usePaginatedTickQuery(currencyA, currencyB, feeAmount, skipNumber, chainId);
  const ticks = data?.v3Pool?.ticks;
  useEffect(() => {
    if (ticks?.length) {
      setTickData((tickData2) => [...tickData2, ...ticks]);
      if (ticks?.length === MAX_TICK_FETCH_VALUE) {
        setSkipNumber((skipNumber2) => skipNumber2 + MAX_TICK_FETCH_VALUE);
      }
    }
  }, [ticks]);
  return {
    isLoading: isLoading || ticks?.length === MAX_TICK_FETCH_VALUE,
    error,
    ticks: tickData
  };
}
export function usePoolActiveLiquidity(currencyA, currencyB, feeAmount, chainId) {
  const multichainContext = useMultichainContext();
  const defaultChainId = multichainContext.chainId ?? UniverseChainId.Mainnet;
  const pool = usePoolMultichain(currencyA?.wrapped, currencyB?.wrapped, feeAmount, chainId ?? defaultChainId);
  const liquidity = pool[1]?.liquidity;
  const sqrtPriceX96 = pool[1]?.sqrtRatioX96;
  const currentTick = pool[1]?.tickCurrent;
  const activeTick = useMemo(() => getActiveTick(currentTick, feeAmount), [currentTick, feeAmount]);
  const { isLoading, error, ticks } = useAllV3Ticks(currencyA, currencyB, feeAmount, chainId ?? defaultChainId);
  return useMemo(() => {
    if (!currencyA || !currencyB || activeTick === void 0 || pool[0] !== PoolState.EXISTS || !ticks || ticks.length === 0 || isLoading) {
      return {
        isLoading: isLoading || pool[0] === PoolState.LOADING,
        error,
        activeTick,
        data: void 0
      };
    }
    const token0 = currencyA?.wrapped;
    const token1 = currencyB?.wrapped;
    const pivot = ticks.findIndex((tickData) => tickData?.tick && tickData.tick > activeTick) - 1;
    if (pivot < 0) {
      logger.debug("usePoolTickData", "usePoolActiveLiquidity", "TickData pivot not found", {
        token0: token0.address,
        token1: token1.address,
        chainId: token0.chainId
      });
      return {
        isLoading,
        error,
        activeTick,
        data: void 0
      };
    }
    const sdkPrice = tickToPrice(token0, token1, activeTick);
    const activeTickProcessed = {
      liquidityActive: JSBI.BigInt(pool[1]?.liquidity ?? 0),
      tick: activeTick,
      liquidityNet: Number(ticks[pivot]?.tick) === activeTick ? JSBI.BigInt(ticks[pivot]?.liquidityNet ?? 0) : JSBI.BigInt(0),
      price0: sdkPrice.toFixed(PRICE_FIXED_DIGITS),
      sdkPrice
    };
    const subsequentTicks = computeSurroundingTicks(token0, token1, activeTickProcessed, ticks, pivot, true);
    const previousTicks = computeSurroundingTicks(token0, token1, activeTickProcessed, ticks, pivot, false);
    const ticksProcessed = previousTicks.concat(activeTickProcessed).concat(subsequentTicks);
    return {
      isLoading,
      error,
      currentTick,
      activeTick,
      liquidity,
      sqrtPriceX96,
      data: ticksProcessed
    };
  }, [currencyA, currencyB, activeTick, pool, ticks, isLoading, error, currentTick, liquidity, sqrtPriceX96]);
}
