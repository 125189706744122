"use strict";
import { useWeb3React } from "@web3-react/core";
import { useCallback } from "react";
import ERC20_ABI from "uniswap/src/abis/erc20.json";
import { getContract } from "utilities/src/contracts/getContract";
import { logger } from "utilities/src/logger/logger";
import { useAsyncData } from "utilities/src/react/hooks";
export function useCreateTransferTransaction(transferInfo) {
  const transactionFetcher = useCallback(() => {
    return getTransferTransaction(transferInfo);
  }, [transferInfo]);
  return useAsyncData(transactionFetcher).data;
}
async function getTransferTransaction(transferInfo) {
  const { provider, account, chainId, currencyAmount, toAddress } = transferInfo;
  if (!provider || !account || !chainId || !currencyAmount || !toAddress) {
    return void 0;
  }
  const currency = currencyAmount.currency;
  const params = {
    provider,
    account,
    chainId,
    toAddress,
    tokenAddress: currency.isNative ? "" : currency.address,
    amountInWei: currencyAmount.quotient.toString()
  };
  return currency.isNative ? getNativeTransferRequest(params) : getTokenTransferRequest(params);
}
function getNativeTransferRequest(params) {
  const { account, toAddress, amountInWei, chainId } = params;
  return {
    from: account,
    to: toAddress,
    value: amountInWei,
    chainId
  };
}
async function getTokenTransferRequest(transferParams) {
  const { provider, account, chainId, toAddress, tokenAddress, amountInWei } = transferParams;
  const tokenContract = getContract(tokenAddress, ERC20_ABI, provider, account);
  try {
    const populatedTransaction = await tokenContract.populateTransaction.transfer(toAddress, amountInWei, {
      from: account
    });
    return { ...populatedTransaction, chainId };
  } catch (error) {
    logger.error(error, {
      tags: {
        file: "transfer",
        function: "getTokenTransferRequest"
      },
      extra: { transferParams }
    });
  }
  return void 0;
}
export function useIsSmartContractAddress(address) {
  const { provider } = useWeb3React();
  const fetchIsSmartContractAddress = useCallback(async () => {
    if (!address) {
      return false;
    }
    const code = await provider?.getCode(address);
    return code !== "0x";
  }, [provider, address]);
  const { data, isLoading } = useAsyncData(fetchIsSmartContractAddress);
  return { isSmartContractAddress: !!data, loading: isLoading };
}
