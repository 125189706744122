import { useCallback, useState } from "react";
import { Flex } from "ui/src/components/layout";
import { SWIPEABLE_CARD_Y_OFFSET } from "ui/src/components/swipeablecards/BaseCard";
import { SwipeableCard } from "ui/src/components/swipeablecards/SwipeableCard";
import { usePrevious } from "utilities/src/react/hooks";
export function BaseSwipeableCardStack({
  cards,
  minCardHeight = 0,
  renderCard,
  keyExtractor,
  onSwiped
}) {
  const firstCard = cards[0];
  const [activeKey, setActiveKey] = useState(firstCard ? keyExtractor(firstCard) : "");
  const [containerHeight, setContainerHeight] = useState(minCardHeight + (cards.length - 1) * SWIPEABLE_CARD_Y_OFFSET);
  const [cardHeight, setCardHeight] = useState(minCardHeight);
  const keyIndex = cards.findIndex((card) => keyExtractor(card) === activeKey);
  const prevIndex = usePrevious(keyIndex);
  const activeIndex = keyIndex >= 0 ? keyIndex : prevIndex ? prevIndex + 1 : 0;
  const handleSwiped = useCallback(
    (card, index) => {
      const nextIndex = activeIndex === cards.length - 1 ? 0 : activeIndex + 1;
      const nextCard = cards[nextIndex];
      const nextKey = nextCard ? keyExtractor(nextCard) : "";
      setActiveKey(nextKey);
      onSwiped?.(card, index);
    },
    [activeIndex, cards, keyExtractor, onSwiped]
  );
  const handleLayout = useCallback(
    ({ height, yOffset }) => {
      setContainerHeight(Math.max(containerHeight, height + yOffset));
      setCardHeight(Math.max(cardHeight, height));
    },
    [cardHeight, containerHeight]
  );
  return <Flex position="relative" style={{ height: containerHeight }}>{cards.map((card, index) => {
    const stackIndex = (index - activeIndex + cards.length) % cards.length;
    return <Flex key={index} position="absolute" width="100%" zIndex={cards.length - stackIndex}><SwipeableCard
      cardHeight={cardHeight}
      disableSwipe={cards.length <= 1 || activeIndex !== index}
      stackIndex={stackIndex}
      onLayout={handleLayout}
      onPress={card.onPress}
      onSwiped={() => handleSwiped(card, index)}
    >{renderCard(card, stackIndex)}</SwipeableCard></Flex>;
  })}</Flex>;
}
