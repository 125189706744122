"use strict";
export var Z_INDEX = /* @__PURE__ */ ((Z_INDEX2) => {
  Z_INDEX2[Z_INDEX2["deprecated_zero"] = 0] = "deprecated_zero";
  Z_INDEX2[Z_INDEX2["default"] = 1] = "default";
  Z_INDEX2[Z_INDEX2["hover"] = 2] = "hover";
  Z_INDEX2[Z_INDEX2["active"] = 3] = "active";
  Z_INDEX2[Z_INDEX2["under_dropdown"] = 990] = "under_dropdown";
  Z_INDEX2[Z_INDEX2["dropdown"] = 1e3] = "dropdown";
  Z_INDEX2[Z_INDEX2["sticky"] = 1020] = "sticky";
  Z_INDEX2[Z_INDEX2["fixed"] = 1030] = "fixed";
  Z_INDEX2[Z_INDEX2["modalBackdrop"] = 1040] = "modalBackdrop";
  Z_INDEX2[Z_INDEX2["offcanvas"] = 1050] = "offcanvas";
  Z_INDEX2[Z_INDEX2["modal"] = 1060] = "modal";
  Z_INDEX2[Z_INDEX2["popover"] = 1070] = "popover";
  Z_INDEX2[Z_INDEX2["tooltip"] = 1080] = "tooltip";
  Z_INDEX2[Z_INDEX2["modalOverTooltip"] = 1090] = "modalOverTooltip";
  return Z_INDEX2;
})(Z_INDEX || {});
