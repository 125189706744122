"use strict";
import { useEffect } from "react";
export default function useInterval(callback, delay, leading = true) {
  useEffect(() => {
    if (delay === null) {
      return void 0;
    }
    let timeout;
    tick(
      delay,
      /* skip= */
      !leading
    );
    return () => {
      if (timeout) {
        clearInterval(timeout);
      }
    };
    async function tick(delay2, skip = false) {
      if (!skip) {
        const promise = callback();
        if (promise) {
          await promise;
        }
      }
      timeout = setTimeout(() => tick(delay2), delay2);
    }
  }, [callback, delay, leading]);
}
