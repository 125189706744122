"use strict";
import clsx from "clsx";
import { atoms } from "nft/css/atoms";
import { sprinkles } from "nft/css/sprinkles.css";
import * as React from "react";
import { animated } from "react-spring";
export const Box = React.forwardRef(({ as = "div", className, ...props }, ref) => {
  const atomProps = {};
  const nativeProps = {};
  for (const key in props) {
    if (sprinkles.properties.has(key)) {
      atomProps[key] = props[key];
    } else {
      nativeProps[key] = props[key];
    }
  }
  const atomicClasses = atoms({
    reset: typeof as === "string" ? as : "div",
    ...atomProps
  });
  return React.createElement(as, {
    className: clsx(atomicClasses, className),
    ...nativeProps,
    ref
  });
});
export const AnimatedBox = animated(Box);
Box.displayName = "Box";
