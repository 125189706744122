"use strict";
import { approvedERC20, approvedERC721 } from "pages/Landing/assets/approvedTokens";
import { Token } from "pages/Landing/components/TokenCloud/Token";
import { mixArrays, randomFloat, randomInt } from "pages/Landing/components/TokenCloud/utils";
import PoissonDiskSampling from "poisson-disk-sampling";
import { useMemo, useRef } from "react";
import { Flex } from "ui/src";
export function TokenCloud({ transition }) {
  const pts = useMemo(() => {
    const tokenList = mixArrays(approvedERC20, approvedERC721, 0.33);
    const w = window.innerWidth;
    const h = window.innerHeight - 72;
    const leftThreshold = w / 2 - 240;
    const rightThreshold = w / 2 + 240;
    const poissonConfig = {
      shape: [w, h],
      minDistance: 250,
      maxDistance: 375,
      tries: 10
    };
    const poissonDiskSampling = new PoissonDiskSampling(poissonConfig);
    const points = poissonDiskSampling.fill().sort((a, b) => Math.abs(a[0] - w / 2) - Math.abs(b[0] - w / 2)).map(([x, y], idx) => {
      const token = tokenList[idx % tokenList.length];
      const size = randomInt(50, 96);
      return {
        x,
        y,
        blur: 1 / size * 500 * (x > leftThreshold && x < rightThreshold || y < 100 ? 5 : 1),
        // make blur bigger for smaller icons
        size,
        color: token.color,
        logoUrl: token.logoUrl,
        opacity: randomFloat(0.5, 1) * (x > leftThreshold && x < rightThreshold || y < 100 ? 0.75 : 1),
        rotation: randomInt(-20, 20),
        delay: Math.abs(x - w / 2) / 800,
        floatDuration: randomFloat(3, 6),
        ticker: token.symbol,
        tickerPosition: x < leftThreshold && x + 100 > leftThreshold || x + 200 > w ? "left" : "right",
        standard: token.standard,
        address: token.address,
        chain: token.chain
      };
    }).map((p) => {
      return {
        ...p,
        y: p.y - 0.5 * p.size,
        x: p.x - 0.5 * p.size
      };
    });
    return points;
  }, []);
  const constraintsRef = useRef(null);
  return <Flex
    ref={constraintsRef}
    width="100vw"
    position="absolute"
    centered
    overflow="hidden"
    inset={0}
    pointerEvents="none"
    contain="strict"
  >{pts.map((point, idx) => {
    return <Token key={`token-${idx}`} point={point} idx={idx} transition={transition} />;
  })}</Flex>;
}
